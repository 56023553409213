import { centerCrop, makeAspectCrop } from 'react-image-crop';

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

const generateVideoThumbnail = (file, onSuccess) => {
  const fileReader = new FileReader();

  fileReader.onload = function () {
    const blob = new Blob([fileReader.result], { type: file.type });
    const url = URL.createObjectURL(blob);
    const video = document.createElement('video');

    const timeupdate = function () {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
        video.pause();
      }
    };

    video.addEventListener('loadeddata', function () {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
      }
    });

    const snapImage = function () {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      const image = canvas.toDataURL();
      const success = image.length > 100000;
      if (success) {
        onSuccess({
          image,
          width: canvas.width,
          height: canvas.height,
        });
        URL.revokeObjectURL(url);
      }

      return success;
    };

    video.addEventListener('timeupdate', timeupdate);
    video.preload = 'metadata';
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();
  };

  fileReader.readAsArrayBuffer(file);
};

async function urlToImageFile(imageUrl) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Optionally, convert the Blob to a File
    const file = new File([blob], `${new Date().getTime()}.jpg`, {
      type: blob.type,
    });

    return file;
  } catch (error) {
    console.error('Error converting URL to image file:', error);
    return null;
  }
}

function clampDimensions(aspectRatio, maxHeight, maxWidth) {
  if (!aspectRatio) return {};

  let width, height;

  if (aspectRatio <= 0 || maxHeight <= 0 || maxWidth <= 0) {
    return { width: 0, height: 0 }; // Handle invalid inputs
  }

  // Calculate potential dimensions based on max width
  width = maxWidth;
  height = width * aspectRatio;

  // If height exceeds max height, adjust based on max height instead
  if (height > maxHeight) {
    height = maxHeight;
    width = height / aspectRatio;
  }

  return { width: Math.round(width), height: Math.round(height) };
}

function getVideoDurationFromFile(file) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      resolve(video.duration);
      video.removeAttribute('src');
      video.load();
    };

    video.onerror = function () {
      reject(new Error('Failed to load video metadata.'));
    };

    video.src = URL.createObjectURL(file); // Create a URL from the file object
  });
}

export {
  centerAspectCrop,
  generateVideoThumbnail,
  urlToImageFile,
  clampDimensions,
  getVideoDurationFromFile,
};
