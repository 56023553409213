import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from '@reach/router';
import cx from 'classnames';

import withUserContext from 'behaviors/withUserContext';

import {
  CauzesSection,
  MobileTabs,
  JoinOthersAndTrackInfluence,
} from './components';

import useDonationLanding from 'hooks/useDonationLanding';
import GiveStartCauze from 'components/Asides/GiveStartCauze';
import GlobalFeed from 'components/Feed/GlobalFeed';
import FollowingFeed from 'components/Feed/FollowingFeed';
import useDashboardStore from 'stores/DashboardStore';
import useMobile from 'hooks/useMobile';
import useFollowingFeedStore from 'stores/FollowingFeedStore';
import { useBasicProfile } from 'hooks/useProfileById';

const DonationLandingView = ({
  donationLandingStore,
  uiStore,
  authStore,
  activeEntity,
  userProfileStore,
  profileStore,
  charityStore,
  companyStore,
}) => {
  const { updateProps, showLoadingScreen, dashboardTabIndex } =
    useDashboardStore();

  const getInitialEvents = () => {
    const isCharity = activeEntity.entityType === 'CHARITY';
    const isCompany = activeEntity.entityType === 'COMPANY';
    const isUser =
      activeEntity.entityType === 'USER' ||
      activeEntity.entityType === 'INFLUENCER';
    const entityId = activeEntity.id;

    if (isCharity) {
      return charityStore.events.get(+entityId);
    } else if (isCompany) {
      return companyStore.events.get(+entityId);
    } else if (isUser) {
      return profileStore.cauzes;
    }
  };

  const getInitialJoinedEvents = () => {
    const isCharity = activeEntity.entityType === 'CHARITY';
    const isCompany = activeEntity.entityType === 'COMPANY';
    const isUser =
      activeEntity.entityType === 'USER' ||
      activeEntity.entityType === 'INFLUENCER';
    const entityId = activeEntity.id;

    if (isCharity) {
      return charityStore.joinedEvents.get(+entityId);
    } else if (isCompany) {
      return companyStore.joinedEvents.get(+entityId);
    } else if (isUser) {
      return userProfileStore.joinedEvents.get(+entityId);
    }
  };

  const initialEvents = getInitialEvents();
  const initialJoinedEvents = getInitialJoinedEvents();

  const [events, setEvents] = useState(getInitialEvents());
  const [joinedEvents, setJoinedEvents] = useState(getInitialJoinedEvents());
  const [eventsLoading, setEventsLoading] = useState(!initialEvents?.length);
  const [joinedEventsLoading, setJoinedEventsLoading] = useState(
    !initialJoinedEvents?.length,
  );
  const followingFeedStore = useFollowingFeedStore();
  const userId =
    activeEntity?.userContext?.userId ||
    activeEntity?.userContext?.influencerId;
  useBasicProfile({ id: userId, userContext: activeEntity?.userContext });
  const user = userProfileStore.users.get(+userId);
  const hasClosedJoinOthersAndTrackInfluence =
    user?.profileData?.settings?.flags?.hasClosedJoinOthersAndTrackInfluence;
  const feedSectionRef = useRef(null);

  const loadEvents = async () => {
    const isCharity = activeEntity.entityType === 'CHARITY';
    const isCompany = activeEntity.entityType === 'COMPANY';
    const isUser =
      activeEntity.entityType === 'USER' ||
      activeEntity.entityType === 'INFLUENCER';
    const entityId = activeEntity.id;

    if (isCharity) {
      charityStore
        .getEvents({ id: entityId, pinnedOnly: false, includeExternal: true })
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => setEventsLoading(false));
      charityStore
        .getJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => {
          setJoinedEventsLoading(false);
        });
    } else if (isCompany) {
      companyStore
        .getCompanyEvents({ id: entityId })
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => {
          setEventsLoading(false);
        });
      companyStore
        .getCompanyJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => {
          setJoinedEventsLoading(false);
        });
    } else if (isUser) {
      profileStore
        .getCauzes(true)
        .then((_events) => setEvents(_events.slice(0, 5)))
        .finally(() => {
          setEventsLoading(false);
        });
      userProfileStore
        .getUserJoinedEvents({ id: entityId })
        .then((_events) => setJoinedEvents(_events.slice(0, 5)))
        .finally(() => setJoinedEventsLoading(false));
    }
  };

  const { isTablet, isDesktop } = useMobile();

  useEffect(() => {
    if (!activeEntity) {
      setEvents([]);
      setJoinedEvents([]);
      setEventsLoading(false);
      setJoinedEventsLoading(false);
      return;
    }

    setEventsLoading(!initialEvents?.length);
    setJoinedEventsLoading(!joinedEvents?.length);

    loadEvents();
  }, [activeEntity]);

  useDonationLanding();

  useEffect(() => {
    if (donationLandingStore.lastScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, donationLandingStore.lastScrollPosition);
      }, 1);
    }

    return () => donationLandingStore.updateScrollPosition();
  }, []);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }
  const isEmpty = useMemo(() => {
    return !events?.length && !joinedEvents?.length;
  }, [events, joinedEvents]);

  if (!authStore.isAuthenticated && donationLandingStore.giftRedeemed) {
    uiStore.showNotification({
      body: 'This gift has already been donated.',
      type: 'ERROR',
      onDismiss: () => {},
      autoDismiss: true,
    });
  }

  useEffect(() => {
    updateProps({
      size: 'half',
      title:
        isEmpty &&
        !eventsLoading &&
        !joinedEventsLoading &&
        !hasClosedJoinOthersAndTrackInfluence &&
        !userProfileStore.loading
          ? 'My Dashboard'
          : '',
      asideChildren: authStore.isAuthenticated && (
        <div
          className={cx('xl:block', {
            hidden: dashboardTabIndex === 1,
          })}
        >
          <GiveStartCauze />
        </div>
      ),
    });
  }, [
    authStore.isAuthenticated,
    isEmpty,
    dashboardTabIndex,
    isTablet,
    eventsLoading,
    joinedEventsLoading,
    hasClosedJoinOthersAndTrackInfluence,
    userProfileStore.loading,
  ]);

  useEffect(() => {
    showLoadingScreen('', {});
  }, []);

  if (!authStore.isAuthenticated) {
    return <Redirect to="/discover" noThrow />;
  }

  return (
    <div className="donation-landing">
      <div>
        {!hasClosedJoinOthersAndTrackInfluence &&
          isEmpty &&
          !joinedEventsLoading &&
          !eventsLoading &&
          !userProfileStore.loading && (
            <JoinOthersAndTrackInfluence
              userProfileStore={userProfileStore}
              userId={userId}
            />
          )}
        <div
          className={cx('xl:hidden', {
            '!mb-4': dashboardTabIndex === 1,
            '!mb-8': dashboardTabIndex === 0,
            block: authStore.isAuthenticated,
            hidden: !authStore.isAuthenticated,
          })}
        >
          <div ref={feedSectionRef} className="h-0 w-0"></div>
          <MobileTabs />
        </div>
        <div
          className={cx('xl:flex flex-col gap-y-10 mb-0 xl:mb-10', {
            hidden: dashboardTabIndex === 1,
            'hidden xl:hidden':
              !authStore.isAuthenticated ||
              (isEmpty && !joinedEventsLoading && !eventsLoading),
            'px-4': isTablet,
          })}
        >
          {!joinedEvents?.length && events?.length > 0 && !isDesktop && (
            <div ref={feedSectionRef} className="h-0 w-0"></div>
          )}
          <CauzesSection
            title="Your Cauzes"
            events={events}
            loading={eventsLoading}
            viewAllHref="/profile/cauzes"
          />
          {joinedEvents?.length > 0 && events?.length > 0 && !isDesktop && (
            <div ref={feedSectionRef} className="h-0 w-0"></div>
          )}
          <CauzesSection
            title="Cauzes You've Joined"
            events={joinedEvents}
            loading={joinedEventsLoading}
            viewAllHref="/profile/cauzes"
          />
        </div>
        <div
          className={cx('xl:block', {
            hidden: dashboardTabIndex === 0 && authStore.isAuthenticated,
          })}
        >
          {!authStore.isAuthenticated ? (
            <GlobalFeed
              activeEntity={activeEntity}
              title="Global Feed"
              feedSectionRef={feedSectionRef}
              onCheckoutSuccess={() => {
                donationLandingStore.getRecentFeedItems(true);
                loadEvents();
              }}
            />
          ) : (
            <FollowingFeed
              activeEntity={activeEntity}
              title="People You Follow"
              feedSectionRef={feedSectionRef}
              onCheckoutSuccess={() => {
                followingFeedStore.getFeed();
                loadEvents();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
    'userProfileStore',
    'charityStore',
    'companyStore',
  )(observer(DonationLandingView)),
);
