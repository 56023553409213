/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import mixpanel from 'mixpanel-browser';
import { globalFeedQuery } from 'graphql/feed';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import {
  giftTokenDetailsQuery,
  associatedEventsQuery,
  redeemGiftTokenMutation,
  inspectTokenQuery,
  redeemCompanyInviteTokenMutation,
  featuredEventsQuery,
} from 'graphql/donateLanding';
import { likeMutation, unlikeMutation } from 'graphql/like';

import uiStore from './UiStore';
import authStore from './AuthStore';
import profileStore from './ProfileStore';

class DonationLandingStore {
  @observable loadingGiftData = false;
  @observable loadingCauzes = true;
  @observable loadingFeedItems = true;
  @observable loadingSearchItems = false;
  @observable loadingMore = false;

  @observable isError = false;
  @observable hasGiftTokenError = false;
  @observable giftRedeemed = false;

  @observable currentPage = 1;
  @observable totalPages;
  @observable topCauzes = [];
  @observable localCauzes = [];
  @observable recentFeedItems = [];
  @observable searchItems = [];
  @observable topicSearchLimit = 200;
  @observable lastScrollPosition = 0;

  getInitial = () => {
    this.getAssociatedEvents();
    this.getRecentFeedItems();
  };

  @action like = ({ like = true, id, purchaseId, userContext }) => {
    const currentFeed = this.recentFeedItems;
    const feedItem = currentFeed.find(
      (feedItem) => feedItem?.activityPurchase?.id === id,
    );
    if (!feedItem) return;
    feedItem.currentEntityLiked = like;
    feedItem.likeCount = like ? feedItem.likeCount + 1 : feedItem.likeCount - 1;

    const updatedUserContext = { ...userContext };
    if (updatedUserContext.influencerId) {
      updatedUserContext.userId = updatedUserContext.influencerId;
      delete updatedUserContext.influencerId;
    }

    const options = {
      variables: { id: purchaseId, userContext: updatedUserContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    apolloClient.mutate(options).catch(() => {
      feedItem.currentEntityLiked = !like;
      feedItem.likeCount = like
        ? feedItem.likeCount - 1
        : feedItem.likeCount + 1;
    });
  };

  @action updateScrollPosition() {
    this.lastScrollPosition = window.scrollY;
  }

  @action loadGiftData = async ({ token }) => {
    this.loadingGiftData = true;

    const tokenType = await this.inspectToken({ token });
    if (tokenType === 'REDEEMED_ERROR') {
      this.giftRedeemed = true;
      uiStore.showNotification({
        body: 'This gift has already been donated.',
        type: 'ERROR',
        onDismiss: () => {},
        autoDismiss: true,
      });
      uiStore.saveUiState({ userToken: null });
    }

    if (tokenType === 'GIFT' || tokenType === 'COMPANY_INVITE_WITH_GIFT') {
      const options = {
        variables: {
          token,
        },
        query: giftTokenDetailsQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      try {
        const userToken = await apolloClient
          .query(options)
          .then((res) => res.data.userToken);

        if (userToken) {
          if (userToken.redeemed || userToken.revoked) {
            this.giftRedeemed = userToken.redeemed;
            this.hasGiftTokenError = userToken.revoked;

            if (!authStore.isAuthenticated && this.giftRedeemed) {
              uiStore.showNotification({
                body: 'This gift has already been donated.',
                type: 'ERROR',
                onDismiss: () => {},
                autoDismiss: true,
              });
            }

            uiStore.saveUiState({ userToken: null });
          } else {
            if (authStore.isAuthenticated) {
              const res = await apolloClient.mutate({
                variables: { token },
                mutation: redeemGiftTokenMutation,
                errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
              });
              if (res.data.redeemGiftToken) {
                uiStore.showNotification({
                  body: `$${
                    res.data.redeemGiftToken.gift.amount / 100
                  } has been added to your account.`,
                  onDismiss: () => {},
                  autoDismiss: false,
                });

                profileStore.updateActiveEntityTotal(
                  res.data.redeemGiftToken.gift.amount,
                );

                this.giftRedeemed = true;
                uiStore.saveUiState({
                  userToken: null,
                  giftAmount:
                    res.data.redeemGiftToken.gift.amount < 2500
                      ? res.data.redeemGiftToken.gift.amount
                      : 0,
                });
              } else {
                uiStore.saveUiState({ userToken: null });
              }
            } else {
              uiStore.saveUiState({ userToken });
            }
          }
          this.getAssociatedEvents();
        }
      } catch (err) {
        this.hasGiftTokenError = true;
      }
    }

    if (
      tokenType === 'COMPANY_INVITE' ||
      tokenType === 'COMPANY_ADMIN_INVITE'
    ) {
      try {
        if (authStore.isAuthenticated) {
          const res = await apolloClient.mutate({
            variables: { token },
            mutation: redeemCompanyInviteTokenMutation,
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          });

          if (res.data.redeemCompanyToken) {
            uiStore.showNotification({
              body: `Congratulations, you’re now part of ${res.data.redeemCompanyToken.company.name}’s giving program.`,
              onDismiss: () => {},
              autoDismiss: true,
              type: 'SUCCESS',
            });

            window.history.replaceState(
              {},
              '',
              window.location.origin + window.location.pathname,
            );

            await profileStore.getProfile();
          }
        }
      } catch (err) {
        uiStore.showNotification({
          body:
            'The link you’ve tapped has already been used or is expired. ' +
            'If you feel this is a mistake, please contact the sender.',
          type: 'ERROR',
        });
      }
    }

    mixpanel.track('Gift Token Loaded', {
      token,
      tokenType,
      distinct_id: uiStore.savedUiState?.userToken?.email,
      error: this.hasGiftTokenError,
    });
    this.loadingGiftData = false;
  };

  inspectToken = async ({ token }) => {
    let res;
    const options = {
      variables: {
        token,
      },
      query: inspectTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      res = await apolloClient.query(options);
      if (res.errors?.[0].message.includes('redeemed')) {
        return 'REDEEMED_ERROR';
      }
    } catch (err) {
      // thnks tokens trigger catch clause rather than return res.errors
      if (err.message.includes('redeemed')) {
        return 'REDEEMED_ERROR';
      }
    }
    return res.data.inspectToken;
  };

  @action getAssociatedEvents = async () => {
    if (this.topCauzes.length > 0) return;

    this.loadingCauzes = true;
    let giftInitiatorContext = null;

    const options = {
      variables: {
        userContext: giftInitiatorContext,
        zipcode: profileStore?.activeEntity?.zip,
        limit: 50,
      },
      query: giftInitiatorContext ? associatedEventsQuery : featuredEventsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      this.topCauzes = await (giftInitiatorContext ? apolloClient : api2Client)
        .query(options)
        .then((res) => res.data.associatedEvents || res.data.featuredEvents);

      this.localCauzes = [];

      this.isError = false;
    } catch (err) {
      this.isError = true;
      this.error = err;
    }
    this.loadingCauzes = false;
  };

  @action getRecentFeedItems = async (force = false) => {
    if (this.recentFeedItems?.length > 0 && !force) {
      return;
    }

    if (this.recentFeedItems) {
      this.loadingFeedItems = true;
    }

    try {
      const variables = {
        offset: 0,
        limit: 20,
      };

      const options = {
        variables: variables,
        query: globalFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.recentFeedItems = results.data.globalFeed;
      this.isError = false;
    } catch (err) {
      this.isError = true;
      this.error = err;
    }
    this.loadingFeedItems = false;
  };

  @action loadMoreRecentFeedItems = async () => {
    this.loadingMore = true;
    this.currentPage += 1;

    mixpanel.track('Load More Recent Feed Items', {
      page: this.currentPage,
    });

    if (this.currentPage > this.totalPages) {
      return;
    }

    try {
      const variables = {
        offset: 20 * (this.currentPage - 1),
        limit: 20,
      };

      const options = {
        variables: variables,
        query: globalFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.recentFeedItems = this.recentFeedItems.concat(
        results.data.globalFeed,
      );
    } catch (err) {
      this.isError = true;
      this.error = err;
    }
    this.loadingMore = false;
  };

  @action onUnmount = () => {
    this.loadingGiftData = true;
    this.isError = false;
    this.recentFeedItems = [];
    this.searchItems = [];
    this.currentPage = 1;
    this.loadingMore = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const donationLandingStore = new DonationLandingStore();
export default donationLandingStore;
