import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate, Redirect } from '@reach/router';

import Avatar from 'components/Avatar/Avatar';
import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import ShareModal from 'components/modals/ShareModal/ShareModal';

import PaymentMeta from 'components/PaymentMeta/PaymentMeta';

import withUserContext from 'behaviors/withUserContext';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useCheckoutStore from 'stores/CheckoutStoreV2';

// Determine Line Items for Donation Reciept based on payment method
const DonationLineItems = ({ currentCheckout }) => (
  <div className="donation-details">
    <div className="donation-line-item">
      <span>Donation Amount</span>
      <span className="ellipses-container"></span>
      <Currency
        showCents={true}
        amount={
          currentCheckout.splitAmount?.remaining ||
          currentCheckout.splitAmount?.forCauze
        }
      />
    </div>
    {currentCheckout.paymentType === 'CARD' && (
      <div className="donation-line-item">
        <span>CC Fee</span>
        <span className="ellipses-container"></span>
        <Currency showCents={true} amount={currentCheckout.splitAmount?.fee} />
      </div>
    )}
    {currentCheckout.paymentType === 'ACH' && (
      <div className="donation-line-item">
        <span>ACH Fee</span>
        <span className="ellipses-container"></span>
        <Currency showCents={true} amount={currentCheckout.splitAmount?.fee} />
      </div>
    )}
    {currentCheckout.paymentType === 'PAYPAL' && (
      <div className="donation-line-item">
        <span>PayPal Fee</span>
        <span className="ellipses-container"></span>
        <Currency showCents={true} amount={currentCheckout.splitAmount?.fee} />
      </div>
    )}
    <div className="donation-line-item">
      <span>Total</span>
      <span className="ellipses-container"></span>
      <Currency showCents={true} amount={currentCheckout.splitAmount?.total} />
    </div>
  </div>
);

// Get the match sponsor information
const MatchTag = ({ checkoutSuccess }) => (
  <div className="match-tag-container">
    {checkoutSuccess.matchSponsors.map((sponsor, index) => (
      <div className="match-item">
        <Avatar md avatar={sponsor.avatar} index={index} />
        <div className="match-item-details">
          <span className="match-amount">
            {`MATCHED! `}
            <Currency amount={sponsor.matchAmount} />
          </span>
          <span className="match-description">
            {`Your donation was matched an additional `}
            <Currency amount={sponsor.matchAmount} />
            {` by `} <br />
            {sponsor.name}
          </span>
        </div>
      </div>
    ))}
  </div>
);

// Set Checkout Message based on auth state
const CheckoutMessage = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return (
      <>
        Giving a damn feels damn good! Keep it going by finding more Cauzes and
        nonprofits to give to or share with your online community
      </>
    );
  } else {
    return (
      <>
        That felt good right?! You can do more with a Cauze account. Track your
        giving, join friends and family, follow your favorite Cauzes or
        nonprofits. Create one now and keep giving a damn!
      </>
    );
  }
};

// Determine Which CTA buttons to show based on auth state
const SuccessButtons = ({ isAuthenticated, checkoutSuccess }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  if (isAuthenticated) {
    return (
      <>
        <CauzeButton
          large
          tall
          children="Share"
          className="donate"
          onClick={() => {
            setShowShareModal(true);
          }}
        />
        <ShareModal
          isOpen={showShareModal}
          onToggleClose={() => setShowShareModal(false)}
          shareUrl={checkoutSuccess?.purchase?.shareLink}
        />
      </>
    );
  } else {
    return (
      <>
        <CauzeButton
          large
          tall
          className="donate"
          children="Create Account"
          onClick={() => {
            navigate('/signup');
          }}
        />
      </>
    );
  }
};

const NewDonationSuccessView = ({ authStore }) => {
  const { setProps } = useDashboardStore();
  const isAuthenticated = authStore.isAuthenticated;
  const [cachedCheckout, setCachedCheckout] = useState();
  const checkoutStore = useCheckoutStore();
  const checkoutSuccess = checkoutStore?.checkoutSuccess;
  const currentCheckout = checkoutStore?.currentCheckout;

  useEffect(() => {
    if (!cachedCheckout && currentCheckout) {
      setCachedCheckout({
        ...currentCheckout,
      });
      checkoutStore.reset();
    }
  }, [currentCheckout]);

  useEffect(() => {
    setProps({
      size: 'half',
      scroll: 0,
    });
  }, []);

  if (
    !checkoutStore?.currentCheckout?.splitAmount &&
    !cachedCheckout?.splitAmount
  ) {
    return <Redirect to="/donate" noThrow />;
  }

  if (!cachedCheckout) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="donation-success-view">
      <div className="donation-success-view-container">
        <div className="donation-success-view-header">
          <div className="donation-success-view-title">3. Done</div>
          <div className="donation-success-view-subtitle">
            You made it count! <br /> Here's a Summary of your Donation:
          </div>
        </div>
        <div className="donation-success-view-content">
          {cachedCheckout.eventId && (
            <div className="donation-success-target">
              <span className="target-name">{cachedCheckout.eventName}</span>
            </div>
          )}
          {cachedCheckout?.charityIds?.length > 0 &&
            !cachedCheckout.eventId &&
            checkoutSuccess?.purchase?.donations.map((donation) => (
              <div className="donation-success-target">
                <span className="target-name">{donation?.project?.name}</span>
              </div>
            ))}
        </div>
        <DonationLineItems
          currentCheckout={cachedCheckout}
          checkoutSuccess={checkoutSuccess}
        />
        <PaymentMeta
          currentCheckout={cachedCheckout}
          containerClassName="flex-justify-start"
        />
        {checkoutSuccess.matchSponsors?.length > 0 && (
          <MatchTag checkoutSuccess={checkoutSuccess} />
        )}
        <div className="donation-success-message">
          <CheckoutMessage isAuthenticated={isAuthenticated} />
        </div>
        <div className="donation-success-button-container">
          <SuccessButtons
            checkoutSuccess={checkoutSuccess}
            isAuthenticated={isAuthenticated}
          />
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject('authStore')(observer(NewDonationSuccessView)),
);
