import React, { useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import ErrorModal from 'components/modals/ErrorModal/';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { ReactComponent as ImageIcon } from 'assets/images/icons/image/image.svg';
import CommentInputImageUploadModal from './CommentInputImageUploadModal';
import useCheckoutStore from 'stores/CheckoutStoreV2';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close/close.svg';
import placeholder from 'assets/images/placeholders/placeholder-event-image.png';
import {
  generateVideoThumbnail,
  getVideoDurationFromFile,
  urlToImageFile,
} from 'util/image';

const LoadingImage = () => (
  <div className="image-loading-area">
    <CauzeSpinner size="4rem" />
  </div>
);

const CommentInput = ({ style, cauzePurchaseStore, authStore, uiStore }) => {
  const checkoutStore = useCheckoutStore();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(
    checkoutStore?.comment,
  );
  const [comment, setComment] = useState('');
  const [upImg, setUpImg] = useState(placeholder);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const inputRef = useRef(null);
  const imageInputRef = useRef(null);

  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showImageUploadError, setShowImageUploadError] = useState(false);

  const handleCloseImageUploadError = () => {
    setShowImageUploadError(!showImageUploadError);
  };

  const getCommentPlaceholder = () => {
    if (checkoutStore.checkoutType === 'donation') {
      return 'Share your thoughts...';
    }

    return 'Send a personal message!';
  };

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const targetFile = e.target.files[0];

      if (targetFile.type.includes('video')) {
        const duration = await getVideoDurationFromFile(targetFile);

        if (duration > 60) {
          uiStore.showNotification({
            body: 'Video uploads must be under 1 minute.',
            type: 'ERROR',
          });
          return;
        }

        checkoutStore.setContentUploading(true);
        generateVideoThumbnail(targetFile, async ({ image, width, height }) => {
          setPreviewImageUrl(image);

          await Promise.all([
            urlToImageFile(image).then((imgFile) =>
              checkoutStore
                .uploadImage(imgFile)
                .then(({ imageId, previewUrl }) => {
                  checkoutStore.update({
                    commentImageId: imageId,
                    imagePreviewUrl: previewUrl,
                  });
                }),
            ),

            checkoutStore.startVideoUpload({
              width,
              height,
              video: targetFile,
            }),
          ]);

          await checkoutStore.updateDonationCheckout();

          checkoutStore.setContentUploading(false);
        });
      } else {
        setIsChanged(true);
        setShowImageUploadModal(true);
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  return (
    <div style={style}>
      <div className="is-hidden">
        <input
          ref={imageInputRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>

      <CommentInputImageUploadModal
        updateImageUrl={setPreviewImageUrl}
        setUploadingImage={setUploadingImage}
        isActive={showImageUploadModal}
        onToggleClose={() => {
          imageInputRef.current.value = '';
          setShowImageUploadModal(false);
        }}
        cropRatio={[1, 1]}
        src=""
        noPlaceholder
        upImg={upImg}
        setUpImg={setUpImg}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        showFileError={showFileError}
        setShowFileError={setShowFileError}
      />

      <ErrorModal
        onToggleClose={handleCloseImageUploadError}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={cauzePurchaseStore?.imageUploadError}
      />

      <div style={{ width: '100%' }} className="flex-column flex-space-between">
        <div className="comment-area-container">
          {uploadingImage && <LoadingImage />}
          <div className="relative mt-5 border pl-2 pb-2 border-solid border-black overflow-hidden rounded-[10px]">
            {authStore.isAuthenticated &&
              checkoutStore.checkoutType ===
                checkoutStore.CHECKOUT_TYPE.DONATION && (
                <ImageIcon
                  className="absolute top-3.5 right-2 cursor-pointer"
                  onClick={() => {
                    imageInputRef.current.click();
                  }}
                />
              )}
            <TextareaAutosize
              ref={inputRef}
              className={classNames(
                'overflow-hidden focus:outline-none pt-4 w-full border-none resize-none max-sm:text-lg text-agenda pr-6',
                {
                  'is-image': previewImageUrl,
                },
              )}
              value={comment}
              onChange={(ev) => {
                setComment(ev.target.value);
              }}
              onBlur={() => {
                checkoutStore.update({
                  comment: comment,
                });
                checkoutStore.updateCheckout();
              }}
              placeholder={getCommentPlaceholder()}
              maxRows={4}
            />
            {previewImageUrl && (
              <div className="image-container">
                <div
                  className="remove-container relative h-[100px] w-[100px] bg-cover bg-center rounded-[10px] filter hover:brightness-75 group"
                  style={{
                    backgroundImage: `url(${previewImageUrl})`,
                  }}
                >
                  <button
                    title="Remove?"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPreviewImageUrl('');
                      checkoutStore.update({
                        commentImageId: undefined,
                        imagePreviewUrl: undefined,
                      });
                      checkoutStore.updateCheckout();
                    }}
                    className="absolute w-full h-full text-center bg-transparent border-none cursor-pointer hidden group-hover:block"
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject('authStore', 'uiStore')(observer(CommentInput));
