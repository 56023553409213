import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { DashboardCard, YourAccountsCard } from 'views/Donate/components';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import StartCauzeButton from 'components/Button/StartCauzeButton';
import withUserContext from 'behaviors/withUserContext';
import { isSameEntity } from 'util/entity';

const GiveStartCauze = ({
  profileStore,
  activeEntity,
  userProfileStore,
  charityStore,
  companyStore,
  cauzeStore,
}) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({});

    if (['USER', 'INFLUENCER'].includes(activeEntity?.entityType)) {
      const userData = userProfileStore.users.get(+activeEntity.id);

      if (!userData) {
        userProfileStore
          .getUser({
            id: activeEntity.id,
            userContext: activeEntity.userContext,
          })
          .then((resultData) => {
            setData(resultData);
          });
      } else {
        setData(userData);
      }
    }
  }, [activeEntity]);

  return (
    <div className="flex flex-col gap-y-[22px] mt-0 pt-0 px-4 lg:pt-12 lg:px-0">
      <StartCauzeButton activeEntity={activeEntity} />

      <DashboardCard title="Your Impact">
        <div className="flex gap-x-[60px]">
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber
                isCurrency
                amount={
                  (data?.profileData?.userGood?.alltimeGoodSum || 0) / 100
                }
              />
            </div>
            <div className="font-agenda text-sm">Total Good</div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber
                amount={
                  data?.profileData?.userGood?.alltimeInfluencedCount || 0
                }
              />
            </div>
            <div className="font-agenda text-sm">People Inspired</div>
          </div>
        </div>
      </DashboardCard>

      <DashboardCard title="Your Accounts">
        <div className="flex flex-col gap-y-5">
          <YourAccountsCard
            noSwitch
            name="Your Account"
            avatar={
              activeEntity.avatar
                ? activeEntity.avatar
                : activeEntity?.id === profileStore?.data?.id &&
                  profileStore?.data?.avatar
            }
            entityType={activeEntity.entityType}
            userEntity
            amounts={[activeEntity?.balance?.total || 0]}
            userProfileStore={userProfileStore}
            id={activeEntity?.id}
            charityStore={charityStore}
            companyStore={companyStore}
            userContext={activeEntity.userContext}
            cauzeStore={cauzeStore}
            index={0}
            profileStore={profileStore}
            activeEntity={activeEntity}
          />

          {profileStore.availableUserEntities
            .filter((context) => !isSameEntity(context, activeEntity))
            .map((context, index) => (
              <YourAccountsCard
                index={index}
                key={`${index}-${context.id}`}
                id={context?.id}
                avatar={context.avatar}
                name={context.name}
                amounts={[context.balance?.total || 0]}
                entityType={context.entityType}
                userProfileStore={userProfileStore}
                userContext={context.userContext}
                charityStore={charityStore}
                companyStore={companyStore}
                cauzeStore={cauzeStore}
                profileStore={profileStore}
                activeEntity={activeEntity}
              />
            ))}
        </div>
      </DashboardCard>
    </div>
  );
};

export default withUserContext(
  inject(
    'authStore',
    'profileStore',
    'userProfileStore',
    'companyStore',
    'charityStore',
    'cauzeStore',
  )(observer((props) => <GiveStartCauze {...props} />)),
);
