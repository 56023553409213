// scrollUtils.js

export function setShouldScrollToFeed() {
  sessionStorage.setItem('shouldScrollToFeed', 'true');
}

export function clearShouldScrollToFeed() {
  sessionStorage.removeItem('shouldScrollToFeed');
}

export function scrollToFeedIfNeeded(ref) {
  if (sessionStorage.getItem('shouldScrollToFeed') === 'true' && ref?.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
    clearShouldScrollToFeed();
  }
}
