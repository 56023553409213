/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';
import {
  listCountriesQuery,
  quickSearchProjectsQuery,
  trendingTopicsQuery,
} from '../graphql/search';
import {
  followMutation,
  unfollowMutation,
  getFollowersQuery,
  getFollowersQueryV2,
} from '../graphql/follow';
import { trackFollow, trackUnfollow } from '../util/tracking/follow';
import { topCharitiesQuery } from 'graphql/charity';

class SearchStore {
  @observable loading = false;
  @observable results = [];
  @observable page = 1;
  @observable currentSearch = {
    query: '',
    filter: 'none',
    location: '',
    country: 'United States',
    topics: [],
    categories: [],
  };
  @observable activeSearch = {};
  @observable countryWhitelist = ['US'];
  @observable topicSearchLimit = 200;
  @observable topics = [];
  @observable categories = [];
  @observable activeTopic = '';
  @observable activeCategory = '';
  @observable charityFollowers = [];
  @observable loadingFollowers = false;

  @action setPage = (page) => {
    this.page = page;
  };

  @action updateAndRunSearch = async (props) => {
    this.updateSearch(props);
    const charities = await this.getCharities();
    return charities;
  };

  @action updateSearch = (props) => {
    this.currentSearch = { ...this.currentSearch, ...props };
    global.IS_LOCAL_OR_DEV &&
      console.log('Current Search', toJS(this.currentSearch));
  };

  @action loadMoreSearchItems = () => {
    this.page += 1;
  };

  @action getTopCharitiesByZip = async ({ zip }) => {
    const options = {
      variables: { zip },
      query: topCharitiesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    try {
      this.topCharitiesByZipLoading = true;
      const result = await api2Client.query(options);
      return result.data.topProjects;
    } catch (err) {
      console.log(err);
    } finally {
      this.topCharitiesByZipLoading = false;
    }
  };

  @action getCharities = async () => {
    this.page = 1;
    this.loading = true;
    this.activeSearch = {};
    const variables = {
      query: this.currentSearch.query,
      location: this.currentSearch.location,
      country: this.currentSearch.country,
      topics: this.currentSearch.topics,
      categories: this.currentSearch.categories,
    };

    mixpanel.track('Charity Search', {
      channel: 'web',
      query: variables.query,
      location_filter: variables.location,
      country_filter: variables.country,
      topics_filter: variables.topics,
    });

    if (this.currentSearch.filter !== 'none') {
      variables.categories = this.currentSearch.filter;
    }

    if (this.currentSearch.query) {
      this.clearActiveTopic();
      this.clearActiveCategory();
    }

    const options = {
      variables,
      query: quickSearchProjectsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const filters = variables;
      if (
        !variables.query &&
        isEmpty(filters.categories) &&
        isEmpty(filters.topics) &&
        !filters.location &&
        filters.country === 'United States'
      ) {
        this.results = (
          await this.getTopCharitiesByZip({
            zip: filters.location,
          })
        ).map((charity) => ({
          avatar: charity.avatarUrls,
          city: charity.project.city,
          description: charity.project.description,
          ein: charity.project.ein,
          featuredFollowers: charity.project.topSubscriptionEntities || [],
          followerCount: charity.project.totalFollowers,
          id: charity.project.id,
          isSelfFollowing: charity.currentEntityIsFollowing,
          name: charity.name,
          state: charity.project.state,
        }));
      } else {
        const res = await api2Client.query(options);

        this.results = res.data.quickSearchProjects.map((charity) => ({
          avatar: charity.avatarUrls,
          city: charity.city,
          description: charity.description,
          ein: charity.ein,
          featuredFollowers: charity.topSubscriptionEntities || [],
          followerCount: charity.totalFollowers,
          id: charity.id,
          isSelfFollowing: charity.currentEntityIsFollowing,
          name: charity.name,
          state: charity.state,
        }));
      }

      this.activeSearch = this.currentSearch;
      this.isError = false;
      return this.results;
    } catch (err) {
      console.log('err', err);
      this.isError = true;
      this.error = err;
    } finally {
      this.loading = false;
    }
  };

  @action updateCharityFilters = (filters) => {
    this.currentSearch = filters;
  };

  @action getCountryList = async () => {
    const options = {
      query: listCountriesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      let usaIndex = result.data.listCountries.findIndex(
        (country) => country.name === 'United States',
      );
      const _listCountriesLength = result.data.listCountries.unshift(
        result.data.listCountries.splice(usaIndex, 1)[0],
      );
      this.countryWhitelist = result.data.listCountries;
    } catch (err) {
      console.log(err);
    }
  };

  @action resetSearch = () => {
    this.currentSearch = { query: '', filter: 'none', location: '' };
    this.activeSearch = {};
    this.results = [];
  };

  @action getTopics = async () => {
    const options = {
      query: trendingTopicsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      this.topics = result.data.trendingTopics.map((topic) => topic.name);
    } catch (err) {
      console.log(err);
    }
  };

  @action getCategories = async () => {
    try {
      this.categories = [];
    } catch (err) {
      console.log(err);
    }
  };

  @action setActiveTopic = (topic) => {
    this.clearActiveCategory();
    if (this.activeTopic === topic) {
      this.clearActiveTopic();
      this.updateSearch({ topics: [] });
      this.getCharities();
    } else {
      this.activeTopic = topic;
      this.updateSearch({ topics: [topic] });
      this.getCharities();
    }
  };

  @action setActiveCategory = (category) => {
    this.clearActiveTopic();
    if (this.activeCategory === category) {
      this.clearActiveCategory();
      this.updateSearch({ categories: [] });
      this.getCharities();
    } else {
      this.activeCategory = category;
      this.updateSearch({ categories: [category] });
      this.getCharities();
    }
  };

  @action followCharity = ({ follow = true, actorContext, charityId }) => {
    // Only charities are listed in the search results for now, so only need to check context.charityId
    // This may change in the future
    this.toggleLocalCharityFollow(follow, charityId);

    if (follow) {
      trackFollow({ actorContext, targetContext: { charityId } });
    } else {
      trackUnfollow({ actorContext, targetContext: { charityId } });
    }

    const options = {
      variables: {
        targetContext: { charityId },
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.toggleLocalCharityFollow(!follow, charityId);

      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action toggleLocalFollow = (id, follow) => {
    this.charityFollowers
      .filter((entity) => entity.id === id)
      .map((entity) => (entity.isSelfFollowing = follow));
  };

  @action getFollowersForResult = async ({ targetContext, userContext }) => {
    this.loadingFollowers = true;
    this.charityFollowers = [];
    let options = {};

    if (userContext) {
      options = {
        variables: { targetContext, userContext },
        query: getFollowersQuery,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        fetchPolicy: 'no-cache',
      };

      try {
        this.charityFollowers = await apolloClient
          .query(options)
          .then((res) => res.data.getFollowers);
      } catch (err) {
        global.IS_LOCAL_OR_DEV && console.log('getFollowers error', err);
      }
    } else {
      options = {
        variables: { projectId: parseInt(targetContext?.charityId) },
        query: getFollowersQueryV2,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        fetchPolicy: 'no-cache',
      };

      try {
        this.charityFollowers = await api2Client
          .query(options)
          .then((res) => res.data.projectFollowers);
      } catch (err) {
        global.IS_LOCAL_OR_DEV && console.log('projectFollowers error', err);
      }
    }

    this.loadingFollowers = false;
  };

  follow = ({ follow = true, targetContext, actorContext, targetId }) => {
    if (follow) {
      trackFollow({ actorContext, targetContext });
    } else {
      trackUnfollow({ actorContext, targetContext });
    }
    this.toggleLocalFollow(targetId, follow);

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.toggleLocalFollow(targetId, !follow);

      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action toggleLocalCharityFollow = (follow = true, targetId) => {
    this.results
      .filter((result) => result.id === targetId)
      .map((targetResult) => (targetResult.isSelfFollowing = follow));
  };

  @action clearActiveTopic = () => {
    this.activeTopic = '';
  };

  @action clearActiveCategory = () => {
    this.activeCategory = '';
  };

  @action onUnmount = () => {
    this.loading = true;
    this.page = 1;
    this.results = [];
    this.resetSearch();
    this.topics = [];
    this.charityFollowers = [];
    this.loadingFollowers = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const searchStore = new SearchStore();
export default searchStore;
