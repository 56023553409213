import React from 'react';
import classnames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close/close-white.svg';
import CauzeVideo from 'components/CauzeVideo/CauzeVideo';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

const VideoModal = ({ onToggleClose, isOpen, video }) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <div
      className={classnames('modal video-modal !bg-black', {
        'is-active': isOpen,
      })}
    >
      <div className="modal-card">
        <div className="flex justify-between w-screen absolute z-10">
          <button className="bg-transparent border-none p-2 invisible">
            <CloseIcon />
          </button>
          <div></div>
          <button
            className="bg-transparent border-none p-2"
            onClick={() => {
              onToggleClose(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <section className="modal-card-body !bg-black flex justify-center items-center !p-0">
          <CauzeVideo
            video={video}
            defaultMuted={false}
            maxWidth={window.innerWidth}
            maxHeight={window.innerHeight}
            isModal
          />
        </section>
      </div>
    </div>
  );
};

export default VideoModal;
