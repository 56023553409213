import React, { useEffect } from 'react';
import { Redirect } from '@reach/router';

import withUserContext from 'behaviors/withUserContext';
import CompanyCauzesView from './CompanyCauzesView';
import CharityCauzesView from './CharityCauzesView';
import { observer } from 'mobx-react';
import UserCauzesView from './UserCauzesView';
import useDashboardStore from 'stores/DashboardStore';

const ActiveEntityCauzesView = ({ activeEntity }) => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      size: 'half',
    });
  }, []);

  if (
    activeEntity?.entityType === 'USER' ||
    activeEntity?.entityType === 'INFLUENCER'
  ) {
    return <UserCauzesView />;
  } else if (activeEntity?.entityType === 'COMPANY') {
    return <CompanyCauzesView />;
  } else if (activeEntity?.entityType === 'CHARITY') {
    return <CharityCauzesView />;
  }

  return <Redirect to="/login" />;
};

export default withUserContext(observer(ActiveEntityCauzesView));
