import React, { useState, useMemo, useEffect, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import withUserContext from 'behaviors/withUserContext';
import Navbar from 'components/page/Navbar/Navbar';
import { getThnksCompanyId } from 'util/cobrandUtils';
import Sidebar from 'components/Sidebar/Sidebar';
import FooterNew from '../Footer/FooterNew';
import useMobile from 'hooks/useMobile';
import useDashboardStore from 'stores/DashboardStore';
import { LOADING_SCREENS, TOAST } from 'util/constants';
import ContextSwitchLoadingScreen from 'components/LoadingScreen/ContextSwitchLoadingScreen';
import ContextSwitchToast from 'components/Toast/ContextSwitchToast';
import useWalletStore from 'stores/WalletStoreV2';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const DashboardLayout = ({
  children,
  uiStore,
  activeEntity,
  authStore,
  profileStore,
  eventStore,
  bodyRef,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [paddingTop, setPaddingTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const previousScrollPositionRef = useRef(0);
  const { isLaptop } = useMobile();
  const dashboardStore = useDashboardStore();
  const walletStore = useWalletStore();
  const checkoutStore = useCheckoutStore();
  const {
    asideChildren,
    title,
    noSidebar,
    bodyClassName,
    navbarProps,
    size,
    noPadding,
    scroll,
    loadingScreen,
    loadingScreenProps,
    actions,
    isCharityProfilePage,
  } = dashboardStore.props;

  let cobrandCompany = null;
  // Only thnks is a cobrand company for now
  if (uiStore.savedUiState.userToken?.company?.id === getThnksCompanyId()) {
    cobrandCompany = uiStore.savedUiState.userToken.company;
  }

  const calculatedSize = useMemo(() => {
    if (asideChildren) {
      return 'half';
    }

    return size ?? 'screen';
  }, [size]);

  const handleScroll = (target) => {
    const currentScrollPosition = target.scrollTop;
    const threshold = 60;
    const scrollDifference = Math.abs(
      currentScrollPosition - previousScrollPositionRef.current,
    );

    if (scrollDifference >= threshold) {
      if (currentScrollPosition > previousScrollPositionRef.current) {
        setScrollDirection('down');
      } else if (currentScrollPosition < previousScrollPositionRef.current) {
        setScrollDirection('up');
      }
    }

    previousScrollPositionRef.current = currentScrollPosition;

    if (['/donate', '/discover'].includes(window.location.pathname)) {
      dashboardStore.setPrevScrollPosition(title, currentScrollPosition);
    }

    if (currentScrollPosition === 0) {
      setScrollDirection('up');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    bodyRef.current.scrollTo({ top: scroll });
  }, [scroll]);

  useEffect(() => {
    (async () => {
      if (authStore.isAuthenticated) {
        await walletStore.getInitial({
          userContext: authStore.activeEntity?.userContext,
          force: true,
        });
        checkoutStore.update({
          email:
            uiStore.savedUiState.userToken?.email || profileStore?.data?.email,
          activeEntity: profileStore.activeEntity,
        });
      }
    })();
  }, [authStore.isAuthenticated, profileStore.activeEntity]);

  useEffect(() => {
    let interval;

    if (loadingScreen === LOADING_SCREENS.CONTEXT_SWITCH) {
      interval = setTimeout(() => {
        dashboardStore.showLoadingScreen('');
      }, 2500);
    }

    return () => {
      interval && clearTimeout(interval);
    };
  }, [loadingScreen]);

  return (
    <>
      <ContextSwitchLoadingScreen
        {...loadingScreenProps}
        profileStore={profileStore}
        active={loadingScreen === LOADING_SCREENS.CONTEXT_SWITCH}
      />
      <div className="w-screen h-screen overflow-hidden flex flex-col relative">
        <ContextSwitchToast
          {...dashboardStore.toastProps}
          profileStore={profileStore}
          show={dashboardStore.toast === TOAST.CONTEXT_SWITCH}
          onClose={() => dashboardStore.showToast('')}
        />
        <Navbar
          redirectAfterContextSelect={false}
          cobrand={cobrandCompany}
          expanded={expanded}
          setExpanded={setExpanded}
          noSidebar={noSidebar}
          hideNavbar={isLaptop && scrollDirection === 'down'}
          {...navbarProps}
        />
        <div
          id="dashboard-layout-body"
          ref={bodyRef}
          className="dashboard-layout-body overflow-y-auto overflow-x-hidden"
          onScroll={(e) => {
            handleScroll(e.target);

            if (isLaptop) return;

            setPaddingTop(
              Math.min(
                e.target.scrollTop,
                e.target.scrollHeight - e.target.clientHeight - 392, // footer height
              ),
            );
          }}
        >
          <div
            className={cx(
              'relative min-h-screen overflow-y-hidden bg-white pb-4',
              {
                'dashboard-grid': !noSidebar,
                full: calculatedSize === 'full',
                screen: calculatedSize === 'screen',
                half: calculatedSize === 'half',
              },
            )}
          >
            {!noSidebar && (
              <div
                style={{ paddingTop: isLaptop ? 0 : paddingTop }}
                className={cx(
                  'h-full xl:min-w-[320px] xl:max-w-[320px] overflow-hidden absolute xl:relative',
                )}
              >
                <Sidebar
                  activeEntity={activeEntity}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  authStore={authStore}
                  profileStore={profileStore}
                  eventStore={eventStore}
                />
              </div>
            )}
            <div
              className={cx('dashboard-content overflow-hidden h-full w-full', {
                'max-xl:px-4': !noPadding,
                'xl:pl-4 xl:pr-8': calculatedSize === 'screen' && !noPadding,
                [bodyClassName]: bodyClassName,
                'min-h-screen': isCharityProfilePage,
              })}
            >
              <div className="col-span-5 w-full content-container">
                <div
                  className={cx('w-full flex-column flex-align-center', {
                    'pt-4 xl:py-12': !noPadding,
                  })}
                >
                  {title && (
                    <div className="w-full mb-5 flex justify-between">
                      <h1
                        className={cx(
                          'text-left text-2xl font-agenda-bold leading-none my-auto',
                          {
                            'px-4 pt-4 xl:px-0 xl:pt-0': noPadding,
                          },
                        )}
                      >
                        {title}
                      </h1>
                      {actions}
                    </div>
                  )}

                  <div className="w-full">
                    <div>{children}</div>
                  </div>
                </div>
              </div>
            </div>
            {asideChildren && (
              <div className="pl-0 xl:pl-8 max-w-screen xl:max-w-[22.8125rem]">
                {asideChildren}
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <FooterNew />
          </div>
        </div>
      </div>
    </>
  );
};

export default withUserContext(
  inject(
    'donationLandingStore',
    'searchStore',
    'uiStore',
    'authStore',
    'profileStore',
    'eventStore',
  )(observer(DashboardLayout)),
);
