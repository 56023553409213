import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { navigate } from '@reach/router';

import withUserContext from 'behaviors/withUserContext';
import * as events from 'util/events/checkout';

import Button from 'components/Button/Button';
import CheckoutConfirmationModal from 'components/checkout/CheckoutConfirmationModal';
import ErrorModal from 'components/modals/ErrorModal';

import ChooseAmountSectionUser from 'components/checkout/ChooseAmountSectionUser';
import PaymentSectionUser from 'components/checkout/PaymentSectionUser';

import useUserDonationCheckout from 'hooks/useUserDonationCheckout';
import useProfileById from 'hooks/useProfileById';
import ElementsWrapper from 'components/ElementsWrapper';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const DonationUserGiftCheckoutViewContent = observer(
  ({
    userId,
    userContext,
    userGiftStore,
    profileStore,
    authStore,
    activeEntity,
    uiStore,
    userProfileStore,
    location, // location.state for the event view sending configs for default and/or minimum amounts
  }) => {
    const { setProps } = useDashboardStore();
    useProfileById({ id: userId, userContext });
    const user = userProfileStore.users.get(+userId);
    const stripe = useStripe();
    const elements = useElements();

    userGiftStore.setStripeObject(stripe);
    userGiftStore.setStripeElements(elements);

    const isAuthenticated = authStore.isAuthenticated;
    const checkoutDetails = userGiftStore.checkoutDetails;
    const currentCheckout = userGiftStore.currentCheckout;
    const minimumDonationAmount = location?.state?.minimumDonation;
    const waitingForPlaidLoad =
      isAuthenticated && !userGiftStore.plaidLinkToken;
    const waitingForStripeLoad = !stripe || !elements;

    userGiftStore.setIsAuthenticated(isAuthenticated);

    const [showCannotDonate, setShowCannotDonate] = useState(
      activeEntity.entityType === 'CHARITY' && !activeEntity.canDonate,
    );
    useUserDonationCheckout({
      userId,
      isAuthed: authStore.isAuthenticated,
      emailIsPrivate: profileStore?.data?.emailIsPrivate,
      userContext,
      activeEntity,
      giftAmount:
        uiStore.savedUiState?.giftAmount > 0
          ? uiStore.savedUiState.giftAmount
          : null,
    });

    useEffect(() => {
      setShowCannotDonate(
        activeEntity.entityType === 'CHARITY' && !activeEntity.canDonate,
      );
    }, [activeEntity]);

    let isLoading =
      userGiftStore.checkoutLoading ||
      !userGiftStore.checkoutDetails ||
      waitingForStripeLoad ||
      waitingForPlaidLoad ||
      userProfileStore.loading;

    let content = (
      <div className="donation-checkout flex-column">
        {showCannotDonate && (
          <ErrorModal
            isOpen
            errorText="Cannot donate as charity, please change your user from dropdown"
            buttonText="ok"
            onToggleClose={() => setShowCannotDonate(false)}
          />
        )}

        {isAuthenticated && userGiftStore.showConfirmationModal && (
          <CheckoutConfirmationModal
            currentCheckout={currentCheckout}
            isSubmitting={userGiftStore.awaitCompleteCheckout}
            isOpen
            onConfirm={() => {
              events.confirmCardCheckout();
              userGiftStore
                .completeCheckout(isAuthenticated)
                .then((checkoutComplete) => {
                  if (checkoutComplete === true) {
                    navigate('/checkout/user-gift-success');
                  }
                });
            }}
            onToggleClose={userGiftStore.cancelCheckoutConfirmation}
            isAuthenticated={isAuthenticated}
          />
        )}

        {!isAuthenticated && userGiftStore.showConfirmationModal && (
          <CheckoutConfirmationModal
            currentCheckout={currentCheckout}
            isAuthenticated={isAuthenticated}
            isSubmitting={userGiftStore.awaitCompleteCheckout}
            isOpen
            onConfirm={() => {
              events.confirmCardCheckout();
              userGiftStore.completeCheckout().then((checkoutComplete) => {
                if (checkoutComplete === true) {
                  navigate('/checkout/user-gift-success');
                }
              });
            }}
            onToggleClose={userGiftStore.cancelCheckoutConfirmation}
          />
        )}

        {userGiftStore.showErrorModal && (
          <ErrorModal
            isOpen
            errorText={userGiftStore.errorModalText}
            buttonText={userGiftStore.errorModalButtonText}
            onToggleClose={userGiftStore.onErrorModalClose}
          />
        )}

        <div className="column-container flex-align-center">
          <div className="form-container">
            <h1>Send Gift</h1>

            <ChooseAmountSectionUser
              user={user}
              minimumDonationAmount={minimumDonationAmount}
              currentCheckout={currentCheckout}
              checkoutDetails={checkoutDetails}
              userGiftStore={userGiftStore}
              isAuthenticated={isAuthenticated}
              location={location}
            />

            <PaymentSectionUser
              isAuthenticated={isAuthenticated}
              userGiftStore={userGiftStore}
              currentCheckout={currentCheckout}
              minimumDonationAmount={minimumDonationAmount}
              stripe={stripe}
              elements={elements}
              activeEntity={activeEntity}
              uiStore={uiStore}
            />
          </div>
        </div>
      </div>
    );

    if (userContext?.userId === userId) {
      isLoading = false;
      content = (
        <div className="flex-column flex-center flex-expand">
          <div className="text-bold">
            Cannot donate to yourself. Please choose a different user to gift to
          </div>
        </div>
      );
    }

    if (showCannotDonate) {
      isLoading = false;
      content = (
        <div className="flex-column flex-center flex-expand">
          <div className="text-bold">
            Cannot donate as charity. Please select a different user from the
            dropdown
          </div>
        </div>
      );
    }

    if (authStore.isAuthenticated && userGiftStore.checkoutDetailsFail) {
      isLoading = false;
      content = (
        <div className="flex-column flex-center flex-expand">
          <div className="text-bold">Couldn't get checkout Details</div>
          <Button
            style={{ marginTop: '2rem' }}
            className="is-medium"
            onClick={() =>
              userGiftStore.getCheckoutDetails({
                userId,
              })
            }
          >
            Try Again
          </Button>
        </div>
      );
    }

    useEffect(() => {
      setProps({
        size: 'half',
        scroll: 0,
      });
    }, []);

    if (isLoading) {
      return <CauzeSpinner fullscreen />;
    }

    return content;
  },
);

const DonationUserGiftCheckoutView = ({ authStore, ...props }) => {
  return (
    <ElementsWrapper
      elementOptions={{
        setupFutureUsage: authStore.isAuthenticated ? 'on_session' : undefined,
      }}
    >
      <DonationUserGiftCheckoutViewContent authStore={authStore} {...props} />
    </ElementsWrapper>
  );
};

export default withUserContext(
  inject(
    'uiStore',
    'profileStore',
    'authStore',
    'userGiftStore',
    'userProfileStore',
  )(DonationUserGiftCheckoutView),
);
