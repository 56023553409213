import { observable, action, toJS } from 'mobx';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import {
  getPurchaseCommentsQuery,
  cauzePurchaseByIdQuery,
  cauzePurchaseByIdQueryV2,
  likeActorsForPurchaseQueryV2,
} from 'graphql/cauzePurchase';
import { followMutation, unfollowMutation } from 'graphql/follow';
import {
  likeMutation,
  unlikeMutation,
  activityFeedItemQuery,
  createCommentMutation,
  deleteCommentMutation,
  editCommentMutation,
} from 'graphql/feed';
import config from '../config';
import axios from 'axios';
import { getToken } from 'util/storage';
import { createVideoMutation } from 'graphql/content';

const PAGE_SIZE = 50;

const UPLOAD_URL = `${config.UPLOAD_ROOT}/cauze`;
const UPLOAD_URLV2 = `${config.API_ROOTV2}/api`;
const CAUZE_IMAGE_FORM_KEY = `image`;

class CauzePurchaseStore {
  @observable isError = false;
  @observable hasFetchedInitial = false;
  @observable uploadingComment = false;
  @observable feedLoading = true;
  @observable feed = [];
  @observable purchase = {};
  @observable comment = {};
  @observable likeActors = [];
  @observable totalItems = 0;
  @observable videoData = null;

  @observable imageUploadError = false;
  @observable uploadingCommentImage = false;

  @action clearFeed = async () => {
    this.feedLoading = true;
    this.hasFetchedInitial = false;
    this.feed = [];
    this.purchase = {};
    this.totalItems = 0;
    this.likeActors = [];
  };

  @action getPurchaseById = async ({ id }) => {
    try {
      const options = {
        variables: { id },
        query: cauzePurchaseByIdQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      const queryResult = await apolloClient.query(options);
      const purchase = queryResult.data.cauzePurchaseById;
      this.purchase = purchase;
      this.hasFetchedInitial = true;
      if (this.purchase.body) {
        this.totalItems += 1;
      }

      return purchase;
    } catch (err) {
      console.log(err);
    }
  };

  @action getPurchaseByIdV2 = async ({ id }) => {
    try {
      const options = {
        variables: { id: parseInt(id) },
        query: cauzePurchaseByIdQueryV2,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      const queryResult = await api2Client.query(options);
      const purchase = queryResult.data.purchase;
      return {
        ...purchase,
        event: {
          id: purchase.eventId,
        },
      };
    } catch (err) {
      console.log(err);
    }
  };

  @action getFeedItem = async ({ purchaseId }) => {
    try {
      const options = {
        variables: { purchaseId: parseInt(purchaseId) },
        query: activityFeedItemQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      const queryResult = await api2Client.query(options);
      const feedItem = queryResult.data.activityFeedItem;
      return feedItem;
    } catch (err) {
      console.log(err);
    }
  };

  @action getFeed = async ({ userContext, purchaseId }) => {
    this.feedLoading = true;
    this.currentPage = 1;
    this.totalItems = 0;

    try {
      this.feedLoading = true;
      const options = {
        variables: { purchaseId: +purchaseId, userContext },
        query: getPurchaseCommentsQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };

      const queryResult = await api2Client.query(options);
      const feed = queryResult.data.cauzePurchaseComments;

      this.feed = feed;
      this.feedLoading = false;
      return toJS(feed);
    } catch (err) {
      console.log(err);
    }
  };

  @action loadMore = async ({ userContext, purchaseId }) => {
    this.currentPage = this.currentPage + 1;
    if (this.currentPage > this.totalPages) {
      return;
    }
    try {
      const options = {
        variables: {
          userContext,
          purchaseId,
          page: this.currentPage,
          pageSize: PAGE_SIZE,
        },
        query: getPurchaseCommentsQuery,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      const queryResult = await apolloClient.query(options);
      this.feed = this.feed.concat(
        queryResult.data.commentsByPurchase[0].items,
      );
    } catch (err) {
      console.log(err);
    }
  };

  @action like = ({ like = true, purchaseId, index }) => {
    this.feed[index].hasLiked = like;
    this.feed[index].likes = like
      ? this.feed[index].likes + 1
      : this.feed[index].likes - 1;

    const options = {
      variables: { id: purchaseId },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    api2Client.mutate(options).catch((err) => {
      this.feed[index].hasLiked = !like;
      this.feed[index].likes = like
        ? this.feed[index].likes - 1
        : this.feed[index].likes + 1;
      if (global.IS_DEV) {
        console.log(err);
      }
    });
  };

  @action getLikeActors = async ({ purchaseId }) => {
    try {
      const options = {
        variables: { purchaseId: +purchaseId },
        query: likeActorsForPurchaseQueryV2,
        fetchPolicy: 'network-only',
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };

      const queryResult = await api2Client.query(options);
      this.likeActors = queryResult.data.cauzePurchaseLikes;
      return this.likeActors;
    } catch (err) {
      console.log(err);
    }
  };

  @action follow = ({ follow = true, targetContext, actorContext }) => {
    this.likeActors.forEach((likeActor, index) => {
      if (
        (likeActor.entityType === 'USER' &&
          likeActor.id === targetContext.userId) ||
        (likeActor.entityType === 'CHARITY' &&
          likeActor.id === targetContext.charityId) ||
        (likeActor.entityType === 'COMPANY' &&
          likeActor.id === targetContext.companyId)
      ) {
        this.likeActors[index].isSelfFollowing = follow;
      }
    });

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.likeActors.forEach((likeActor, index) => {
        if (
          (likeActor.entityType === 'USER' &&
            likeActor.id === targetContext.userId) ||
          (likeActor.entityType === 'CHARITY' &&
            likeActor.id === targetContext.charityId) ||
          (likeActor.entityType === 'COMPANY' &&
            likeActor.id === targetContext.companyId)
        ) {
          this.likeActors[index].isSelfFollowing = !follow;
        }
      });
      if (global.IS_DEV) {
        console.log(err);
      }
    });
  };

  @action updateComment = (props) => {
    this.comment = { ...this.comment, ...props };
  };

  @action addComment = async ({
    purchaseId,
    body,
    imageId,
    previewUrl,
    userContext,
  }) => {
    this.uploadingComment = true;
    const options = {
      variables: {
        purchaseId: parseInt(purchaseId),
        body,
        imageId: parseInt(imageId),
        videoId: this.videoData?.id ? parseInt(this.videoData.id) : undefined,
        previewUrl,
      },
      mutation: createCommentMutation,
      errorPolicy: 'none',
    };

    try {
      if (this.videoData) {
        await this.completeVideoUpload();
      }

      await api2Client.mutate(options);
      this.clearComment();
      const feed = await this.getFeed({ purchaseId, userContext });
      this.uploadingComment = false;
      return feed;
    } catch (err) {
      console.log(err);
      this.uploadingComment = false;
      this.hostCommentCreateError = true;
    }
  };

  @action deleteComment = async ({ commentId }) => {
    const options = {
      variables: { commentId },
      mutation: deleteCommentMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
      this.totalItems = this.totalItems - 1;
      this.clearComment();
      this.feed = this.feed.filter((comment) => comment.id !== commentId);
    } catch (err) {
      global.IS_DEV && console.log(err);
    }
  };

  @action editComment = async ({
    commentId,
    purchaseId,
    body,
    imageId = null,
    previewUrl = null,

    imagePreviewUrl = null,
  }) => {
    this.uploadingComment = true;
    const options = {
      variables: {
        commentId,
        body,
        previewUrl,
        imageId,
        videoId: this.videoData?.id ? parseInt(this.videoData.id) : undefined,
        imagePreviewUrl,
      },
      mutation: editCommentMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      if (this.videoData) {
        await this.completeVideoUpload();
      }

      await api2Client.mutate(options);
      this.uploadingComment = false;
      this.clearComment();
      if (commentId) {
        const updateFeedIndex = this.feed.findIndex(
          (comment) => comment.id === commentId,
        );
        this.feed[updateFeedIndex] = {
          ...this.feed[updateFeedIndex],
          body,
          previewUrl,
          imageId,
          image: { lg: imagePreviewUrl },
        };
      }
      if (purchaseId) {
        this.purchase = {
          ...this.purchase,
          body,
          previewUrl,
          imageId,
          image: { lg: imagePreviewUrl },
        };
      }
    } catch (err) {
      this.uploadingComment = false;
      global.IS_DEV && console.log(err);
    }
  };

  @action clearComment = () => {
    this.comment = {};
    this.videoData = null;
  };

  @action uploadImage = async (imageFile) => {
    if (imageFile) {
      try {
        this.uploadingCommentImage = true;

        const token = await getToken();
        const url = UPLOAD_URL;
        const data = new FormData();
        data.append(CAUZE_IMAGE_FORM_KEY, imageFile);
        console.log('res.data', this.comment);
        const res = await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.comment = {
          imageId: res.data.id,
          previewUrl: res.data.urls.md,
        };

        // This is because the upload put is so fast the user can't see
        // the progress spinner, made them think it didn't work sometimes
        setTimeout(() => {
          this.uploadingCommentImage = false;
        }, 1000);

        return {
          imageId: res.data.id,
          previewUrl: res.data.urls.md,
        };
      } catch (err) {
        this.uploadingCommentImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action startVideoUpload = async ({ width, height, video }) => {
    const options = {
      variables: { width, height },
      mutation: createVideoMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.mutate(options);

      this.videoData = {
        width,
        height,
        video,
        id: result.data.createVideo.id,
      };
    } catch (err) {
      console.log('startVideoUploadError:', err);
      this.videoData = null;
    }
  };

  @action completeVideoUpload = async () => {
    const token = await getToken();
    const data = new FormData();
    data.append('file', this.videoData.video);

    try {
      await axios.post(
        `${UPLOAD_URLV2}/video/${this.videoData.id}/upload`,
        data,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        },
      );
    } catch (err) {
      // pass
    }
  };

  @action resetImage = () => {
    this.imageUploadError = false;
    this.videoData = null;
    this.comment = {
      imageId: null,
      previewImageUrl: '',
    };
  };
}

const cauzePurchaseStore = new CauzePurchaseStore();
export default cauzePurchaseStore;
