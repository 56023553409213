import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import useProfileById from 'hooks/useProfileById';
import { normalizeContext } from 'util/contextUtils';
import convertFeedToV1 from 'util/feed/convertFeedToV1';

import EntityProfile from 'components/EntityProfile/EntityProfile';
import mergeFeedItems from 'util/feed/mergeFeedItems';
import useWalletStore from 'stores/WalletStoreV2';
import { isSameEntity } from 'util/entity';

const ProfileView = ({
  authStore,
  profileStore,
  uiStore,
  userProfileStore,
  id,
  activeEntity,
}) => {
  useProfileById({ id });
  const profileData = userProfileStore.users.get(+id) || {};
  const feed = userProfileStore.feeds.get(+id);
  const events = userProfileStore.events.get(+id);
  const charities = userProfileStore.charities.get(+id);
  const walletStore = useWalletStore();

  const onFollow = () => {
    userProfileStore.follow({
      follow: !profileData.isSelfFollowing,
      actorContext: normalizeContext(profileStore.activeEntity.userContext),
      userId: id,
    });
  };

  const onLike = ({ feedId }) => {
    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );
    const feedItemIndex = feed?.findIndex(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );
    if (feedItem) {
      userProfileStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        userId: id,
        userContext: normalizeContext(profileStore.activeEntity.userContext),
      });
    }
    feed[feedItemIndex].likeCount = !feedItem.currentEntityLiked
      ? feedItem.likeCount + 1
      : feedItem.likeCount - 1;
    feed[feedItemIndex].currentEntityLiked = !feedItem.currentEntityLiked;
  };

  const onPin = ({ eventId, pinned }) => {
    userProfileStore.pinEvent({ eventId, pinned, userId: id });
  };

  const feedLoadMore = () => {
    userProfileStore.loadMore({ id });
  };

  const onRearrange = async (newCharities) => {
    if (id) {
      userProfileStore.setCharities({
        followerId: id,
        charities: newCharities,
      });
    }

    await Promise.all(
      newCharities.map(async (charity, index) => {
        userProfileStore.updateFollow({
          charityId: charity.id,
          priority: index,
        });
      }),
    );
  };

  const showCheckoutModal = () => {
    uiStore.openModal('CHECKOUT', {
      activeEntity,
      walletStore,
      giftToken: uiStore.savedUiState?.userToken?.token,
      hideAmount: uiStore.savedUiState?.userToken?.gift?.hideAmount,
      giftAmount: uiStore.savedUiState?.userToken?.gift?.amount,
      email: uiStore.savedUiState.userToken?.email || profileStore?.data?.email,
      initiatorId: uiStore.savedUiState.userToken?.gift?.initiatorCompany?.id,
      checkoutType: 'gift',
      userProfileData: profileData,
      userIds: [id],
      eventId: null,
      firstName: activeEntity?.firstName,
      lastName: activeEntity?.lastName,
      zip: activeEntity?.zip,
      amount: 500,
      giftType: 'USER_GIFT',
      onCheckoutSuccess: async (state) => {
        uiStore.showNotification({
          body: `${state.amount === 0 ? 'Invites' : 'Gifts'} Sent`,
          type: 'SUCCESS',
        });
        profileStore.getProfile();
      },
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      isSameEntity(activeEntity, {
        entityType: 'USER',
        id: profileData?.id,
      })
    ) {
      return;
    }

    if (Object.keys(profileData).length > 0) {
      if (params.get('gift') === '1') {
        showCheckoutModal();
      }
    }
  }, [profileData]);

  return (
    <EntityProfile
      activeEntity={activeEntity}
      showCheckoutModal={showCheckoutModal}
      featuredProjects={charities}
      onCheckoutSuccess={() => {
        if (id) {
          userProfileStore.getFeed({
            id,
            userContext: activeEntity.userContext,
          });
        }
      }}
      loading={userProfileStore.loading || !Object.keys(profileData).length}
      onRearrange={onRearrange}
      entityData={{
        ...profileData,
        name: `${profileData.firstName} ${profileData.lastName}`,
        avatar: profileData.avatarUrls,
        followerCount: profileData.profileData?.followerCount,
        followingCount: profileData.profileData?.followingCount,
        hero: profileData.heroUrls,
      }}
      isAuthenticated={authStore.isAuthenticated}
      onFollow={onFollow}
      onLike={onLike}
      uiStore={uiStore}
      events={events}
      eventsLoading={userProfileStore.eventsLoading}
      feedLoading={userProfileStore.feedLoading}
      feedLoadMore={feedLoadMore}
      feedLoadingMore={userProfileStore.feedLoadingMore}
      feed={mergeFeedItems(feed)?.map(convertFeedToV1)}
      entityType="USER"
      isVerified={profileData.isVerified}
      isInfluencer={profileData?.profileData?.isInfluencer}
      hideFollowerCount={false}
      showEditButton={
        profileData.isCurrentEntity ||
        profileStore.activeEntity?.sysAdmin ||
        activeEntity?.id === id
      }
      isSelf={profileData.isCurrentEntity || activeEntity?.id === id}
      onPin={onPin}
      store={userProfileStore}
      entityId={id}
    />
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'userProfileStore',
    'uiStore',
  )(observer(ProfileView)),
);
