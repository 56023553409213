import { observer, inject } from 'mobx-react';
import { useEffect, useRef } from 'react';

import withUserContext from 'behaviors/withUserContext';
import useEvent from 'hooks/useEvent';
import CauzeFundraiserEventView from './CauzeFundraiserEventView';
import FuturePhilanthropyView from './FuturePhilanthropyView';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useMobile from 'hooks/useMobile';

const EventView = (props) => {
  const { setProps } = useDashboardStore();
  const feedSectionRef = useRef(null);
  const { isMobile } = useMobile();

  let parsedEventId =
    decodeURIComponent(props.eventId).split('?').length > 1 &&
    decodeURIComponent(props.eventId).split('?')[0];

  const sanitizedEventId = parsedEventId || props.eventId;
  useEvent({
    eventId: sanitizedEventId,
    purchaseId: props.purchaseId,
    userContext: props.userContext,
  });

  const eventConfig = props.eventStore?.eventData?.config || {
    donationAmountPresets: [2000, 2500, 5000],
    minimumDonation: 10000,
    template: 'default',
  };

  const templates = {
    default: FuturePhilanthropyView,
    cauze_fundraise: CauzeFundraiserEventView,
    future_philanthropy: FuturePhilanthropyView,
  };

  useEffect(() => {
    setProps({
      size: 'full',
      bodyClassName: '!overflow-visible',
    });
  }, []);

  if (props.eventStore.loading || !props.eventStore.eventData) {
    return <CauzeSpinner fullscreen />;
  }

  const Template = templates[eventConfig.template] || templates['default'];
  return <Template {...props} feedSectionRef={feedSectionRef} />;
};

export default withUserContext(
  inject(
    'eventStore',
    'profileStore',
    'uiStore',
    'matchStore',
    'authStore',
  )(observer(EventView)),
);
