import { create } from 'zustand';

const initialState = {
  props: {
    isCharityProfilePage: false,
  },
  toast: null,
  toastProps: {},
  prevScrollPosition: {},
  dashboardTabIndex: 0,
};

const useDashboardStore = create((set, get) => ({
  ...initialState,
  setProps: (props) =>
    set({
      props: {
        ...props,
        scroll: get().prevScrollPosition?.[props.title] ?? 0,
      },
    }),
  updateProps: (props) => {
    const state = get();
    set({
      props: {
        loadingScreen: state.props.loadingScreen,
        loadingScreenProps: state.props.loadingScreenProps,
        ...props,
      },
    });
  },
  showLoadingScreen: (loadingScreen, loadingScreenProps) => {
    const state = get();
    state.setProps({ ...state.props, loadingScreen, loadingScreenProps });
  },
  showToast: (toast, toastProps = {}) => {
    set({ toast, toastProps });
  },
  setPrevScrollPosition: (title, prevScrollPosition) => {
    const state = get();
    set({
      prevScrollPosition: {
        ...state.prevScrollPosition,
        [title]: prevScrollPosition,
      },
    });
  },
  setDashboardTabIndex: (dashboardTabIndex) => {
    set({ dashboardTabIndex });
  },
}));

export default useDashboardStore;
