import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Button from 'components/Button/Button';

import placeholderImage from 'assets/images/icons/placeholder/placeholder-charity@3x.png';
import useDashboardStore from 'stores/DashboardStore';
import classnames from 'classnames';
import { navigate } from '@reach/router';
import { useCauzeRoutingContext } from 'hooks/useCauzeRoutingContext';
import ImageUploadModal from 'components/modals/ImageUploadModal/ImageUploadModal';

const InputContainer = ({ title, error, children, style }) => (
  <div
    style={style}
    className={classnames('input-container flex-column', {
      'input-container-error': error,
    })}
  >
    <p className={classnames('input-title flex-column')}>{title}</p>
    {children}
    <p className="input-container-error-message">{error}</p>
  </div>
);

const handleCompanyLogoChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCompanyLogoFile(file);
    store.setCompanyLogoUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const handleCompanyHeroChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCompanyHeroFile(file);
    store.setCompanyHeroUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const CompanyProfileView = ({ companyAdminStore, companyId, uiStore }) => {
  useCompanyAdmin(companyId);
  const { setProps } = useDashboardStore();
  const [profileImageHasChanged, setProfileImageHasChanged] = useState(false);
  const [coverImageHasChanged, setCoverImageHasChanged] = useState(false);
  const [bioTextHasChanged, setBioTextHasChanged] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [upImg, setUpImg] = useState(null);

  const { navigateWithConfirm } = useCauzeRoutingContext();
  let companyLogoPreview = null;
  if (companyAdminStore.companyLogoUrl) {
    companyLogoPreview = (
      <img
        className="profile-image-logo"
        src={companyAdminStore.companyLogoUrl}
      />
    );
  } else {
    companyLogoPreview = (
      <img className="profile-image-logo" src={placeholderImage} />
    );
  }

  let companyHeroPreviewUrl = null;
  if (companyAdminStore.companyHeroUrl) {
    companyHeroPreviewUrl = companyAdminStore.companyHeroUrl;
  } else {
    companyHeroPreviewUrl = placeholderImage;
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3000000) {
        setShowFileError(
          'File is too large. Please select a file less than 3MB.',
        );
        return;
      }
      setIsChanged(true);
      setShowImageUploadModal(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onFileUpload = (imageFile) => {
    companyAdminStore.setCompanyHeroFile(imageFile);
  };

  const handleSave = async () => {
    try {
      let success = false;

      if (profileImageHasChanged) {
        await companyAdminStore.submitCompanyLogo();
      }

      if (coverImageHasChanged) {
        await companyAdminStore.submitCompanyHero();
      }

      if (bioTextHasChanged) {
        await companyAdminStore.updateBio();
      }

      success = true;

      if (success) {
        uiStore.showNotification({
          body: 'Profile Updated',
          type: 'SUCCESS',
        });
        navigate(`/company/${companyId}`);
      } else {
        uiStore.showNotification({
          body: 'No changes were made to update.',
          type: 'ERROR',
        });
      }
    } catch (error) {
      uiStore.showNotification({
        body: 'There was an issue updating your profile. Support has been notified',
        type: 'ERROR',
      });
    }
  };

  useEffect(() => {
    setProps({
      size: 'half',
      title: 'Your Profile',
      actions: (
        <a
          className="profile-link font-agenda-bold"
          onClick={() => navigateWithConfirm(`/company/${companyId}`)}
        >
          View Profile Page
        </a>
      ),
    });
  }, []);

  return (
    <div className="charity-admin user-details charity-profile flex-column flex-expand">
      <ImageUploadModal
        store={companyAdminStore}
        cropRatio={[544, 181]}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        isChanged={isChanged}
        setIsChanged={setIsChanged}
        upImg={upImg}
        setUpImg={setUpImg}
        showFileError={showFileError}
        setShowFileError={setShowFileError}
        onFileUpload={onFileUpload}
        updateImageUrl={(imageUrl) => {
          companyAdminStore.setCompanyHeroUrl(imageUrl);
          setCoverImageHasChanged(true);
        }}
      />
      <section className="page-body user-profile-form">
        <div className="w-full">
          <InputContainer title="Profile Image">
            <div className="image-input">
              {companyLogoPreview}
              <label className="image-upload-label" htmlFor="logo-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="logo-upload"
                onChange={(e) => {
                  handleCompanyLogoChange(e, companyAdminStore);
                  setProfileImageHasChanged(true);
                }}
              />
            </div>
          </InputContainer>

          <InputContainer title="Cover Image" style={{ gridColumn: 'span 2' }}>
            <div className="image-input">
              <div className="profile-hero-image-container">
                <div className="profile-hero-image-overlay" />

                <div
                  className="profile-hero-image"
                  style={{
                    backgroundImage: `url('${companyHeroPreviewUrl}')`,
                  }}
                />
              </div>
              <label className="image-upload-label" htmlFor="hero-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="hero-upload"
                onChange={onSelectFile}
              />
            </div>
          </InputContainer>
          <InputContainer title="Bio">
            <textarea
              name="mission_statement"
              onChange={(ev) => {
                companyAdminStore.setBioText(ev.target.value);
                setBioTextHasChanged(true);
              }}
              placeholder="Describe your companies charitable mission"
              value={companyAdminStore.bio}
              rows={8}
            />
          </InputContainer>
          <Button
            className="save-button is-medium max-xl:w-full"
            type="button"
            outline
            onClick={handleSave}
            isSubmitting={
              companyAdminStore.isUploadingCompanyLogo ||
              companyAdminStore.isUploadingCompanyHero ||
              companyAdminStore.updatingBioText
            }
          >
            Save Changes
          </Button>
        </div>
      </section>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject('companyAdminStore', 'uiStore')(observer(CompanyProfileView)),
  ),
  { entityType: 'COMPANY' },
);
