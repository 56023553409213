import React from 'react';
import { inject, observer } from 'mobx-react';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo-confirmed.svg';
import { ReactComponent as AppleStoreLogo } from 'assets/images/icons/apple/app-store.svg';
import { ReactComponent as GooglePlayStoreLogo } from 'assets/images/icons/google/play-store.svg';
import Avatar from 'components/Avatar/Avatar';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const CheckoutCompleteContent = ({
  uiStore,
  authStore,
  setShouldScrollToFeedAndScroll,
}) => {
  const checkoutStore = useCheckoutStore();
  const joinedEntity = checkoutStore?.joinedEntity;

  return (
    <div className="flex-1 w-full px-5 pt-5 flex flex-col justify-between">
      <div className="flex flex-col  mb-5">
        <div className="flex justify-center mb-2.5">
          <CauzeLogo />
        </div>
        <div className="text-center text-gray-1b2 font-agenda-black text-[24px] leading-[30px] mb-2.5">
          CONFIRMED!
        </div>
        <div className="text-center px-4 text-gray-1b2 font-agenda text-[20px] leading-tight mb-5">
          Your donation is on the way and {joinedEntity?.name} has been notified
          that you joined!
        </div>
        {joinedEntity && !authStore.isAuthenticated && (
          <div className="flex gap-x-2.5 mx-auto">
            <Avatar
              className="my-auto"
              avatarUrls={joinedEntity?.avatarUrls}
              style={{
                width: '37px',
                height: '37px',
              }}
            />
            <div className="my-auto font-agenda-bold text-gray-1b2 leading-none text-[14px]">
              Download Cauze to follow {joinedEntity?.name}.
            </div>
          </div>
        )}
      </div>
      {!authStore.isAuthenticated && (
        <>
          <div className="mt-auto mb-5  justify-center flex gap-2.5 h-[53px] w-full">
            <a href="https://apps.apple.com/us/app/cauze-charitable-giving/id1297994796">
              <AppleStoreLogo className="w-full" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.cauze.Cauze">
              <GooglePlayStoreLogo className="w-full" />
            </a>
          </div>
          <a
            href="/signup"
            className="underline text-center font-agenda-bold text-gray-1b2 h-[17px] text-[14px]"
          >
            CREATE AN ACCOUNT
          </a>
        </>
      )}
      {authStore.isAuthenticated && (
        <button
          className="cz-btn-outline black"
          onClick={() => {
            uiStore.closeModal();
            checkoutStore.reset();
            setShouldScrollToFeedAndScroll();
          }}
        >
          CLOSE
        </button>
      )}
    </div>
  );
};

export default inject(
  'uiStore',
  'authStore',
)(observer(CheckoutCompleteContent));
