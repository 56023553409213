import React, { useState } from 'react';
import cx from 'classnames';
import { useSpring, animated } from '@react-spring/web';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import SliderModal from '../SliderModal/SliderModal';

import { observer } from 'mobx-react';
import CommentInput from './CommentInput';
import useMobile from 'hooks/useMobile';

const CauzeUpdateModal = ({
  onToggleClose,
  isOpen,
  commentProps,
  uiStore,
  eventStore,
}) => {
  const { isMobile } = useMobile();
  const [closed, setClosed] = useState(false);
  const [comment, setComment] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  useKeyboardEvent('Escape', () => {
    if (!isPosting) {
      onToggleClose(false);
    }
  });

  const [asideProps] = useSpring(
    () => ({
      from: { right: isMobile ? 0 : -376, bottom: isMobile ? -376 : 0 },
      to: { right: 0, bottom: 0 },
      reverse: closed,
      config: {
        duration: 200,
      },
      onResolve: () => {
        if (closed) {
          onToggleClose();
        }
      },
    }),
    [isOpen, closed],
  );

  const handleSuccess = () => {
    uiStore.showNotification({
      body: 'Your update has been posted successfully.',
      type: 'SUCCESS',
    });
    setIsPosting(false);
    onToggleClose(false);
    eventStore.getEventUpdates({
      id: commentProps.event?.id,
    });
  };

  const handlePost = (ev) => {
    if (comment && !isPosting) {
      ev.preventDefault();
      setIsPosting(true);

      eventStore
        .postEventUpdate({
          body: comment,
          eventId: commentProps.event?.id,
          purchaseId: commentProps.purchaseId,
          ...eventStore.commentImageData,
        })
        .then(handleSuccess)
        .catch(() => setIsPosting(false));
    }
  };

  return (
    <SliderModal
      onToggleClose={onToggleClose}
      isOpen={!closed}
      isActive={isOpen}
    >
      <animated.aside
        style={asideProps}
        className="absolute h-auto lg:h-screen flex flex-col w-screen lg:w-[376px] bg-white rounded-t-[20px] lg:rounded-l-[20px] lg:rounded-tr-none add-funds-sidebar pb-5 pt-5 overflow-y-auto"
      >
        <div className="self-end !absolute top-0 left-0 right-0">
          <div className="flex justify-end w-full">
            <button
              className={cx(
                'mt-2 bg-transparent border-none text-gray-1b2 cursor-pointer',
              )}
              onClick={() => {
                setClosed(true);
              }}
            >
              <div className="delete " />
            </button>
          </div>
        </div>
        <div className="flex-1 w-full px-5 flex flex-col justify-between">
          <div>
            <div className="flex justify-center w-full">
              <div className="font-agenda-bold text-[20px] mx-auto py-2">
                Update Your Joiners
              </div>
            </div>
            <div className="text-center font-agenda text-[15px] leading-tight">
              Let everyone who has joined your cauze know how it's coming along.
            </div>
            <CommentInput
              comment={comment}
              setComment={setComment}
              uploadingImage={uploadingImage}
              setUploadingImage={setUploadingImage}
            />
          </div>
          <button
            disabled={!comment || isPosting || uploadingImage}
            className={cx('cz-btn-brand w-full mt-2.5 md:mt-8', {
              'bg-lightgray-999': !comment || isPosting || uploadingImage,
            })}
            onClick={handlePost}
          >
            POST UPDATE
          </button>
        </div>
      </animated.aside>
    </SliderModal>
  );
};

export default observer(CauzeUpdateModal);
