import React from 'react';

import { getEntityUrl } from 'util/navHelpers';

const FeedCardMatch = ({ matches, uiStore }) => {
  const onProfileViewClick = (e, match) => {
    e.preventDefault();
    e.stopPropagation();
    uiStore.openModal('PROFILE_MODAL', {
      id: match.id,
      entityType: match.type,
    });
  };
  return (
    <div className="feed-card-match">
      {matches.map((match, index) => (
        <div key={index} className="match flex-row flex-align-center">
          <span>
            <a
              onClick={(e) =>
                onProfileViewClick(e, { id: match.id, type: match.entityType })
              }
            >
              {match.name}
            </a>{' '}
            matched {match.multiplier > 1 ? `${match.multiplier}X` : ''}
          </span>
        </div>
      ))}
    </div>
  );
};

export default FeedCardMatch;
