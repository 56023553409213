import { useEffect } from 'react';
import userProfileStore from '../stores/UserProfileStore';

const useProfileById = ({ id, userContext, isLanding }) => {
  useEffect(() => {
    if (id) {
      userProfileStore.getUser({ id, userContext });
      userProfileStore.getEvents({ id });
      userProfileStore.getUserJoinedEvents({ id });
      userProfileStore.setLedgerFilters({});

      if (!isLanding) {
        userProfileStore.getFeed({ id, userContext });
      }
    }
  }, [id, userContext, isLanding]);
};

export const useBasicProfile = ({ id, userContext }) => {
  useEffect(() => {
    if (id) {
      userProfileStore.getUser({ id, userContext });
    }
  }, [id, userContext]);
};

export default useProfileById;
