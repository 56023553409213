import React, { useState, useRef, useMemo } from 'react';
import ReactCrop from 'react-image-crop';
import classnames from 'classnames';
import 'react-image-crop/src/ReactCrop.scss';

import { centerAspectCrop } from 'util/image';
import useMobile from 'hooks/useMobile';

const calculateAspectRatioFitRobust = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
) => {
  if (srcWidth <= 0 || srcHeight <= 0 || maxWidth <= 0 || maxHeight <= 0) {
    return { width: 0, height: 0 }; // Handle invalid input
  }

  const ratio = srcWidth / srcHeight;

  let width = srcWidth;
  let height = srcWidth;

  if (srcWidth > maxWidth) {
    width = maxWidth;
    height = width / ratio;
  } else if (srcHeight > maxHeight) {
    height = maxHeight;
    width = height * ratio;
  }

  return { width, height };
};

const CommentInputImageUploadModal = ({
  store,
  onToggleClose,
  isActive,
  updateImageUrl,
  setUploadingImage,
  noPlaceholder,
  cropRatio = [16, 9],
  isChanged,
  setIsChanged,
  upImg,
  setUpImg,
  showFileError,
  setShowFileError,
}) => {
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const { isMobile } = useMobile();
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    aspect: cropRatio[0] / cropRatio[1],
  });
  const [finalCrop, setFinalCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    aspect: cropRatio[0] / cropRatio[1],
  });
  const maxSize = [
    document.body.clientWidth - 16,
    document.body.clientHeight - 325,
  ];

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3000000) {
        setShowFileError(
          'File is too large. Please select a file less than 3MB.',
        );
        return;
      }
      setIsChanged(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function onLoad(e) {
    const { naturalWidth, naturalHeight } = e.currentTarget;

    const _crop = centerAspectCrop(
      naturalWidth,
      naturalHeight,
      cropRatio[0] / cropRatio[1],
    );
    const _finalCrop = centerAspectCrop(
      naturalWidth,
      naturalHeight,
      cropRatio[0] / cropRatio[1],
    );

    setCrop(_crop);
    setFinalCrop(_finalCrop);
  }

  const updateFinalCrop = async (crop, percentCrop) => {
    if (imgRef.current && crop.width && crop.height) {
      setFinalCrop(percentCrop);
      setIsChanged(true);
    }
  };

  const uploadImage = async () => {
    if (!isChanged) {
      onToggleClose();
      return;
    }
    const image = imgRef.current;
    const fileName = 'uploadedImage.jpg';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth * (finalCrop.width / 100);
    const scaleY = image.naturalHeight * (finalCrop.height / 100);
    canvas.width = scaleX;
    canvas.height = scaleY;

    ctx.drawImage(
      image,
      (finalCrop.x / 100) * image.naturalWidth,
      (finalCrop.y / 100) * image.naturalHeight,
      scaleX,
      scaleY,
      0,
      0,
      scaleX,
      scaleY,
    );

    setUploadingImage(true);

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        updateImageUrl(window.URL.createObjectURL(file));
        store
          .uploadImage(new File([file], fileName, { lastModified: new Date() }))
          .then(() => {
            setUploadingImage(false);
            setIsChanged(false);
            onToggleClose();
            resolve(file);
          });
      }, 'image/jpeg');
    });
  };

  const imgStyle = useMemo(() => {
    const image = imgRef.current;
    if (!isMobile || !maxSize || !image) return {};

    const { width, height } = calculateAspectRatioFitRobust(
      image.naturalWidth,
      image.naturalHeight,
      maxSize[0],
      maxSize[1],
    );
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }, [maxSize, imgRef]);

  return (
    <div
      className={classnames('modal image-upload-modal', {
        'is-active': isActive,
      })}
    >
      <div onClick={onToggleClose} className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body !bg-black !p-0">
          <div className="flex-row flex-center h-full overflow-hidden px-2">
            <ReactCrop
              style={imgStyle}
              src={upImg}
              ruleOfThirds
              crop={crop}
              onChange={(c) =>
                setCrop({
                  ...c,
                  aspect: cropRatio[0] / cropRatio[1],
                })
              }
              onComplete={updateFinalCrop}
              aspect={cropRatio[0] / cropRatio[1]}
            >
              <img
                className="h-full w-full"
                ref={imgRef}
                src={upImg}
                onLoad={onLoad}
              />
            </ReactCrop>
          </div>
          <div className="is-hidden">
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </div>
        </section>
        <footer className="modal-card-foot flex gap-x-2 justify-between">
          <button
            className="cz-btn-outline brand flex-1"
            type="submit"
            onClick={onToggleClose}
          >
            Cancel
          </button>
          <button
            className="cz-btn-brand flex-1"
            disabled={noPlaceholder && !isChanged}
            onClick={(e) => {
              e.preventDefault();
              uploadImage();
              onToggleClose();
            }}
          >
            Save
          </button>
        </footer>
      </div>
    </div>
  );
};

export default CommentInputImageUploadModal;
