/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { navigate } from '@reach/router';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import { userByIdMinQuery } from 'graphql/user';
import { companyByIdQuery } from 'graphql/company';
import {
  eventQueryApi2,
  eventPurchasersQuery,
  eventPurchaserQuery,
  createCommentMutation,
  followEventMutation,
  unfollowEventMutation,
  pinEventMutation,
  eventHostUpdatesQuery,
  createEventHostUpdateMutation,
  deleteEventHostUpdateMutation,
} from 'graphql/event.js';
import { likeMutation, unlikeMutation } from 'graphql/like';
import { followMutation, unfollowMutation } from 'graphql/follow';
import { trackFollow, trackUnfollow } from 'util/tracking/follow';

import { eventFeedQuery, purchaseFeedQuery } from 'graphql/feed';
import uiStore from 'stores/UiStore';
import { getToken } from 'util/storage';
import config from '../config';
import shuffleArray from 'util/shuffle';
import { matchEntityToContext } from 'util/contextUtils';
import { createVideoMutation } from 'graphql/content';

const UPLOAD_URL = `${config.UPLOAD_ROOT}/cauze`;
const UPLOAD_URLV2 = `${config.API_ROOTV2}/api`;
const CAUZE_IMAGE_FORM_KEY = `image`;

class EventStore {
  @observable loading = true;
  @observable loadingPurchases = true;
  @observable isError = false;
  @observable eventData;
  @observable eventPurchasers;
  @observable purchasers = [];
  @observable eventFeed;
  @observable currentPage = 1;
  @observable feedLoading = true;
  @observable loadingMore = false;
  @observable showCharityDetailById = 0;
  @observable currentEventReferrer;
  @observable followerMatchEntities = [];
  @observable matchSponsor;
  @observable matchAdmin = false;
  @observable fundraiserFeed = [];
  @observable hostUpdates = [];
  @observable loadingFundraiserFeed = false;
  @observable videoData = null;

  // For event host comments
  @observable commentText = '';
  @observable uploadingCommentImage = false;
  @observable commentImageData = {
    imageId: null,
    previewUrl: '',
  };
  @observable imageUploadError = false;
  @observable hostCommentCreateError = false;

  @action getEvent = async ({ purchaseId = null, id = null, userContext }) => {
    this.loading = true;
    this.followerMatchEntities = [];
    let variables = {};

    if (id) {
      variables.id = id;
    } else {
      variables.purchaseId = purchaseId;
    }

    if (userContext) variables.userContext = userContext;

    const options = {
      variables: variables,
      query: eventQueryApi2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      if (result.data.event === null) {
        navigate('/notfound');
      } else {
        this.eventData = result.data.event;

        if (this.eventData.matchSummaries) {
          result.data.event.matchSummaries
            .flatMap((matchSummary) => matchSummary)
            .forEach((matchSummary) => {
              if (matchSummary.matchType === 'FOLLOWER') {
                return matchSummary.matchSponsors
                  .flatMap((sponsor) => sponsor)
                  .forEach((sponsor) => {
                    if (!sponsor.isSelf && !sponsor.isSelfFollowing) {
                      this.followerMatchEntities.push({
                        entityType: sponsor.entityType,
                        id: sponsor.id,
                        name: sponsor.name,
                      });
                    }
                  });
              }
            });
        }

        // Randomize the charities being shown when there's a lot to impose some initial view fairness
        if (this.eventData.charities.length > 3) {
          shuffleArray(this.eventData.charities);
        }

        if (this.eventData.matchSummaries) {
          const matchSponsor = result.data.event.matchSummaries.find(
            (match) =>
              match.active &&
              match.matchSponsors.find((sponsor) => sponsor.isSelf),
          );
          const matchAdmin = result.data.event.matchSummaries.find(
            (match) => match.matchAdmin.isSelf,
          );

          if (matchSponsor || matchAdmin) {
            this.matchSponsor = matchSponsor || matchAdmin;
            this.matchAdmin = matchAdmin;
          }
        }

        this.loading = false;
      }

      if (
        uiStore.savedUiState?.checkoutReferrer?.linkId === id ||
        uiStore.savedUiState?.checkoutReferrer?.linkId === purchaseId
      ) {
        uiStore.saveUiState({
          checkoutReferrer: {
            ...uiStore.savedUiState.checkoutReferrer,
            eventId: result.data.event.id,
          },
        });
        if (uiStore.savedUiState.checkoutReferrer.referrerContext?.userId) {
          const referrerOptions = {
            variables: {
              id: uiStore.savedUiState.checkoutReferrer?.referrerContext
                ?.userId,
            },
            query: userByIdMinQuery,
            fetchPolicy: 'no-cache',
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          };
          apolloClient.query(referrerOptions).then((res) => {
            this.currentEventReferrer = res.data.data;
            this.currentEventReferrer.entityType = 'USER';
          });
          return;
        }
        if (uiStore.savedUiState.checkoutReferrer.referrerContext?.companyId) {
          const referrerOptions = {
            variables: {
              id: uiStore.savedUiState.checkoutReferrer?.referrerContext
                ?.companyId,
            },
            query: companyByIdQuery,
            fetchPolicy: 'no-cache',
            errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
          };
          apolloClient.query(referrerOptions).then((res) => {
            this.currentEventReferrer = res.data.data;
            this.currentEventReferrer.entityType = 'COMPANY';
          });
          return;
        }
      } else {
        this.currentEventReferrer = null;
      }
    } catch (err) {
      if (!global.IS_LOCAL_OR_DEV) {
        navigate('/notfound');
      }
      console.log(err);
    }
  };

  @action getEventPurchasers = async ({ id = null, purchaseId = null }) => {
    this.loadingPurchases = true;
    const isAdhoc = +id === -1; // -1 indicates ad hoc event without associated event table entry
    let variables = {};
    if (isAdhoc) {
      variables.purchaseId = +purchaseId;
    } else {
      variables.id = +id;
    }
    variables.page = 1;
    variables.pageSize = 250;

    const options = {
      variables: variables,
      query: isAdhoc ? eventPurchaserQuery : eventPurchasersQuery, // ad-hoc events only have a single purchase
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      this.eventPurchasers = isAdhoc
        ? [result.data.eventPurchaser]
        : result.data.eventPurchasers;

      if (this.eventPurchasers?.length >= 0) {
        this.purchasers = this.eventPurchasers.map((purchase) =>
          this.getJoinerEntityFromPurchase(purchase),
        );
      }
      this.loadingPurchases = false;
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log('Error querying purchases', err);
      }
    }
  };

  getJoinerEntityFromPurchase = (purchase) => {
    if (purchase.company) return { ...purchase, entityType: 'COMPANY' };
    else if (purchase.project) return { ...purchase, entityType: 'CHARITY' };
    else {
      return {
        ...purchase,
        entityType: 'USER',
        name: `${purchase.user.firstName} ${purchase.user.lastName}`,
      };
    }
  };

  @action setShowCharityDetailById = (id) => {
    this.showCharityDetailById = id;
  };

  @action getEventFeed = ({ id = null }) => {
    const options = {
      variables: {
        eventId: parseInt(id),
        limit: 20,
        offset: 0,
      },
      query: eventFeedQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    this.feedQuery = api2Client.watchQuery(options);

    const setData = action((result) => {
      this.eventFeed = result.data.cauzeFeed;
      this.feedLoading = false;
    });

    const setError = action((err) => {
      this.isError = true;
      this.error = err;
    });

    this.feedSubscription = this.feedQuery.subscribe({
      next(result, _more) {
        if (!result.loading) {
          setData(result);
        }
      },
      error(err) {
        setError(err);
      },
    });
  };

  @action getEventUpdates = async ({ id = null }) => {
    const options = {
      variables: {
        eventId: parseInt(id),
      },
      query: eventHostUpdatesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    this.hostUpdates = (await api2Client.query(options)).data.cauzeHostUpdates;
  };

  @action loadMore = async ({ purchaseId = null, id = null }) => {
    this.loadingMore = true;
    this.currentPage += 1;

    const variables = {
      eventId: parseInt(id),
      limit: 20,
      offset: 20 * (this.currentPage - 1),
    };

    mixpanel.track('Event Page Load More Feed Items', {
      eventId: id || purchaseId,
    });

    try {
      const options = {
        variables: variables,
        query: eventFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);

      this.eventFeed = this.eventFeed.concat(results.data.cauzeFeed);
    } catch (err) {
      this.isError = true;
    }

    this.loadingMore = false;
  };

  @action pinEvent = ({ eventId, pinned = true }) => {
    this.eventData.currentEntityHasPinned = pinned;

    mixpanel.track('Pin Event', { eventId });

    const variables = {
      eventId: parseInt(eventId),
      pinned: Boolean(pinned),
    };

    const options = {
      variables: variables,
      mutation: pinEventMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    api2Client.mutate(options).catch(() => {
      this.eventData.currentEntityHasPinned = pinned;
    });
  };

  @action followEvent = ({ follow = true, eventId, userContext }) => {
    this.eventData.currentEntityIsFollowing = follow;

    mixpanel.track('Follow Event', { eventId });

    const options = {
      variables: { eventId, userContext },
      mutation: follow ? followEventMutation : unfollowEventMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.eventData.currentEntityIsFollowing = follow;
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  follow = ({ follow = true, actorContext, targetContext }) => {
    this.setFollow({ targetContext, follow });

    if (follow) {
      trackFollow({ actorContext, targetContext });
    } else {
      trackUnfollow({ actorContext, targetContext });
    }

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      this.setFollow({ targetContext, follow: !follow });
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action like = ({ like = true, id, purchaseId, userContext }) => {
    const currentFeed = this.eventFeed;
    const feedItem = currentFeed.find(
      (feedItem) => feedItem?.activityPurchase?.id === id,
    );

    if (feedItem) {
      feedItem.currentEntityLiked = like;
      feedItem.likeCount = like
        ? feedItem.likeCount + 1
        : feedItem.likeCount - 1;
    }

    const updatedUserContext = { ...userContext };
    if (updatedUserContext.influencerId) {
      updatedUserContext.userId = updatedUserContext.influencerId;
      delete updatedUserContext.influencerId;
    }

    const options = {
      variables: { id: purchaseId, userContext: updatedUserContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    apolloClient.mutate(options).catch(() => {
      if (feedItem) {
        feedItem.currentEntityLiked = !like;
        feedItem.likeCount = like
          ? feedItem.likeCount - 1
          : feedItem.likeCount + 1;
      }
    });
  };

  @action setFollow = ({ targetContext, follow }) => {
    this.purchasers
      .filter((purchaser) =>
        matchEntityToContext({
          entity: purchaser,
          context: targetContext,
        }),
      )
      .map((entity) => (entity.isSelfFollowing = follow));
  };

  @action setCommentText = (text) => (this.commentText = text);

  @action uploadImage = async (imageFile) => {
    if (imageFile) {
      try {
        this.uploadingCommentImage = true;

        const token = await getToken();

        const url = UPLOAD_URL;
        const data = new FormData();
        data.append(CAUZE_IMAGE_FORM_KEY, imageFile);
        const res = await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.commentImageData = {
          imageId: res.data.id,
          previewUrl: res.data.urls.md,
        };

        // This is because the upload put is so fast the user can't see
        // the progress spinner, made them think it didn't work sometimes
        setTimeout(() => {
          this.uploadingCommentImage = false;
        }, 1000);

        return {
          imageId: res.data.id,
          previewUrl: res.data.urls.md,
        };
      } catch (err) {
        this.uploadingCommentImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action updateCommentImageData = ({ imageId, previewUrl }) => {
    this.commentImageData = {
      imageId: imageId,
      previewImageUrl: previewUrl,
    };
  };

  @action startVideoUpload = async ({ width, height, video }) => {
    const options = {
      variables: { width, height },
      mutation: createVideoMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.mutate(options);

      this.videoData = {
        width,
        height,
        video,
        id: result.data.createVideo.id,
      };
    } catch (err) {
      console.log('startVideoUploadError:', err);
      this.videoData = null;
    }
  };

  @action completeVideoUpload = async () => {
    const token = await getToken();
    const data = new FormData();
    data.append('file', this.videoData.video);

    try {
      await axios.post(
        `${UPLOAD_URLV2}/video/${this.videoData.id}/upload`,
        data,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        },
      );
    } catch (err) {
      // pass
    }
  };

  @action resetImage = () => {
    this.imageUploadError = false;
    this.commentImageData = {
      imageId: null,
      previewImageUrl: '',
    };
    this.videoData = null;
  };

  @action createComment = async ({
    body,
    imageId,
    userContext,
    purchaseId,
    eventId,
  }) => {
    const variables = {
      body,
      eventId: eventId || purchaseId,
      userContext,
      imageId,
    };

    const options = {
      variables,
      mutation: createCommentMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await apolloClient.mutate(options);
      this.clearCommentData();
      // this.refetch(); // This doesn't appear to do anything
      // this.getEventFeed({ purchaseId, id: eventId });
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
      this.hostCommentCreateError = true;
    }
  };

  @action postEventUpdate = async ({ eventId, body, imageId, previewUrl }) => {
    const variables = {
      eventId,
      body,
      imageId,
      videoId: this.videoData?.id ? parseInt(this.videoData.id) : undefined,
      previewUrl,
    };

    const options = {
      variables,
      mutation: createEventHostUpdateMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await this.completeVideoUpload();
      await api2Client.mutate(options);
      await this.getEventUpdates({ id: eventId });
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
    }
  };

  @action deleteEventUpdate = async ({ eventId, purchaseId }) => {
    const variables = {
      eventId,
      purchaseId,
    };

    const options = {
      variables,
      mutation: deleteEventHostUpdateMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      await api2Client.mutate(options);
      await this.getEventUpdates({ id: eventId });
    } catch (err) {
      global.IS_LOCAL_OR_DEV && console.log(err);
    }
  };

  @action clearCommentData = () => {
    this.commentText = '';
    this.uploadingCommentImage = false;
    this.commentImageData = {
      imageId: null,
      previewUrl: '',
    };
    this.imageUploadError = false;
    this.hostCommentCreateError = false;
    this.videoData = null;
  };

  @action refetch = () => {
    this.eventQuery.refetch();
    this.feedQuery.refetch();
  };

  @action onUnmount = () => {
    this.loading = true;
    this.eventData = {};
    this.purchasers = [];
    this.feedSubscription && this.feedSubscription.unsubscribe();
    this.feedQuery = null;
    this.currentPage = 1;
    this.uploadingCommentImage = false;
    this.commentImageData = {
      imageId: null,
      previewUrl: '',
    };
    this.imageUploadError = false;
    this.hostCommentCreateError = false;
    this.matchSponsor = null;
    this.fundraiserFeed = [];
    this.loadingFundraiserFeed = false;
    this.videoData = null;
  };

  /*

    Fundraising Feed

  */

  @action getEventApi2 = async ({ purchaseId = null, id = null }) => {
    this.loading = true;
    this.followerMatchEntities = [];
    let variables = {};

    if (id) {
      variables.eventId = id;
    } else {
      variables.purchaseId = purchaseId;
    }

    const options = {
      variables: variables,
      query: eventQueryApi2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      if (result.data.event === null) {
        navigate('/notfound');
      } else {
        this.eventData = result.data.event;

        if (this.eventData.matches) {
          result.data.event.matches
            .flatMap((matches) => matches)
            .forEach((matches) => {
              if (matches.matchType === 'FOLLOWER') {
                return matches.matchSponsors
                  .flatMap((sponsor) => sponsor)
                  .forEach((sponsor) => {
                    if (!sponsor.isSelf && !sponsor.currentEntityIsFollowing) {
                      this.followerMatchEntities.push({
                        entityType: sponsor.type,
                        id: sponsor.id,
                        name: sponsor.name,
                      });
                    }
                  });
              }
            });
        }

        // Randomize the charities being shown when there's a lot to impose some initial view fairness
        if (this.eventData.charities.length > 3) {
          shuffleArray(this.eventData.charities);
        }

        if (this.eventData.matches) {
          const matchSponsor = result.data.event.matches.find(
            (match) =>
              match.active &&
              match.matchSponsors.find((sponsor) => sponsor.isCurrentEntity),
          );
          const matchAdmin = result.data.event.matches.find(
            (match) => match.matchAdmin.isCurrentEntity,
          );

          if (matchSponsor || matchAdmin) {
            this.matchSponsor = matchSponsor || matchAdmin;
            this.matchAdmin = matchAdmin;
          }
        }

        this.loading = false;
      }
    } catch (err) {
      if (!global.IS_LOCAL_OR_DEV) {
        navigate('/notfound');
      }
    }
  };

  @action getFundraiserFeed = async ({ eventId }) => {
    if (!eventId) return;
    this.loadingFundraiserFeed = true;

    const options = {
      variables: { eventId },
      query: purchaseFeedQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      this.fundraiserFeed = result.data.purchaseFeed;
    } catch (err) {
      this.fundraiserFeed = [];
      global.IS_LOCAL_OR_DEV && console.log(err);
    }

    this.loadingFundraiserFeed = false;
  };

  @action onFundraiserFeedPurchaseLike = ({
    id,
    userContext,
    like = true,
    index,
  }) => {
    // Local optimistic mutation
    this.fundraiserFeed[index].cauzePurchase.currentEntityLiked = like;
    this.fundraiserFeed[index].purchaseLikeCount = like
      ? this.fundraiserFeed[index].purchaseLikeCount + 1
      : this.fundraiserFeed[index].purchaseLikeCount - 1;

    // Send update to server
    const options = {
      variables: { id, userContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      global.IS_LOCAL_OR_DEV && console.log(err);
      // Reserve local mutation
      this.fundraiserFeed[index].cauzePurchase.currentEntityLiked = !like;
      this.fundraiserFeed[index].purchaseLikes = like
        ? this.fundraiserFeed[index].purchaseLikes - 1
        : this.fundraiserFeed[index].purchaseLikes + 1;
    });
  };

  constructor() {
    makeObservable(this);
  }
}

const eventStore = new EventStore();
export default eventStore;
