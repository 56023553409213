import { useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';

import NewPaymentOptions from 'components/checkout/NewPaymentOptions';
import UserInfoInput from 'views/DonationCheckout/UserInfoInput';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import WrappedPaypalButton from 'components/WrappedPaypalButton';
import WrappedApplePayButton from 'components/WrappedApplePayButton';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import Currency from 'components/Currency/Currency';
import Section from './CheckoutSection';

import stripeOptions from 'config/stripe';
import { userCheckoutFormValidate as validate } from 'util/formFieldValidators';
import MultipleItemMessage from 'util/MultipleItemMessage';
import * as events from 'util/events/checkout';
import { navigate } from '@reach/router';

const PaymentSectionUser = ({
  isAuthenticated,
  userGiftStore,
  currentCheckout,
  minimumDonationAmount,
  stripe,
  elements,
  activeEntity,
}) => {
  const pendingInvites = userGiftStore.pendingInvites;

  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    card: true,
    isValid: true,
  });

  const [showUserInputError, setShowUserInputError] = useState(false);
  const [userInputErrorMessage, setUserInputErrorMessage] = useState('');

  const associatedEmailError = () => {
    if (
      userGiftStore?.pendingInvites &&
      userGiftStore?.pendingInvites?.userIsUnclaimed
    ) {
      const giftText = pendingInvites?.hasGiftToken ? ' a gift' : '';
      const groupInviteText = pendingInvites?.hasGroupInvite
        ? ' a group invite'
        : '';
      const matchText = pendingInvites?.matchText ? ' matches' : '';
      const errorMessage = (
        <MultipleItemMessage
          messageStart="The email address you provided has"
          messageItems={[giftText, groupInviteText, matchText]}
          messageEnd=" associated. Create an account to get the full benefit."
        />
      );
      return errorMessage;
    }

    if (!userGiftStore.pendingInvites.userIsUnclaimed) {
      return (
        <div>
          {`The email address you entered is associated with an exisiting account. If you `}
          <span
            className="login-text"
            onClick={() => {
              navigate('/login');
            }}
          >
            login
          </span>
          , your donation will be matched.;
        </div>
      );
    }
  };

  const unauthedPaypalValidate = async () => {
    const { charityId, charityIds } = userGiftStore.currentCheckout;

    let validationResult = validate({
      firstName: 'Gift',
      lastName: 'Recipient',
      email: 'cauzerecipient@cauze.com',
      charityId,
      charityIds,
      onNoCharitySelected: userGiftStore.onNoCharitySelected,
    });

    setValidation(validationResult);
    if (validationResult.isValid) {
      events.checkoutWithPaypal();
      return true;
    } else {
      return false;
    }
  };

  const isBalance =
    `${currentCheckout.paymentType}`.toUpperCase() === 'BALANCE';

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (currentCheckout.donationAmount === 0) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
    }

    const underMinimumAmount =
      minimumDonationAmount &&
      currentCheckout.donationAmount < minimumDonationAmount;
    if (underMinimumAmount) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
      return;
    }

    if (isBalance) {
      userGiftStore.startCheckout();
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const { firstName, lastName, email, charityId, charityIds } =
      userGiftStore.currentCheckout;

    const validationResult = validate({
      firstName,
      lastName,
      email,
      charityId,
      charityIds,
      onNoCharitySelected: userGiftStore.onNoCharitySelected,
    });

    if (isAuthenticated) {
      userGiftStore.startCheckout();
    } else {
      if (validationResult.isValid) {
        userGiftStore.updateCheckout({ paymentType: 'stripe_cc' });
        userGiftStore.startCheckout();
      } else {
        if (
          !validationResult.firstName ||
          !validationResult.lastName ||
          !validationResult.email
        ) {
          processUserInputErrors(validationResult);
          document
            .getElementById('userInput')
            .scrollIntoView({ block: 'center' });
        }
        events.checkoutValidationError();
        setValidation(validationResult);
      }
    }

    if (currentCheckout.donationAmount === 0) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
    }
  };

  const processUserInputErrors = (validationResult) => {
    const firstName = !validationResult.firstName ? ' first name' : '';
    const lastName = !validationResult.lastName ? ' last name' : '';
    const email = !validationResult.email ? ' email address' : '';

    setUserInputErrorMessage(
      <MultipleItemMessage
        messageStart="Please provide a valid"
        messageItems={[firstName, lastName, email]}
      />,
    );

    setShowUserInputError(true);
  };

  const donateButtonText = (
    <>
      Give <Currency amount={currentCheckout.donationAmount} showCents />
    </>
  );

  return (
    <Section title="2. Payment">
      <div className="pt-5">
        {!isAuthenticated && (
          <UserInfoInput
            donationCheckoutStore={userGiftStore}
            setValidation={setValidation}
            validation={validation}
            setErrorMessage={setShowUserInputError}
          />
        )}

        {userGiftStore.showPendingInvitesMessage && (
          <ErrorCard
            message={associatedEmailError()}
            className="associated-email"
            support={true}
          />
        )}

        {showUserInputError && (
          <ErrorCard
            message={userInputErrorMessage}
            className="info-input-error"
          />
        )}

        {isAuthenticated && (
          <NewPaymentOptions
            isUser
            activeEntity={activeEntity}
            excludedPaymentTypes={[
              currentCheckout.balance <= 0 && !activeEntity.allowNegative
                ? 'BALANCE'
                : '',
            ]}
            store={userGiftStore}
            allowNegativeBalance={activeEntity.allowNegative}
            onDonate={handleSubmit}
            donateText={donateButtonText}
            showCardInputError={userGiftStore.cardInputError}
            dismissCardInputError={userGiftStore.dismissCardInputError}
          />
        )}

        {!isAuthenticated && (
          <div className="flex-column flex-align-center unauthed-payment pt-2">
            <h3>Digital Wallet</h3>
            <WrappedApplePayButton store={userGiftStore} />
            <WrappedPaypalButton
              {...userGiftStore.getPaypalVars()}
              validateFunc={unauthedPaypalValidate}
            />
            <div className="my-4" />
            <h3>Card Payment</h3>
            <div
              className="stripe-element-wrapper"
              id="stripeCard"
              onClick={userGiftStore.dismissCardInputError}
              onFocus={userGiftStore.dismissCardInputError}
            >
              <PaymentElement
                options={stripeOptions.cardElement}
                onClick={userGiftStore.dismissCardInputError}
                onFocus={userGiftStore.dismissCardInputError}
              />
            </div>
            {userGiftStore.cardInputError && (
              <ErrorCard
                message="Oops. The card information you entered is incorrect. Please check,
        change the highlighted areas and you’ll be all set!"
                className="card-error"
              />
            )}
            <CauzeButton onClick={handleSubmit} large className="donate mt-8">
              {donateButtonText}
            </CauzeButton>
          </div>
        )}
      </div>
    </Section>
  );
};

export default PaymentSectionUser;
