import ReactPlayer from 'react-player';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { clampDimensions } from 'util/image';
import { ReactComponent as PlayIcon } from 'assets/images/icons/play/play.svg';
import { ReactComponent as PauseIcon } from 'assets/images/icons/play/pause.svg';
import { ReactComponent as MuteIcon } from 'assets/images/icons/speaker/mute-icon.svg';
import { ReactComponent as SpeakerIcon } from 'assets/images/icons/speaker/speaker-icon.svg';
import useInViewport from 'hooks/useInViewport';
import useMobile from 'hooks/useMobile';

const CauzeVideoPlayPause = ({ isPlaying, setIsPlaying }) => {
  const handlePress = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isPlaying]);

  return (
    <button
      className="bg-transparent border-none cursor-pointer my-auto"
      onClick={handlePress}
    >
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </button>
  );
};

const CauzeVideoProgress = ({ progress }) => {
  return (
    <div className="flex-1 my-auto h-[7px] rounded-md overflow-hidden relative">
      <div className="bg-white bg-opacity-30 w-full h-full absolute" />
      <div
        className="bg-white h-full rounded-md"
        style={{
          width: `${Math.ceil(progress * 100)}%`,
        }}
      ></div>
    </div>
  );
};

const CauzeVideoVolume = ({ isMuted, setIsMuted }) => {
  const handlePress = useCallback(() => {
    if (isMuted) {
      setIsMuted(false);
    } else {
      setIsMuted(true);
    }
  }, [isMuted]);

  return (
    <button
      className="bg-transparent border-none cursor-pointer my-auto"
      onClick={handlePress}
    >
      {isMuted ? <MuteIcon /> : <SpeakerIcon />}
    </button>
  );
};

const CauzeVideo = ({
  uiStore,
  children,
  video,
  videoName,
  maxWidth = 450,
  maxHeight = 600,
  defaultMuted = true,
  isModal = false,
  rounded = true,
}) => {
  const { ref, isInViewport } = useInViewport({ threshold: 1 });
  const { isMobile } = useMobile();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(defaultMuted);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const { height, width } = clampDimensions(
    (video?.height || 0) / (video?.width || 1),
    maxHeight,
    maxWidth,
  );

  useEffect(() => {
    if (!isInViewport) {
      setIsPlaying(false);
    } else if (isInViewport) {
      setIsPlaying(true);
    }
  }, [isInViewport]);

  if (!video) return <></>;

  return (
    <div ref={ref} className="cauze-video relative cursor-pointer">
      {children}
      <ReactPlayer
        muted={isMuted}
        playing={isPlaying}
        width={`${width}px`}
        height={`${height}px`}
        controls={false}
        url={video.videoUrl}
        playsinline
        onDuration={(_duration) => {
          setDuration(_duration);
        }}
        onProgress={({ playedSeconds }) => {
          setProgress(playedSeconds);
        }}
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
      <div className="controls h-full w-full absolute bottom-0 right-0"></div>
      <div
        style={{ width: `${width}px` }}
        className="absolute bottom-3 left-0 right-0 flex flex-col h-full max-w-full px-0 lg:px-3"
      >
        <div
          className="flex-1"
          onClick={() => {
            if (isMobile && !isModal) {
              uiStore.openModal('VIDEO_MODAL', { video, title: videoName });
            } else {
              setIsPlaying(!isPlaying);
            }
          }}
        ></div>
        <div className="flex max-w-full" style={{ width: `${width}px` }}>
          <CauzeVideoPlayPause
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
          <CauzeVideoProgress progress={progress / (duration || 1)} />
          <CauzeVideoVolume isMuted={isMuted} setIsMuted={setIsMuted} />
        </div>
      </div>
    </div>
  );
};

export default inject('uiStore')(
  observer((props) => <CauzeVideo {...props} />),
);
