import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';

class WrappedPaypalButton extends React.Component {
  constructor(props) {
    super(props);

    window.React = React;
    window.ReactDOM = ReactDOM;

    this.state = {
      showButton: false,
    };
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }

  render() {
    const {
      commit,
      onSuccess,
      onError,
      onCancel,
      validateFunc,
      onOrderCreate,
    } = this.props;

    if (this.state.showButton) {
      const paypal = window.paypal;
      let PayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });

      // This would be for syncronously disabling the button, maybe adding an event listener
      // to update the button state with actions.enable(), but more work than it's worth right
      // now I've already spent too long on this
      // const onInit = (data, actions) => {
      //   // actions.disable();
      // };

      // This asyncronous validation will cause the modal to briefly pop up then disappear,
      // which is not ideal visually but does work pretty well otherwise
      const onClick = async (data, actions) =>
        validateFunc().then((result) => {
          if (result) {
            return actions.resolve();
          } else {
            return actions.reject();
          }
        });

      const onCreateOrder = async (data, actions) => {
        const order = await onOrderCreate();
        return actions.order.create(order);
      };

      const onApprove = (data, actions) =>
        actions.order.capture().then(function (details) {
          onSuccess(details);
        });

      return (
        <div className="paypal-button paypal-button-container">
          <PayPalButton
            commit={commit}
            // onInit={onInit}
            onClick={onClick}
            createOrder={onCreateOrder}
            onApprove={onApprove}
            onCancel={onCancel}
            onError={onError}
            style={{
              shape: 'rect',
              label: 'paypal',
              layout:
                document.body.clientWidth >= 375 ? 'horizontal' : 'vertical',
              height: 40,
              tagline: false,
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default scriptLoader(
  `https://www.paypal.com/sdk/js?client-id=${global.PAYPAL_KEY}&disable-funding=credit,card`,
)(WrappedPaypalButton);
