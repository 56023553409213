import WrappedPaypalButton from './WrappedPaypalButton';
import WrappedPaypalButtonDesktop from './WrappedPaypalButtonDesktop';

export default (props) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    return <WrappedPaypalButton {...props} />;
  } else {
    return <WrappedPaypalButtonDesktop {...props} />;
  }
};
