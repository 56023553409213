import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import useCharityAdmin from 'hooks/useCharityAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Button from 'components/Button/Button';

import { useCauzeRoutingContext } from 'hooks/useCauzeRoutingContext';
import charityPlaceholderImage from 'assets/images/icons/placeholder/placeholder-charity@3x.png';
import useDashboardStore from 'stores/DashboardStore';
import classnames from 'classnames';
import { navigate } from '@reach/router';
import ImageUploadModal from 'components/modals/ImageUploadModal/ImageUploadModal';

const InputContainer = ({ title, error, children, style }) => (
  <div
    style={style}
    className={classnames('input-container flex-column', {
      'input-container-error': error,
    })}
  >
    <p className={classnames('input-title flex-column')}>{title}</p>
    {children}
    <p className="input-container-error-message">{error}</p>
  </div>
);

const handleCharityLogoChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCharityLogoFile(file);
    store.setCharityLogoUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const handleCharityHeroChange = (e, store) => {
  e.preventDefault();

  let reader = new FileReader();
  let file = e.target.files[0];

  reader.onloadend = () => {
    store.setCharityHeroFile(file);
    store.setCharityHeroUrl(reader.result);
  };

  reader.readAsDataURL(file);
};

const CharityProfileView = ({ charityAdminStore, charityId, uiStore }) => {
  useCharityAdmin(charityId);
  const { setProps } = useDashboardStore();
  const { navigateWithConfirm } = useCauzeRoutingContext();

  const [profileImageHasChanged, setProfileImageHasChanged] = useState(false);
  const [coverImageHasChanged, setCoverImageHasChanged] = useState(false);
  const [missionStatementHasChanged, setMissionStatementHasChanged] =
    useState(false);
  const [socialLinksHasChanged, setSocialLinksHasChanged] = useState(false);
  const [donationThankYouTextHasChanged, setDonationThankYouTextHasChanged] =
    useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [upImg, setUpImg] = useState(null);

  let charityLogoPreview = null;
  if (charityAdminStore.charityLogoUrl) {
    charityLogoPreview = (
      <img
        className="profile-image-logo"
        src={charityAdminStore.charityLogoUrl}
      />
    );
  } else {
    charityLogoPreview = (
      <img className="profile-image-logo" src={charityPlaceholderImage} />
    );
  }

  let charityHeroPreviewUrl = null;
  if (charityAdminStore.charityLogoUrl) {
    charityHeroPreviewUrl = charityAdminStore.charityHeroUrl;
  } else {
    charityHeroPreviewUrl = charityPlaceholderImage;
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 3000000) {
        setShowFileError(
          'File is too large. Please select a file less than 3MB.',
        );
        return;
      }
      setIsChanged(true);
      setShowImageUploadModal(true);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let success = false;

      // Call each save function only if the related state is true
      if (profileImageHasChanged) {
        await charityAdminStore.submitCharityLogo();
      }

      if (coverImageHasChanged) {
        await charityAdminStore.submitCharityHero();
      }

      if (missionStatementHasChanged) {
        await charityAdminStore.updateMissionText();
      }

      if (socialLinksHasChanged) {
        await charityAdminStore.updateSocialAccounts();
      }

      if (donationThankYouTextHasChanged) {
        await charityAdminStore.updateSayThanksText();
      }

      // Check if any update was successful
      success = true;

      if (success) {
        uiStore.showNotification({
          body: 'Profile Updated',
          type: 'SUCCESS',
        });
        navigate(`/charity/${charityId}`);
      } else {
        uiStore.showNotification({
          body: 'No changes were made to update.',
          type: 'ERROR',
        });
      }
    } catch (error) {
      uiStore.showNotification({
        body: 'There was an issue updating your profile. Support has been notified',
        type: 'ERROR',
      });
    }
  };

  const onFileUpload = (imageFile) => {
    charityAdminStore.setCharityHeroFile(imageFile);
  };

  useEffect(() => {
    setProps({
      size: 'half',
      title: 'Your Profile',
      actions: (
        <a
          className="profile-link font-agenda-bold"
          onClick={() => navigateWithConfirm(`/charity/${charityId}`)}
        >
          View Profile Page
        </a>
      ),
    });
  }, []);

  return (
    <div className="charity-admin user-details charity-profile flex-column flex-expand">
      <section className="page-body user-profile-form">
        <ImageUploadModal
          store={charityAdminStore}
          cropRatio={[544, 181]}
          isActive={showImageUploadModal}
          onToggleClose={() => setShowImageUploadModal(false)}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
          upImg={upImg}
          setUpImg={setUpImg}
          showFileError={showFileError}
          setShowFileError={setShowFileError}
          onFileUpload={onFileUpload}
          updateImageUrl={(imageUrl) => {
            charityAdminStore.setCharityHeroUrl(imageUrl);
            setCoverImageHasChanged(true);
          }}
        />
        <div>
          <p className="section-description" style={{ maxWidth: '45rem' }}>
            With every registered 501(c)3 in Cauze, it’s important to update
            your profile so givers know exactly why they should support your
            work! Start by adding a logo and mission statement.
          </p>
          <InputContainer title="Profile Image">
            <div className="image-input">
              {charityLogoPreview}
              <label className="image-upload-label" htmlFor="logo-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="logo-upload"
                onChange={(e) => {
                  handleCharityLogoChange(e, charityAdminStore);
                  setProfileImageHasChanged(true);
                }}
              />
            </div>
          </InputContainer>

          <InputContainer title="Cover Image" style={{ gridColumn: 'span 2' }}>
            <div className="image-input">
              <div className="profile-hero-image-container">
                <div className="profile-hero-image-overlay" />

                <div
                  className="profile-hero-image"
                  style={{
                    backgroundImage: `url('${charityHeroPreviewUrl}')`,
                  }}
                />
              </div>
              <label className="image-upload-label" htmlFor="hero-upload">
                Select File
              </label>
              <input
                hidden
                type="file"
                id="hero-upload"
                onChange={onSelectFile}
              />
            </div>
          </InputContainer>

          {/* Mission Statement */}
          <InputContainer
            title="Mission Statement"
            style={{ gridColumn: 'span 2' }}
          >
            <p className="section-description">
              We encourage you to include information on impact (“$10 provides 1
              free meal”) or highlight an area of need (“we’re fundraising to
              fix the van!”). These calls to action mobilize givers and help
              push them to get their communities involved!
            </p>
            <textarea
              name="mission_statement"
              onChange={(ev) => {
                charityAdminStore.setMissionText(ev.target.value);
                setMissionStatementHasChanged(true);
              }}
              placeholder="Describe your nonprofit's mission"
              value={charityAdminStore.missionText}
              rows={8}
            />
          </InputContainer>

          {/* Website & Social Links */}

          <InputContainer
            title="Website (optional)"
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="webUrl"
              value={charityAdminStore.socialAccounts.webUrl}
              placeholder={`Website`}
              onChange={(ev) => {
                charityAdminStore.setSocialAccounts({
                  webUrl: ev.target.value,
                });
                setSocialLinksHasChanged(true);
              }}
            />
          </InputContainer>
          <InputContainer
            title="Facebook (optional)"
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="facebook"
              onChange={(ev) => {
                charityAdminStore.setSocialAccounts({
                  facebookName: ev.target.value,
                });
                setSocialLinksHasChanged(true);
              }}
              style={
                charityAdminStore.socialAccountsValid.facebookName === false
                  ? { borderColor: '#e60054' }
                  : {}
              }
              value={charityAdminStore.socialAccounts.facebookName}
              placeholder={`Facebook`}
            />
          </InputContainer>
          <InputContainer
            title="Instagram (optional)"
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="instagram"
              style={
                charityAdminStore.socialAccountsValid.instagramName === false
                  ? { borderColor: '#e60054' }
                  : {}
              }
              onChange={(ev) => {
                charityAdminStore.setSocialAccounts({
                  instagramName: ev.target.value,
                });
                setSocialLinksHasChanged(true);
              }}
              value={charityAdminStore.socialAccounts.instagramName}
              placeholder={`Instagram`}
            />
          </InputContainer>
          <InputContainer
            title="Twitter/X (optional)"
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="x"
              onChange={(ev) => {
                charityAdminStore.setSocialAccounts({
                  twitterName: ev.target.value,
                });
                setSocialLinksHasChanged(true);
              }}
              style={
                charityAdminStore.socialAccountsValid.twitterName === false
                  ? { borderColor: '#e60054' }
                  : {}
              }
              value={charityAdminStore.socialAccounts.twitterName}
              placeholder={`Twitter`}
            />
          </InputContainer>
          <InputContainer
            title="YouTube (optional)"
            style={{ gridColumn: 'span 2' }}
          >
            <input
              name="youtube"
              onChange={(ev) => {
                charityAdminStore.setSocialAccounts({
                  youtubeUrl: ev.target.value,
                });
                setSocialLinksHasChanged(true);
              }}
              style={
                charityAdminStore.socialAccountsValid.youtubeUrl === false
                  ? { borderColor: '#e60054' }
                  : {}
              }
              value={charityAdminStore.socialAccounts.youtubeUrl}
              placeholder={`YouTube`}
            />
          </InputContainer>
          {/* Donation */}

          <InputContainer title="Donation" style={{ gridColumn: 'span 2' }}>
            <div className="input-title !text-sm">
              Custom Thank You - Post Donation
            </div>
            <p className="section-description">
              Givers that give to your nonprofit will see this custom message
              from you after they complete their donation. Seeing a thank you
              message helps our givers feel the immediate impact of their
              donation, gets them excited to share with their friends, and they
              look forward to giving again.
            </p>
            <textarea
              name="donation_custom_thanks"
              value={charityAdminStore.sayThanksText || ''}
              placeholder="This will show to Cauze users when they give to you"
              onChange={(ev) => {
                charityAdminStore.setSayThanksText(ev.target.value);
                setDonationThankYouTextHasChanged(true);
              }}
              rows={8}
            />
          </InputContainer>
          <Button
            className="save-button is-medium max-xl:w-full"
            type="button"
            outline
            isSubmitting={
              charityAdminStore.isUploadingCharityLogo ||
              charityAdminStore.isUploadingCharityHero ||
              charityAdminStore.updatingMissionText ||
              charityAdminStore.updatingSocialAccounts ||
              charityAdminStore.updatingSayThanksText
            }
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </div>
      </section>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject('charityAdminStore', 'uiStore')(observer(CharityProfileView)),
  ),
  { entityType: 'CHARITY' },
);
