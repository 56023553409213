import ReactPlayer from 'react-player';

import LinkPreview from 'components/LinkPreview/LinkPreview';
import CauzeVideo from 'components/CauzeVideo/CauzeVideo';

const FeedCardPreview = ({ previewUrl, video, videoName }) => {
  const isVideo =
    video || previewUrl?.includes('youtu') || previewUrl?.includes('vimeo');

  if (video) {
    return (
      <div className="flex flex-1 justify-center mb-3">
        <CauzeVideo video={video} videoName={videoName} maxWidth={240} />
      </div>
    );
  }

  if (isVideo) {
    return (
      <div className="video-container">
        <div className="event-video">
          <ReactPlayer
            width="100%"
            height="100%"
            controls
            playsinline
            url={video?.videoUrl || previewUrl}
          />
        </div>
      </div>
    );
  }

  return <LinkPreview uri={previewUrl} />;
};

export default FeedCardPreview;
