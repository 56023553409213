import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';
import ProfileAvatar from 'components/profiles/ProfileAvatar';
import { observer, inject } from 'mobx-react';
import { normalizeContext } from 'util/contextUtils';
import withUserContext from 'behaviors/withUserContext';
import { getEntityUrl } from 'util/navHelpers.js';
import { ReactComponent as CrossIcon } from 'assets/images/icons/svgs/profile-modal-cross.svg';
import dompurify from 'dompurify';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import { navigate } from '@reach/router';

const ProfileHeaderBio = ({ bio }) => {
  const bioMaxCount = 200;
  const [hideMore, setHideMore] = useState(bio.length > bioMaxCount);
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    function handleClick(e) {
      if (
        e.target.id === 'profile-read-more' ||
        e.target.id === 'profile-read-less'
      ) {
        setHideMore((prev) => !prev);
      }
    }

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const truncatedBio = hideMore ? bio.slice(0, bioMaxCount) : bio;
  const showToggleButton = bio.length > bioMaxCount;

  return (
    <span
      className={cx('bio-text', {
        'hide-more': hideMore && showToggleButton,
      })}
      dangerouslySetInnerHTML={{
        __html: sanitizer(
          `${truncatedBio}${
            showToggleButton
              ? hideMore
                ? `...<span>
                      <span id="profile-read-more" class="read-more-button font-agenda-bold">
                        View More
                      </span>
                    </span>`
                : `<span>
                      <span id="profile-read-less" class="read-more-button font-agenda-bold">
                        View Less
                      </span>
                    </span>`
              : ''
          }`.replaceAll('\r\n', '<br />'),
          {
            ADD_ATTR: ['target'],
          },
        ),
      }}
    ></span>
  );
};

const BioIdentity = ({ identity, href, showDivider }) => {
  if (!identity) return <></>;

  let content = identity;

  if (href) {
    content = <a href={href}>{identity}</a>;
  }

  return (
    <div style={{ display: 'block' }}>
      {content}
      {showDivider && <br />}
    </div>
  );
};
const ProfileModal = ({
  isOpen,
  onToggleClose,
  charityStore,
  profileStore,
  userProfileStore,
  id,
  entityType,
  uiStore,
  authStore,
  companyStore,
  userContext,
}) => {
  const isAuthenticated = authStore.isAuthenticated;

  const [localFollowerCount, setLocalFollowerCount] = useState(0);
  const isCompany = entityType === 'COMPANY';
  const isCharity = entityType === 'NONPROFIT' || entityType === 'CHARITY';
  const isInfluencer = entityType === 'INFLUENCER' || entityType === 'USER';
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id) return;
        setLoading(true);
        if (isInfluencer) {
          await userProfileStore.getUser({ id, silent: true });
        } else if (isCompany) {
          await companyStore.getCompany({
            id: +id,
            userContext: normalizeContext(userContext),
            silent: true,
          });
        } else if (isCharity) {
          await charityStore.getCharity({
            id,
            userContext: normalizeContext(userContext),
            silent: true,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, entityType]);

  const profileData = isCompany
    ? companyStore.companies.get(+id)
    : isCharity
    ? charityStore.charities.get(+id)
    : userProfileStore.users.get(+id) || {};

  console.log('profile', profileData);
  const innerProfileData = isCharity ? profileData : profileData?.profileData;
  const name =
    isCompany || isCharity
      ? profileData?.name
      : `${profileData?.firstName} ${profileData?.lastName}`;
  const bio = isCompany ? profileData?.description : profileData?.bio;
  const avatar =
    isCompany || isCharity ? profileData?.avatar : profileData?.avatarUrls;
  const hero =
    isCompany || isCharity ? profileData?.hero : profileData?.heroUrls;
  const hasHero = Boolean(hero && hero?.full);

  const followerCount =
    isCompany || isCharity
      ? profileData?.followerCount
      : innerProfileData?.followerCount;
  const followingCount =
    isCompany || isCharity
      ? profileData?.followingCount
      : innerProfileData?.followingCount;

  useEffect(() => {
    setLocalFollowerCount(followerCount || 0);
  }, [followerCount]);

  // Close modal on 'Escape' key press
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  const webUrl = profileData?.webUrl;
  const foundation = profileData?.foundation || profileData?.foundationName;
  const profileIdentities = useMemo(() => {
    const { ein, city, state } = innerProfileData || {};

    const propsList = [
      foundation && {
        identity: foundation,
      },
      Boolean(city && state && isCharity) && {
        identity: `${city}, ${state} \n`,
      },
      Boolean(ein && isCharity) && {
        identity: `EIN: ${ein}`,
      },
      Boolean(webUrl) && {
        identity: webUrl,
        href: webUrl,
      },
    ].filter((p) => p);

    const components = [];

    for (let i = 0; i < propsList.length; i++) {
      const props = propsList[i];
      components.push(
        <BioIdentity {...props} showDivider={i < propsList.length - 1} />,
      );
    }

    return components;
  }, [foundation, innerProfileData, isCharity, webUrl]);

  const onFollow = () => {
    if (isInfluencer) {
      userProfileStore.follow({
        follow: !profileData.isSelfFollowing,
        actorContext: normalizeContext(profileStore.activeEntity.userContext),
        userId: id,
      });
    } else if (isCompany) {
      companyStore.follow({
        follow: !profileData.isSelfFollowing,
        actorContext: normalizeContext(userContext),
        companyId: id,
      });
    } else if (isCharity) {
      charityStore.follow({
        follow: !profileData.isSelfFollowing,
        actorContext: normalizeContext(userContext),
        charityId: id,
      });
    }

    setLocalFollowerCount((prev) =>
      profileData.isSelfFollowing ? prev + 1 : prev - 1,
    );
  };

  const hasTotalGood =
    !!innerProfileData?.userGood?.alltimeGoodSum && isAuthenticated;
  const hasInfluenced = !!innerProfileData?.userGood?.alltimeInfluencedCount;
  const statCount = 2 + (hasTotalGood ? 1 : 0) + (hasInfluenced ? 1 : 0);
  const textSizeClass = statCount === 4 ? 'text-[20px]' : 'text-[24px]';

  return (
    <>
      <div className={cx('modal profile-modal', { 'is-active': isOpen })}>
        <div
          className="modal-background"
          onClick={() => onToggleClose(false)}
        />
        <div className="modal-card">
          <section className="modal-card-body-profile-modal">
            {loading ? (
              <div className="max-sm:h-[350px] h-[425px] flex items-center justify-center ">
                <CauzeSpinner
                  style={{
                    margin: '0',
                  }}
                />
              </div>
            ) : (
              <>
                <button
                  className="close-icon"
                  onClick={() => onToggleClose(false)}
                  style={{
                    position: 'absolute',
                    top: '20px',
                    right: '12px',
                    zIndex: 3,
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                  }}
                >
                  <CrossIcon />
                </button>
                <div className="body-container">
                  <div className="profiles-image-container">
                    <div className="profile-header-hero">
                      {hasHero && (
                        <div className="profile-header-hero-overlay" />
                      )}
                      <div
                        className={cx(
                          `hero ${
                            !hasHero &&
                            `background-gradient-${
                              1 + ((name?.length || 0) % 6)
                            }`
                          }`,
                          { 'empty-hero': !hasHero },
                        )}
                        style={
                          hasHero
                            ? {
                                backgroundImage: `url(${hero.full})`,
                              }
                            : undefined
                        }
                      />
                      {/* Moved Avatar inside profile-header-hero */}
                      <div className="avatar-container">
                        <div className="w-[90px] h-[90px] ">
                          <ProfileAvatar
                            avatar={avatar}
                            entityType={entityType}
                            isVerified={false}
                            hasRecurringDeposit={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[22px] px-5">
                    {/* User Info */}
                    <div className="user-info mb-3">
                      <div
                        key={id}
                        className={cx(
                          ' mb-0.5 text-[20px] leading-[26px] text-black',
                          {
                            'font-agenda': isCharity,
                            'font-agenda-bold': !isCharity,
                          },
                        )}
                      >
                        {name}
                      </div>
                      <div className="mb-0.5 font-agenda text-[13px] leading-[17px] text-[#1B2031]">
                        {isInfluencer
                          ? `@${profileData?.username}`
                          : profileIdentities}
                      </div>
                      <div className="font-agenda  overflow-y-auto max-h-[280px] text-[13px] leading-4  text-[#1B2031]">
                        {bio ? (
                          <ProfileHeaderBio bio={bio} />
                        ) : (
                          <span>{name} has not yet set their bio.</span>
                        )}
                      </div>
                    </div>

                    <div className="user-stats uppercase">
                      <div className="stat">
                        <span
                          className={`font-agenda-bold leading-6 ${textSizeClass} text-[#010101]`}
                        >
                          {followingCount}
                        </span>
                        <span className="font-agenda text-[10px] leading-[14px]">
                          Following
                        </span>
                      </div>
                      <div className="stat">
                        <span
                          className={`font-agenda-bold leading-6 ${textSizeClass} text-[#010101]`}
                        >
                          {localFollowerCount}
                        </span>
                        <span className="font-agenda text-[10px] leading-[14px] text-[#1B2031]">
                          Followers
                        </span>
                      </div>
                      {hasTotalGood && (
                        <div className="stat">
                          <span
                            className={`font-agenda-bold leading-6 ${textSizeClass} text-[#010101]`}
                          >
                            <LargeNumber
                              isCurrency
                              amount={
                                parseInt(
                                  innerProfileData.userGood.alltimeGoodSum || 0,
                                ) / 100
                              }
                            />
                          </span>
                          <span className="font-agenda text-[10px] leading-[14px] text-[#1B2031]">
                            Total Good
                          </span>
                        </div>
                      )}
                      {hasInfluenced && (
                        <div className="stat">
                          <span
                            className={`font-agenda-bold leading-6 ${textSizeClass} text-[#010101]`}
                          >
                            {innerProfileData.userGood.alltimeInfluencedCount}
                          </span>
                          <span className="font-agenda text-[10px] leading-[14px] text-[#1B2031]">
                            influenced
                          </span>
                        </div>
                      )}
                    </div>

                    {/* Action Buttons */}
                    <div className="action-buttons uppercase ">
                      <button
                        onClick={(e) => {
                          if (isAuthenticated) {
                            onFollow(e);
                          } else {
                            uiStore.openModal('CONFIRM', {
                              message: `Please Login or Signup to follow ${
                                name || 'this user'
                              }.`,
                              action: () => {
                                navigate('/login');
                              },
                              confirmText: 'Login/Signup',
                              cancelText: 'Not Now',
                              bodyMessage:
                                'Create your own Cauze account and join our community of givers.',
                            });
                          }
                        }}
                        className={cx(
                          {
                            '!text-[rgba(27,32,49,0.74)]':
                              profileData?.isSelfFollowing,
                            '!text-black': !profileData?.isSelfFollowing,
                          },
                          'follow-btn font-agenda-bold uppercase',
                        )}
                      >
                        <span>
                          {profileData?.isSelfFollowing ? 'Unfollow' : 'Follow'}
                        </span>
                      </button>
                      <a
                        href={getEntityUrl({
                          id,
                          entityType,
                        })}
                        className="profile-btn !text-black font-agenda-bold uppercase hover:underline"
                      >
                        <span>View Profile</span>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'userProfileStore',
    'uiStore',
    'companyStore',
    'charityStore',
  )(observer(ProfileModal)),
);
