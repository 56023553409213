import cx from 'classnames';

import { ReactComponent as GiveIcon } from 'assets/images/icons/svgs/give-bold.svg';
import { ReactComponent as HeartOutline } from 'assets/images/icons/heart/heart-outline.svg';
import { ReactComponent as HeartFilled } from 'assets/images/icons/heart/heart-red.svg';
import { ReactComponent as CommentIcon } from 'assets/images/icons/svgs/comment.svg';
import { ReactComponent as ShareIcon } from 'assets/images/icons/share/share-grey-thick.svg';

import FeedItemAction from 'components/FeedItem/FeedItemAction';
import Currency from 'components/Currency/Currency';
import useMobile from 'hooks/useMobile';

const FeedItemActions = ({
  hasComment,
  showEvent,
  hasMatch,
  isImageExistWithCauze,
  onJoinClick,
  isJoinedActivity,
  item,
  commentCount,
  onCommentClick,
  currentEntityCommented,
  authStore,
  onLike,
  onShareClick,
  likeCount,
  currentEntityLiked,
}) => {
  const { isTablet } = useMobile();

  return (
    <div className="feed-item-actions pr-0 xl:pr-[20px] text-gray-727">
      {isTablet && (
        <div
          className={cx('feed-item-actions-lines', {
            'has-comment':
              hasComment || !showEvent || hasMatch || !isImageExistWithCauze,
          })}
        />
      )}
      <div className="flex justify-between w-full !mr-0">
        <button
          className={cx(
            'cz-btn-brand-dark lg:flex-1 pt-3 w-[100px] min-[380px]:w-[122px] lg:max-w-[245px]',
            {
              invisible: !Boolean(onJoinClick) && isTablet,
              hidden: !isTablet,
            },
          )}
          onClick={onJoinClick}
          disabled={isJoinedActivity}
        >
          {isJoinedActivity ? 'JOINED' : 'JOIN'}
        </button>

        {(item?.totalActorCount || 0) >= 3 && Boolean(item?.event?.current) && (
          <FeedItemAction
            Icon={GiveIcon}
            text={<Currency compact amount={item?.event?.current || 0} />}
            onClick={onJoinClick}
          />
        )}
        <FeedItemAction
          Icon={CommentIcon}
          text={commentCount || 0}
          onClick={onCommentClick}
          iconClassName={cx({
            'comment-highlighted': currentEntityCommented && commentCount,
          })}
          textClassName={cx('text-xs leading-none font-agenda mt-auto', {
            'text-text-light': !(currentEntityCommented && commentCount),
            'text-cauzeorange': currentEntityCommented && commentCount,
          })}
        />
        <FeedItemAction
          Icon={
            (currentEntityLiked ?? item?.currentEntityLiked) &&
            authStore.isAuthenticated
              ? HeartFilled
              : HeartOutline
          }
          text={likeCount ?? item?.likeCount ?? 0}
          onClick={() => onLike({ feedId: item?.id })}
          textClassName={cx('text-xs leading-none font-agenda mt-auto', {
            'text-text-light': !(
              currentEntityLiked ?? item?.currentEntityLiked
            ),
            'text-cauzeorange':
              (currentEntityLiked ?? item?.currentEntityLiked) &&
              authStore.isAuthenticated,
          })}
        />
        <FeedItemAction Icon={ShareIcon} text="Share" onClick={onShareClick} />
      </div>
    </div>
  );
};

export default FeedItemActions;
