import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import ErrorModal from 'components/modals/ErrorModal/';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'components/Avatar/Avatar';
import classNames from 'classnames';
import CommentInputImageUploadModal from './CommentInputImageUploadModal';
import {
  generateVideoThumbnail,
  getVideoDurationFromFile,
  urlToImageFile,
} from 'util/image';

const LoadingImage = () => (
  <div className="image-loading-area">
    <CauzeSpinner size="4rem" />
  </div>
);

const FeedCommentInput = ({
  uploadingComment = false,
  comment = {},
  style,
  activeEntity,
  postButtonText = 'Post',
  cauzePurchaseStore,
  onUpdateComment,
  onConfirm,
  maxHeight = 150,
  uiStore,
}) => {
  const [uploadingImage, setUploadingImage] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(
    comment?.imageUrls?.lg || '',
  );
  const inputRef = useRef(null);
  const imageInputRef = useRef(null);

  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [commentText, setCommentText] = useState(comment?.body || '');
  const [showImageUploadError, setShowImageUploadError] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    // Update comment text and image preview URL when the comment prop changes
    if (comment) {
      setCommentText(comment.body || '');
      setPreviewImageUrl(
        comment?.imageUrls?.lg || comment?.imageUrls?.md || '',
      );
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        maxHeight,
      )}px`;
    }
  }, [comment]);

  const handleCommentChange = (text) => {
    setCommentText(text);
    onUpdateComment({ body: text });
  };

  const handleInputChange = () => {
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = `${Math.min(
      inputRef.current.scrollHeight,
      maxHeight,
    )}px`;
  };

  const handleCloseImageUploadError = () => {
    cauzePurchaseStore?.resetImage();
    setShowImageUploadError(!showImageUploadError);
  };

  const onSelectFile = async (e) => {
    setUploadingImage(true);

    try {
      if (e.target.files && e.target.files.length > 0) {
        const targetFile = e.target.files[0];

        if (targetFile.type.includes('video')) {
          const duration = await getVideoDurationFromFile(targetFile);

          if (duration > 60) {
            uiStore.showNotification({
              body: 'Video uploads must be under 1 minute.',
              type: 'ERROR',
            });
            return;
          }

          generateVideoThumbnail(
            targetFile,
            async ({ image, width, height }) => {
              cauzePurchaseStore.updateComment({
                previewImageUrl: image,
                externalImageUrl: null,
                previewUrl: null,
              });

              await Promise.all([
                urlToImageFile(image).then((imgFile) =>
                  cauzePurchaseStore
                    .uploadImage(imgFile)
                    .then(({ imageId, previewUrl }) => {
                      setPreviewImageUrl(previewUrl);
                      cauzePurchaseStore.updateComment({
                        imageId: imageId,
                        previewImageUrl: previewUrl,
                      });
                    }),
                ),

                cauzePurchaseStore.startVideoUpload({
                  width,
                  height,
                  video: targetFile,
                }),
              ]);

              setUploadingImage(false);
            },
          );
        } else {
          if (e.target.files[0].size > 3000000) {
            setShowFileError(
              'File is too large. Please select a file less than 3MB.',
            );
            return;
          }
          setIsChanged(true);
          setShowImageUploadModal(true);
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            setPreviewImageUrl(reader.result);
            setUploadingImage(false);
          });
          reader.readAsDataURL(e.target.files[0]);
        }
      }
    } catch (err) {
      setUploadingImage(false);
    }
  };

  return (
    <div
      className="support-block comment-input w-full !mb-0 !pb-0 flex-column flex-align-center"
      style={style}
    >
      <div className="is-hidden">
        <input
          ref={imageInputRef}
          type="file"
          accept="image/*, video/*"
          onChange={onSelectFile}
        />
      </div>

      <CommentInputImageUploadModal
        updateImageUrl={setPreviewImageUrl}
        store={cauzePurchaseStore}
        setUploadingImage={setUploadingImage}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        cropRatio={[1, 1]}
        src=""
        noPlaceholder
      />

      <ErrorModal
        onToggleClose={handleCloseImageUploadError}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={cauzePurchaseStore?.imageUploadError}
      />

      <div style={{ width: '100%' }} className="flex-column flex-space-between">
        <div className="comment-area-container">
          {uploadingImage && <LoadingImage />}
          {previewImageUrl && (
            <div className="image-container">
              <div className="remove-container">
                <img
                  className="comment-image"
                  src={previewImageUrl}
                  alt="Preview"
                />
                <p
                  onClick={(ev) => {
                    ev.preventDefault();
                    cauzePurchaseStore?.resetImage();
                    setPreviewImageUrl('');
                    onUpdateComment({ imageId: null, imagePreviewUrl: null });
                  }}
                  className="image-remove-icon"
                >
                  x
                </p>
              </div>
            </div>
          )}
          <textarea
            ref={inputRef}
            className={classNames('comment-area !p-3 overflow-hidden', {
              'is-image': previewImageUrl,
            })}
            value={commentText}
            onChange={(ev) => {
              handleInputChange();
              handleCommentChange(ev.target.value);
            }}
            placeholder="Add a comment"
            rows="1"
            style={{
              maxHeight: `${maxHeight}px`,
            }}
            cols="100"
          />
        </div>
        <div className="flex-row flex-align-center  mt-2  flex-space-between">
          <div className="flex-row flex-align-center">
            <Avatar className="comment-avatar" avatar={activeEntity?.avatar} />
            <FontAwesomeIcon
              icon={faCamera}
              className="camera-icon"
              onClick={(ev) => {
                ev.preventDefault();
                imageInputRef.current.click();
              }}
            />
          </div>
          <button
            onClick={async (ev) => {
              ev.preventDefault();
              if (commentText && !cauzePurchaseStore?.uploadingCommentImage) {
                inputRef.current.style.height = 'auto';

                await onConfirm();
                setPreviewImageUrl('');
                setCommentText('');
              }
            }}
            disabled={!commentText || uploadingComment || uploadingImage}
            className="cz-btn-brand"
          >
            {uploadingComment ? 'Saving...' : postButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(FeedCommentInput);
