const FeedItemAction = ({
  Icon,
  text,
  iconClassName,
  onClick,
  textClassName = 'text-xs leading-none font-agenda text-text-light mt-auto',
}) => {
  return (
    <div
      className="text-center h-[37px] grid grid-rows-2 cursor-pointer"
      onClick={onClick}
    >
      <div className={iconClassName}>
        <Icon />
      </div>
      <div className={textClassName}>{text}</div>
    </div>
  );
};

export default FeedItemAction;
