/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';

import {
  charitiesQuery,
  processPendingGrantsMutation,
  generatePendingGrantsReportMutation,
  pendingGrantsJobStatusQuery,
} from '../graphql/charity';
import { quickSearchProjectsQuery } from '../graphql/search';

import { createDownloadTokenMutation } from '../graphql/companyReporting';
import Sleep from '../util/sleep';
import downloadLink from '../util/downloadLink';

// import matchSorter from 'match-sorter';

class CharitiesStore {
  @observable loading = true;
  @observable isError = false;

  @observable charities = [];
  @observable searchString = '';
  @observable lastMonthGrantProgress = 0;
  @observable todayGrantProgress = 0;
  @observable showLastMonthGrantProgress = false;
  @observable showTodayGrantProgress = false;

  // id
  // name
  // description
  // shareLink
  // avatar { sm }
  // balance
  // street
  // city
  // state
  // zip
  // ein

  @observable activeCharityId = null;

  getInitial = () => {
    this.getCharities();
  };

  @action getCharities = async () => {
    this.loading = true;
    this.searchString = '';

    const options = {
      variables: {},
      query: charitiesQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      this.charities = await apolloClient.query(options).then((res) => {
        if (res.data?.charitiesWithBalance) {
          return res.data.charitiesWithBalance.map((charity) => {
            let address =
              (charity.street ? charity.street : '') +
              (charity.city ? ` ${charity.city}` : '') +
              (charity.state ? `, ${charity.state}` : '') +
              (charity.zip ? ` ${charity.zip}` : '');

            return {
              ...charity,
              balance: charity.balance.total,
              address: address,
              entityType: 'CHARITY',
            };
          });
        }
        return [];
      });

      this.loading = false;
      this.isError = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  filterRows = (filter, rows) => {
    if (filter.value === this.searchString) return rows;

    if (filter.value === '' && this.searchString !== '') this.getCharities();
    else this.searchCharities(filter);

    return rows;
  };

  @action searchCharities = async (filter) => {
    this.loading = true;
    this.searchString = filter.value;

    const options = {
      query: quickSearchProjectsQuery,
      variables: { query: filter.value },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      this.charities = await api2Client.query(options).then((res) => {
        if (res.data?.quickSearchProjects) {
          return res.data.quickSearchProjects.map((charity) => {
            let address =
              (charity.street ? charity.street : '') +
              (charity.city ? ` ${charity.city}` : '') +
              (charity.state ? `, ${charity.state}` : '') +
              (charity.zip ? ` ${charity.zip}` : '');

            return {
              ...charity,
              balance: charity.balance.total,
              address: address,
              entityType: 'CHARITY',
            };
          });
        }
        return [];
      });

      this.loading = false;
      this.isError = false;
    } catch (err) {
      console.log(err);
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action setActiveCharity = (charityId) => {
    this.activeCharityId = charityId;
  };

  @action getAdminDownloadToken = async (handlerName) => {
    const options = {
      mutation: createDownloadTokenMutation,
      variables: { handlerName },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const token = await apolloClient
        .mutate(options)
        .then((result) => result.data.createDownloadToken.token);

      return token;
    } catch (err) {
      this.error = err;
    }
  };

  @action clearGrantProgressState = (excludeCurrentMonth) => {
    if (excludeCurrentMonth) {
      this.lastMonthGrantProgress = 0;
      this.showLastMonthGrantProgress = false;
    } else {
      this.todayGrantProgress = 0;
      this.showTodayGrantProgress = false;
    }
  };

  @action getPendingGrantsJobStatus = async (jobId, excludeCurrentMonth) => {
    const options = {
      query: pendingGrantsJobStatusQuery,
      variables: { jobId },
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    while (true) {
      try {
        const grantJobStatus = await api2Client
          .query(options)
          .then((result) => result.data.jobStatus);

        if (excludeCurrentMonth) {
          this.lastMonthGrantProgress = grantJobStatus.progress;
        } else {
          this.todayGrantProgress = grantJobStatus.progress;
        }

        if (grantJobStatus.progress === 1) {
          downloadLink(grantJobStatus.jobOutput);
          this.clearGrantProgressState(excludeCurrentMonth);
          break;
        }
        if (grantJobStatus.status === 'ERROR') {
          console.error(grantJobStatus.jobOutput);
          alert(`Error in grant processing job: ${grantJobStatus.jobOutput}`);
          this.clearGrantProgressState(excludeCurrentMonth);
          break;
        }
        Sleep(1000);
      } catch (err) {
        alert('Something went wrong:', err);
        this.error = err;
        this.clearGrantProgressState(excludeCurrentMonth);
        break;
      }
    }
  };

  @action generatePendingGrants = async (excludeCurrentMonth) => {
    const options = {
      mutation: generatePendingGrantsReportMutation,
      variables: { excludeCurrentMonth },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const grantJob = await api2Client
        .mutate(options)
        .then((result) => result.data.generatePendingGrantsReport);

      if (grantJob.status === 'INITIATED') {
        if (excludeCurrentMonth) {
          this.showLastMonthGrantProgress = true;
        }
        if (!excludeCurrentMonth) {
          this.showTodayGrantProgress = true;
        }

        this.grantProgress = grantJob.progress;
        this.getPendingGrantsJobStatus(grantJob.jobId, excludeCurrentMonth);
      }
    } catch (err) {
      this.error = err;
      alert('Something went wrong:', err);
    }
  };

  @action processPendingGrants = async () => {
    const options = {
      mutation: processPendingGrantsMutation,
      variables: {},
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const grants = await apolloClient
        .mutate(options)
        .then((result) => result.data);

      return grants;
    } catch (err) {
      this.error = err;
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.charities = [];
    this.searchString = '';
    this.lastMonthGrantProgress = 0;
    this.todayGrantProgress = 0;
    this.showLastMonthGrantProgress = false;
    this.showTodayGrantProgress = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const charitiesStore = new CharitiesStore();

export default charitiesStore;
