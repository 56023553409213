import { create } from 'zustand';
import mixpanel from 'mixpanel-browser';
import { isEmpty } from 'lodash';
import axios from 'axios';

import config from '../config';
import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import { confirmStripePayment } from 'util/checkoutUtils';
import { checkoutFormValidate as validate } from 'util/formFieldValidators';
import { getToken } from 'util/storage';

import uiStore from './UiStore';
import {
  checkoutDetailsQuery,
  completeDonationCheckout,
  createPaymentIntentMutation,
  editDonationCheckoutMutation,
  getPaymentAmountQuery,
  startDonationCheckoutMutation,
  updatePaymentIntentMutation,
  unauthedStartDonationCheckoutMutation,
  unauthedCompleteDonationCheckout,
} from 'graphql/donationCheckout';
import {
  sendGiftsMutation,
  completeGiftCheckoutMutationV2,
} from 'graphql/giftCheckout';
import { paymentOptionsQueryV2 } from 'graphql/paymentOptions';
import { createVideoMutation } from 'graphql/content';
import { userProfileByIdQueryV2 } from 'graphql/user';

export const CHECKOUT_TYPE = {
  DONATION: 'donation',
  GIFT: 'gift',
  GIFTED_DONATION: 'gifted_donation',
};

export const FRAME = {
  CHECKOUT: 0,
  PAYMENT_METHOD: 1,
  CHECKOUT_SUMMARY: 2,
  ADD_NEW_CARD: 3,
  CHECKOUT_COMPLETE: 4,
  ERROR: 5,
  GIFT_CHECKOUT: 6,
};

const UPLOAD_URL = `${config.UPLOAD_ROOT}/cauze`;
const UPLOAD_URLV2 = `${config.API_ROOTV2}/api`;
const CAUZE_IMAGE_FORM_KEY = `image`;

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  activeEntity: null,
  paymentOptions: null,
  balance: 0,
  onToggleClose: () => {},
  emailIsPrivate: false,
  elements: null,
  stripe: null,
};

const resetState = {
  onCheckoutSuccess: () => {},
  amount: 500,
  emails: null,
  loading: true,
  contentUploading: false,
  activePaymentMethod: null,
  paymentIntentId: null,
  clientSecret: null,
  frame: 0,
  frameHistory: [],
  addCardIsComplete: false,
  isEmails: false,
  userProfile: null,
  checkoutType: CHECKOUT_TYPE.DONATION,
  eventId: null,
  charityIds: null,
  joinPurchaseId: null,
  createEvent: false,
  giftToken: null,
  eventName: null,
  comment: null,
  commentImageId: null,
  previewUrl: null,
  referralLink: null,
  referralPath: null,
  cardBrand: null,
  externalImageUrl: null,
  token: null,
  splitAmount: null,
  giftType: null,
  phoneNumbers: null,
  companyIds: null,
  hideAmount: false,
  initiatorId: null,
  matchIds: [],
  matches: [],
  joinedEntity: null,
  customerId: null,
  videoData: null,
  referrer: null,
};

const useCheckoutStore = create((set, get) => ({
  ...initialState,
  ...resetState,

  /* HELPERS */
  update: (updatedState) => {
    set({ ...updatedState });
  },
  reset: (full = false) => {
    const state = get();

    if (full) {
      state.update({
        ...initialState,
        ...resetState,
      });
    } else {
      state.update(resetState);
    }
  },
  executeRecaptcha: () => {
    const grecaptchaPromise = new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute('6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD', {
            action: 'donationCheckout',
          })
          .then((recaptchaToken) => {
            resolve(recaptchaToken);
          }, reject);
      });
    })
      .then((token) => token)
      .catch((err) => {
        console.err(err);
      });
    return grecaptchaPromise;
  },
  popErrorModal: (message) => {
    uiStore.showNotification({
      body: message,
      type: 'ERROR',
    });
  },
  uploadImage: async (imageFile) => {
    if (imageFile) {
      try {
        const token = await getToken();
        const url = UPLOAD_URL;
        const data = new FormData();
        data.append(CAUZE_IMAGE_FORM_KEY, imageFile);
        const res = await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        return {
          imageId: res.data.id,
          previewUrl: res.data.urls.md,
        };
      } catch (err) {
        // pass
      }
    }
  },
  startVideoUpload: async ({ width, height, video }) => {
    const state = get();
    const options = {
      variables: { width, height },
      mutation: createVideoMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.mutate(options);

      state.update({
        videoData: {
          width,
          height,
          video,
          id: result.data.createVideo.id,
        },
      });
    } catch (err) {
      console.log('startVideoUploadError:', err);
      state.update({
        videoData: null,
      });
    }
  },
  completeVideoUpload: async () => {
    const state = get();
    const token = await getToken();
    const data = new FormData();
    data.append('file', state.videoData.video);

    try {
      await axios.post(
        `${UPLOAD_URLV2}/video/${state.videoData.id}/upload`,
        data,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        },
      );
    } catch (err) {
      // pass
    }
  },
  validateReferrerContext: async () => {
    const state = get();

    if (state.referrerContext) {
      if (
        state.activeEntity?.type === 'USER' &&
        parseInt(state.activeEntity?.id) ===
          parseInt(state.referrerContext.userId)
      ) {
        return null;
      } else {
        const userId = parseInt(state?.referrerContext?.userId);

        const options = {
          variables: { userId },
          query: userProfileByIdQueryV2,
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };

        try {
          const result = await api2Client.query(options);
          const resultData = result.data.data;
          const referrer = {
            ...resultData,
            name: `${resultData.firstName} ${resultData.lastName}`,
            type: 'USER',
          };

          set({
            referrer: referrer,
            joinedEntity: referrer,
          });
        } catch (err) {
          return null;
        }
      }
    } else return null;
  },

  /* FRAME MANAGEMENT */
  FRAME,
  CHECKOUT_TYPE,
  setFrame: (frame) => {
    const state = get();
    set({ frame, frameHistory: [...state.frameHistory, state.frame] });
  },
  setContentUploading: (contentUploading) => {
    set({ contentUploading });
  },
  goBack: () => {
    const state = get();
    const frameHistory = state.frameHistory.slice();
    const lastFrame = frameHistory.pop();
    set({ frame: lastFrame, frameHistory });
  },

  /* PAYMENT METHODS */
  getPaymentType: () => {
    const state = get();

    if (!state.activePaymentMethod) {
      return '';
    }

    const paymentType =
      state.activePaymentMethod.paymentType ||
      state.activePaymentMethod.accountType ||
      '';

    if (paymentType?.toUpperCase() === 'CARD') {
      return 'STRIPE_CC';
    }

    if (paymentType?.toUpperCase() === 'ACH') {
      return 'STRIPE_ACH';
    }

    return paymentType;
  },
  getPaymentMethods: async () => {
    const state = get();
    const userContext = state?.activeEntity?.userContext;

    const options = {
      variables: { includePending: true },
      query: paymentOptionsQueryV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);

      if (result.data.paymentOptions && result.data.paymentOptions.length > 0) {
        state.update({ paymentOptions: result.data.paymentOptions });

        let cards = [];
        result.data.paymentOptions.forEach((paymentOption) => {
          if (paymentOption.paymentType === 'balance') {
            state.update({
              balance: paymentOption.balance.total,
              recurringCardId: paymentOption.balance.recurringPaymentMethodId,
              recurringDepositDay: paymentOption.balance.recurringDepositDay,
              recurringAmount: paymentOption.balance.recurringAmount,
            });
          }

          if (
            paymentOption.paymentType === 'stripe_cc' ||
            paymentOption.paymentType === 'stripe_ach'
          ) {
            cards = cards.concat(paymentOption.paymentMethods || []);

            if (userContext.companyId) {
              state.update({
                hasAutopayEnabled:
                  paymentOption.paymentMethods.filter(
                    (card) => card.autopayEnabled,
                  ).length > 0,
              });
            }
          }
        });

        state.update({ cards });
      }

      return get().paymentOptions;
    } catch (err) {
      // pass
    }

    return [];
  },
  chooseDefaultPaymentMethod: async () => {
    const state = get();

    const { activeEntity } = state;
    const paymentOptions = await state.getPaymentMethods();

    if (state.giftToken) {
      state.update({
        activePaymentMethod: {
          paymentType: 'GIFT_TOKEN',
        },
      });
    }

    if (!paymentOptions?.length) {
      state.update({ activePaymentMethod: null });
      return null;
    }

    let balance = 0;
    let giftType = state.giftType;
    let defaultCard = state.defaultCard;
    let balancePm = null;

    paymentOptions.forEach((paymentOption) => {
      if (defaultCard) return;

      const paymentType = paymentOption.paymentType?.toUpperCase();

      if (paymentType === 'BALANCE') {
        balance = paymentOptions[0].balance?.total;
        balancePm = paymentOptions[0];
        state.update({ balance });
      }

      // Bank cards are payment options with paymentType 'CARD' and accountType 'ACH'
      if (
        ['STRIPE_ACH', 'STRIPE_CC'].includes(paymentType) &&
        paymentOption.paymentMethods.length > 0
      ) {
        defaultCard =
          paymentOption.paymentMethods.find((card) => card.is_default) ||
          paymentOption.paymentMethods[0];
      }
    });

    if (
      giftType !== 'SELF_GIFT' &&
      (balance > (state.amount ?? 0) || activeEntity?.balance?.allowNegative)
    ) {
      defaultCard = balancePm;
    }

    state.update({
      activePaymentMethod: defaultCard,
    });

    return defaultCard;
  },
  getActivePaymentMethod: () => {
    const state = get();
    return state.activePaymentMethod;
  },
  setActivePaymentMethod: (activePaymentMethod) => {
    set({ activePaymentMethod });
  },

  /* PAYMENT INTENT */
  createPaymentIntent: async ({ savePaymentMethod = true, customerId }) => {
    const state = get();
    const paymentMethod = state.getActivePaymentMethod();

    const options = {
      variables: {
        amount: state.amount,
        paymentMethod: paymentMethod.stripeCardId,
        savePaymentMethod,
        customerId,
      },
      mutation: createPaymentIntentMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    const res = await api2Client.mutate(options);
    return res.data?.createPaymentIntent;
  },
  updatePaymentIntent: async () => {
    const state = get();
    const activePaymentMethod = state.getActivePaymentMethod();

    if (!state.splitAmount?.total || !state.paymentIntentId) {
      // TODO: error handling
      return;
    }

    const options = {
      variables: {
        amount: state.splitAmount.total,
        intentId: state.paymentIntentId,
        paymentMethod: activePaymentMethod.stripeCardId,
        customerId: state.customerId,
      },
      mutation: updatePaymentIntentMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    const res = await api2Client.mutate(options);
    return res.data?.updatePaymentIntent;
  },
  evaluatePaymentIntent: async () => {
    const state = get();

    if (!state.paymentIntentId) {
      const paymentIntent = await state.createPaymentIntent({});
      state.update({
        paymentIntentId: paymentIntent.id,
        clientSecret: paymentIntent.clientSecret,
      });
    } else {
      const paymentIntent = await state.updatePaymentIntent();
      state.update({
        clientSecret: paymentIntent.clientSecret,
      });
    }
  },

  /* PRE-CHECKOUT */
  loadMatches: ({ matches = [] }) => {
    const state = get();

    let matchIds = [];

    if (matches?.length > 0) {
      matchIds = matches
        .filter((match) => {
          if (
            Number.parseInt(match?.totalRemaining) <= 0 ||
            Number.parseInt(match?.userMatchLimit) <= 0
          ) {
            return false;
          }

          if (isEmpty(state.activeEntity)) {
            return true;
          }

          return Number.parseInt(match?.currentEntityRemaining) > 0;
        })
        .map((match) => match.id);
    }

    state.update({
      matchIds,
      matches,
    });
  },
  getCheckoutDetails: async () => {
    const state = get();

    if (state.checkoutType !== CHECKOUT_TYPE.DONATION) {
      return;
    }

    const {
      eventId,
      charityIds,
      joinPurchaseId,
      emailIsPrivate,
      createEvent,
      giftToken,
      amount,
    } = state;

    mixpanel.track('Donation Checkout Modal Load', {
      eventId,
      charityIds,
      activeEntity: state.activeEntity,
      userContext: state.activeEntity?.userContext,
      joinPurchaseId,
      emailIsPrivate,
      createEvent,
      giftToken,
      amount,
    });

    const options = {
      variables: {
        charityIds: !eventId
          ? charityIds?.map((cid) => parseInt(cid))
          : undefined,
        eventId: parseInt(eventId) || undefined,
      },
      query: checkoutDetailsQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      const { matches } = result.data.checkoutDetails;
      state.loadMatches({
        matches,
      });
    } catch (err) {
      throw new Error('Error fetching checkout details.');
    }
  },
  getUserDetails: () => {
    const state = get();

    const userDetails = {
      firstName: state.firstName || state.activeEntity?.firstName || '',
      lastName: state.lastName || state.activeEntity?.lastName || '',
      email: state.email || state?.email || '',
      zip: state.zip || state?.zip || '',
    };

    if (!userDetails.firstName) {
      userDetails.firstName = 'Gift';
    }

    if (!userDetails.lastName) {
      userDetails.lastName = 'Recipient';
    }

    if (!userDetails.email) {
      userDetails.email = 'giftrecipient@cauze.com';
    }

    return userDetails;
  },
  getAmount: async () => {
    const state = get();
    let paymentType = state.getPaymentType().toLowerCase();

    if (paymentType === 'venmo') {
      paymentType = 'paypal';
    }

    if (paymentType === 'gift_token') {
      return {
        total: state.amount,
        fee: 0,
        prepayFee: 0,
        remaining: 0,
      };
    }

    const variables = {
      amount: state.amount,
      paymentType: paymentType.toUpperCase(),
      cardBrand: state.activePaymentMethod?.brand,
    };

    if (
      paymentType === 'apple_pay' ||
      paymentType === 'google_pay' ||
      paymentType === 'card'
    ) {
      variables.paymentType = 'STRIPE_CC';
    }

    if (!variables.paymentType) {
      return {
        total: state.amount,
        fee: 0,
        prepayFee: 0,
        remaining: 0,
      };
    }

    const options = {
      variables: variables,
      query: getPaymentAmountQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    let result = await api2Client.query(options);
    return result.data.paymentAmount;
  },

  /* START CHECKOUT */
  startGiftCheckout: async () => {
    mixpanel.track('Donation Checkout Transaction Started');

    const state = get();
    const paymentMethod = state.getActivePaymentMethod();
    let paymentType = state.getPaymentType().toUpperCase();

    if (paymentType === 'STRIPE_ACH') {
      paymentType = 'ACH';
    } else if (paymentType === 'STRIPE_CC') {
      paymentType = 'CARD';
    } else if (paymentType === 'GOOGLE_PAY') {
      paymentType = 'APPLE_PAY';
    }

    const options = {
      variables: {
        amount: state.amount,
        comment: state.comment,
        emails: state.emails,
        phoneNumbers: state.phoneNumbers,
        userIds: state.userIds,
        companyIds: state.companyIds,
        giftType: state.giftType,
        paymentType,
        userContext: state.activeEntity?.userContext,
        delayBalanceCheckout: true,
        cardBrand: paymentMethod?.brand,
        senderFirstName: state.firstName,
        senderLastName: state.lastName,
        senderEmail: state.email,
      },
      mutation: sendGiftsMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    if (!paymentMethod) {
      return;
    }

    try {
      let result = await apolloClient.mutate(options);

      let updatedCheckout = {
        token: result.data.sendGifts.token,
        splitAmount: result.data.sendGifts.splitAmount,
      };

      if (
        paymentType === 'APPLE_PAY' ||
        paymentType === 'GOOGLE_PAY' ||
        ['STRIPE_CC', 'CARD'].includes(paymentType)
      ) {
        if (state.paymentIntentId) {
          result = await state.updatePaymentIntent({
            intentId: state.paymentIntentId,
            amount: result.data.sendGifts.splitAmount.total,
          });
        } else {
          result = await state.createPaymentIntent({
            amount: result.data.sendGifts.splitAmount.total,
            paymentMethod: paymentMethod.stripeCardId,
            savePaymentMethod: true,
            customerId: state.customerId,
          });
        }

        updatedCheckout = {
          ...updatedCheckout,
          paymentIntentId: result.id,
          clientSecret: result.clientSecret,
        };
      }

      state.update(updatedCheckout);
    } catch (err) {
      mixpanel.track('startGiftCheckout Error', { err: `${err}` });
      console.log('err', err);
    }
  },
  startDonationCheckout: async () => {
    mixpanel.track('Donation Checkout Transaction Started');

    const state = get();

    const paymentType = state.getPaymentType().toUpperCase();
    const userDetails = state.getUserDetails();
    const variables = {
      donationAmount: state.amount,
      charityIds: state.charityIds?.map((charityId) => parseInt(charityId)),
      platform: state.activeEntity ? 'WEB' : 'WEB_UNCLAIMED',
      paymentType,
      comment: state.comment,
      commentImageId: parseInt(state.commentImageId),
      commentVideoId: parseInt(state.videoData?.id),
      eventId: parseInt(state.eventId),
      joinPurchaseId: parseInt(state.joinPurchaseId),
      matchIds: state.matchIds,
      previewUrl: state.previewUrl,
      referralLink: state.referralLink,
      referralPath: state.referralPath,
      referrerId: state.referrer?.id,
      referrerType: 'USER',
      emailIsPrivate: state.emailIsPrivate,
      cardBrand: state.cardBrand,
      createEvent: state.createEvent,
      eventName: state.eventName,
      externalImageUrl: state.externalImageUrl,
      userDetails,
    };

    const options = {
      variables: {
        request: {
          ...variables,
          userDetails,
        },
      },
      mutation: startDonationCheckoutMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    const result = await api2Client.mutate(options);
    const { token, splitAmount, paymentIntentId, clientSecret } =
      result.data.startDonationCheckout;

    state.update({
      token,
      splitAmount,
      paymentIntentId,
      clientSecret,
    });
  },
  startCheckout: async () => {
    const state = get();

    state.update({ loading: true });

    if (!state.activePaymentMethod) return;

    try {
      if (state.checkoutType === CHECKOUT_TYPE.GIFT) {
        await state.startGiftCheckout();
      } else {
        await state.startDonationCheckout();
      }
    } catch (err) {
      // TODO: figure out what to do on checkout error
      mixpanel.track('startCheckout Error', { err: `${err}` });
      console.log('err', err);
    }
  },

  /* UPDATE CHECKOUT */
  updateDonationCheckout: async () => {
    const state = get();
    const activePaymentMethod = state.getActivePaymentMethod();

    if (!state.token || !activePaymentMethod || !state.amount) return;

    const userDetails = state.getUserDetails();

    state.update({ loading: true });

    const variables = {
      checkoutTokenUuid: state.token,
      donationAmount: state.amount,
      paymentType: state.getPaymentType().toUpperCase(),
      comment: state.comment,
      commentImageId: state.commentImageId,
      commentVideoId: state.videoData?.id,
      matchIds: state.matchIds,
      previewUrl: state.previewUrl,
      emailIsPrivate: state.emailIsPrivate,
      cardBrand: activePaymentMethod?.brand,
      externalImageUrl: state.externalImageUrl,
      paymentIntentId: state.paymentIntentId,
    };

    const options = {
      variables: {
        request: {
          ...variables,
          userDetails,
        },
      },
      mutation: editDonationCheckoutMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.mutate(options);
      const { splitAmount, clientSecret } = result.data.editDonationCheckout;
      state.update({ splitAmount, clientSecret });

      await state.updatePaymentIntent();
    } catch (err) {
      mixpanel.track('updateDonationCheckout Error', { err: `${err}` });
    } finally {
      state.update({ loading: false });
    }
  },
  updateGiftCheckout: async () => {
    const state = get();
    const splitAmount = await state.getAmount();
    state.update({ splitAmount });
    await state.evaluatePaymentIntent();
  },
  updateCheckout: async () => {
    const state = get();

    const splitAmount = await state.getAmount();
    state.update({ splitAmount });

    if (state.checkoutType === CHECKOUT_TYPE.GIFT) {
      await state.updateGiftCheckout();
    } else {
      await state.updateDonationCheckout();
    }
  },

  /* COMPLETE CHECKOUT */
  getDonationCheckoutVars: async () => {
    const state = get();
    const paymentType = state.getPaymentType().toUpperCase();

    const variables = {
      checkoutTokenUuid: state.token,
      paymentType: paymentType,
    };

    if (paymentType === 'PAYPAL') {
      variables.paypalPaymentData = state.paypalData;
    }

    if (['GOOGLE_PAY', 'APPLE_PAY'].includes(paymentType)) {
      variables.paymentType = 'STRIPE_CC';
    }

    return variables;
  },
  getGiftCheckoutVars: async () => {
    const state = get();
    const activePaymentMethod = state.getActivePaymentMethod();
    const paymentType = state.getPaymentType().toUpperCase();

    const variables = {
      uuid: state.token,
    };

    if (paymentType === 'PAYPAL') {
      variables.paypalData = state.paypalData;
    } else if (['APPLE_PAY', 'GOOGLE_PAY'].includes(paymentType)) {
      variables.paymentData = {
        paymentToken: state.paymentIntentId,
        paymentMethodId: state.paymentMethodId,
        paymentTokenType: 'INTENT',
        savePaymentMethod: false,
      };
      variables.paymentType = 'STRIPE_CC';
    } else if (activePaymentMethod?.stripeCardId) {
      const result = await confirmStripePayment({
        stripe: state.stripe,
        elements: state.elements,
        cardId: activePaymentMethod.id,
        clientSecret: state.clientSecret,
        firstName: state.firstName || state.userProfile?.firstName || '',
        lastName: state.lastName || state.userProfile?.lastName || '',
        email: state.email || state.userProfile?.email || '',
      });

      variables.paymentData = {
        paymentToken: result.paymentIntent.id,
        paymentMethodId: result.paymentIntent.payment_method,
        paymentTokenType: 'INTENT',
        savePaymentMethod: !Boolean(result.paymentIntent.payment_method),
      };
    }

    return variables;
  },
  getCheckoutVars: async () => {
    const state = get();
    return state.getDonationCheckoutVars();
  },
  completeGiftCheckout: async () => {
    const state = get();
    const variables = await state.getGiftCheckoutVars();

    const options = {
      variables,
      mutation: completeGiftCheckoutMutationV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    const result = await api2Client.mutate(options);

    mixpanel.track('Donation Checkout Transaction Completed');

    if (result.errors && result.errors.length > 0) {
      throw JSON.stringify(result.errors);
    }
  },
  completeDonationCheckout: async () => {
    const state = get();

    const variables = await state.getCheckoutVars();

    const options = {
      variables: { request: variables },
      mutation: completeDonationCheckout,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    };

    const result = await api2Client.mutate(options);
    if (result.errors && result.errors.length > 0) {
      throw JSON.stringify(result.errors);
    }

    state.update({
      checkoutSuccess: result.data.completeDonationCheckout,
    });

    mixpanel.track('Donation Checkout Transaction Completed');

    uiStore.saveUiState({ checkoutReferrer: {} });

    return result.data.completeDonationCheckout;
  },
  completeCheckout: async () => {
    const state = get();

    if (state.videoData) {
      await state.completeVideoUpload();
    }

    if (state.checkoutType === CHECKOUT_TYPE.GIFT) {
      await state.startGiftCheckout();
      await state.completeGiftCheckout();
    } else if (state.checkoutType === CHECKOUT_TYPE.GIFTED_DONATION) {
      await state.completeDonationGiftCheckout();
    } else {
      await state.completeDonationCheckout();
    }
  },
  completeDonationGiftCheckout: async () => {
    const state = get();

    const validationResult = validate({
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      charityIds: state.charityIds,
      charityId: state.charityIds[0],
    });

    if (validationResult.isValid) {
      state.update({ matchIds: state.matchIds });
      let options = {
        variables: {
          donationAmount: state.amount,
          charityIds: state.charityIds,
          charityId: state.charityId,
          comment: state.comment,
          commentImageId: state.commentImageId,
          eventId: state.eventId,
          matchIds: state.matchIds,
          paymentType: 'GIFT',
          userToken: state.giftToken,
          userDetails: state.getUserDetails(),
          emailIsPrivate: state.emailIsPrivate,
          platform: 'WEB_UNCLAIMED',
        },
        mutation: unauthedStartDonationCheckoutMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };

      try {
        const result = await apolloClient.mutate(options);
        if (result.errors) {
          throw JSON.stringify(result.errors);
        }

        mixpanel.track('Donation Checkout Transaction Started');

        state.update({
          token: result.data.startDonationCheckout.token,
          splitAmount: result.data.startDonationCheckout.splitAmount,
        });
      } catch (err) {
        mixpanel.track('completeDonationGiftCheckout Error', { err: `${err}` });
      }

      const recaptchaToken = await state.executeRecaptcha();
      const { token, giftToken } = get();
      options = {
        variables: {
          token,
          userToken: giftToken,
          recaptchaToken,
        },
        mutation: unauthedCompleteDonationCheckout,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: (err) => console.log(err),
      };

      try {
        const result = await apolloClient.mutate(options);
        mixpanel.track('Donation Checkout Transaction Completed');

        if (result.errors && result.errors.length > 0) {
          throw JSON.stringify(result.errors);
        }

        uiStore.saveUiState({ checkoutReferrer: {}, userToken: null });
      } catch (errors) {
        mixpanel.track('completeDonationGiftCheckout Error', {
          err: `${errors}`,
        });
        return false;
      }
    } else {
      // TODO: handle validation errors
    }
  },

  // paypal
  paypalValidate: async () => {
    const state = get();
    if (state.amount === 0) {
      state.popErrorModal('Please select an amount.');
      return false;
    }
    return true;
  },
  onPaypalSuccess: (orderDetails) => {
    const state = get();
    state.update({ loading: true });

    try {
      state.update({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });

      state.completeCheckout().then(() => {
        state.update({ loading: false });
        mixpanel.track('Donation Checkout Completed with Paypal');
        state.setFrame(state.FRAME.CHECKOUT_COMPLETE);
      });
    } catch (err) {
      state.update({ loading: false });
    }
  },
  onPaypalSuccessRelegated: (orderDetails) => {
    const state = get();
    state.update({ loading: true });

    try {
      state.update({
        paypalData: {
          payerId: orderDetails.payer.payer_id,
          paymentId: orderDetails.id,
          paymentToken: orderDetails.purchase_units[0].payments.captures[0]?.id,
        },
      });
      state.setActivePaymentMethod({ paymentType: 'paypal' });
    } catch (err) {
      // ignore
    }

    state.update({ loading: false });
  },
  onPaypalError: (error) => {
    const state = get();
    state.update({ loading: false });
    state.popErrorModal(
      'There was an issue processing your paypal transaction. Please try again with card payment. Support has been notified.',
    );

    mixpanel.track('Donation Checkout Error with Paypal', {
      error: `${error}`,
    });
  },
  onPaypalCancel: () => {
    const state = get();
    state.update({ loading: false });

    mixpanel.track('Donation Checkout Cancelled with Paypal');
  },
  onPaypalOrderCreate: async () => {
    const state = get();
    state.setActivePaymentMethod({ paymentType: 'PAYPAL' });
    state.update({ stripeToken: null });
    await state.startCheckout();
    return state.getPaypalOrder();
  },
  getPaypalOrder: () => {
    const state = get();

    if (state.splitAmount) {
      // paypal uses decimal money representation
      let donationTotal =
        (state.splitAmount.remaining || state.splitAmount.forCauze) / 100;
      let fee = state.splitAmount.fee / 100;
      let orderTotal = state.splitAmount.total / 100;

      return {
        intent: 'CAPTURE',
        purchase_units: [
          {
            items: [
              {
                name: `${
                  state.giftType === 'SELF_GIFT'
                    ? 'Cauze Funding'
                    : 'Cauze Gift'
                }`,
                quantity: 1,
                description: 'This amount goes to Cauze',
                unit_amount: { value: donationTotal, currency_code: 'USD' },
              },
              {
                name: 'Handling Fee',
                description: 'This charge does not go to Cauze',
                quantity: 1,
                unit_amount: { value: fee, currency_code: 'USD' },
              },
            ],
            amount: {
              currency_code: 'USD',
              value: orderTotal,
              breakdown: {
                item_total: { value: orderTotal, currency_code: 'USD' },
              },
            },
            description: `${
              state.giftType === 'SELF_GIFT' ? 'Cauze Funding' : 'Cauze Gift'
            }`,
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING', // default is "GET_FROM_FILE"
        },
      };
    }
  },
  getPaypalVars: () => {
    const state = get();

    return {
      currency: 'USD',
      commit: true,
      onSuccess: state.onPaypalSuccess,
      onError: state.onPaypalError,
      onCancel: state.onPaypalCancel,
      onOrderCreate: state.onPaypalOrderCreate,
      validateFunc: state.paypalValidate,
    };
  },

  // apple pay / google pay
  onApplePayOrderConfirm: async (ev) => {
    const state = get();
    const { stripe, elements } = state;

    try {
      const [firstName, ...rest] = ev.billingDetails.name.split(' ');
      state.update({
        firstName,
        lastName: rest.join(' '),
        email: ev.billingDetails.email,
      });

      await state.startCheckout();

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret: get().clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/checkout/success`,
        },
        redirect: 'if_required',
      });

      state.update({
        paymentIntentId: paymentIntent.id,
        clientSecret: paymentIntent.client_secret,
      });

      await state.updateCheckout();

      if (error) {
        state.popErrorModal('Unable to verify card payment. Try again later.');
      } else {
        await state.onApplePayOrderComplete();
      }
    } catch (err) {
      console.log(err);
    }
  },
  onApplePayOrderComplete: async () => {
    const state = get();

    state.update({ loading: true });

    try {
      state.update({
        paymentIntentId: state.paymentIntentId,
      });

      const checkoutComplete = await state.completeCheckout();
      state.update({ loading: false });

      if (state.onCheckoutSuccess) {
        state.onCheckoutSuccess(state);
      }

      if (state.checkoutType === CHECKOUT_TYPE.DONATION) {
        state.setFrame(state.FRAME.CHECKOUT_COMPLETE);

        if (checkoutComplete) {
          mixpanel.track('Donation Checkout Completed with Apple Pay');
        }
      } else {
        uiStore.closeModal();
      }
    } catch (err) {
      state.update({ loading: false });
      uiStore.showNotification({
        body: 'There was an issue processing your apple pay transaction. Please try again with card payment. Support has been notified.',
        type: 'ERROR',
      });
    }
  },
}));

export default useCheckoutStore;
