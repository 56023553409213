import React, { useEffect, useRef, useState } from 'react';
import { navigate, useLocation } from '@reach/router';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import ProfileHeader from 'components/profiles/ProfileHeader';
import FeedItem from 'components/FeedItem/FeedItem';
import { EventListWithArrowsAbove } from 'components/EventList/EventList';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { CharityListItem } from 'components/CharityList/CharityList';
import Button from 'components/Button/Button';
import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import AvatarList from 'components/AvatarList/AvatarList';

import { ReactComponent as PencilIcon } from 'assets/images/icons/svgs/pencil.svg';
import useDashboardStore from 'stores/DashboardStore';
import useMobile from 'hooks/useMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  setShouldScrollToFeed,
  scrollToFeedIfNeeded,
  clearShouldScrollToFeed,
} from 'util/scrollUtils';
import StartCauzeButton from 'components/Button/StartCauzeButton';
import cx from 'classnames';
import { getEntityHomeUrl } from 'util/navHelpers';

const EntityProfile = ({
  showCheckoutModal,
  featuredProjects = [],
  loading,
  entityData,
  isAuthenticated,
  isInfluencer,
  onFollow,
  onLike,
  groupMember,
  uiStore,
  events = [],
  eventsLoading,
  feedLoading,
  feedLoadMore,
  feedLoadingMore,
  feed,
  entityType,
  activeEntity,
  isVerified,
  hideFollowerCount,
  showEditButton,
  onPin,
  companyMembers,
  store,
  showMembers,
  isSelf,
  onRearrange,
  onCheckoutSuccess,
  isCharityProfilePage = false,
  isCompanyProfilePage = false,
}) => {
  const feedSectionRef = useRef(null);
  const location = useLocation();
  const { isLaptop } = useMobile();

  const entityHomeUrl = getEntityHomeUrl({
    id: activeEntity?.id,
    entityType: activeEntity?.entityType,
  });
  const isOnEntityHomePage = location.pathname === entityHomeUrl;
  useEffect(() => {
    clearShouldScrollToFeed();
  }, [location]);
  const scrollToFeed = () => {
    if (!entityData.isCurrentEntity) {
      return;
    }
    if (feedSectionRef.current) {
      feedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setShouldScrollToFeed();
  };

  useEffect(() => {
    scrollToFeedIfNeeded(feedSectionRef);
  }, [feedSectionRef.current]);

  const { updateProps, showLoadingScreen } = useDashboardStore();

  const { isMobile } = useMobile();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const canLoadMore = true;

  const handleLike = ({ feedId }) => {
    if (!isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          navigate('/login');
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
    } else {
      onLike({ feedId });
    }
  };

  const handleSeeAll = () => {
    uiStore.openModal('ENTITY_LIST', {
      title: `${
        entityData.isCurrentEntity ? 'Your' : entityData.name
      }'s Members (${companyMembers.length})`,
      showFollow: true,
      entities: companyMembers?.map((member) => ({
        ...member,
        entityType: 'USER',
        name: `${member.firstName} ${member.lastName}`,
        isActive: true,
      })),
      buttonLabel: 'Follow',
      isSelf: entityData.isCurrentEntity,
      store: store,
      isAuthenticated: isAuthenticated,
      hasSearch: true,
      confirmMessage: 'PLEASE LOGIN OR SIGNUP TO FOLLOW MEMBERS',
    });
  };

  useEffect(() => {
    updateProps({
      size: 'half',
      isCharityProfilePage: isCharityProfilePage,
      asideChildren: isAuthenticated &&
        !isLaptop &&
        isOnEntityHomePage &&
        isCompanyProfilePage && (
          <div className={cx('xl:block')}>
            <div className="flex flex-col gap-y-[22px] mt-0 pt-0 px-4 lg:pt-12 lg:px-0">
              <StartCauzeButton activeEntity={activeEntity} />
            </div>
          </div>
        ),
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      showLoadingScreen('');
    }
  }, [loading]);

  if (loading || !entityData?.id) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div>
      <div className="profile-view-container">
        <div className="profile-view">
          <div>
            <ProfileHeader
              showCheckoutModal={showCheckoutModal}
              isVerified={isVerified}
              entityType={entityType}
              id={entityData.id}
              username={entityData.username}
              hero={entityData.hero}
              name={entityData.name}
              avatar={entityData.avatar}
              followerCount={entityData.followerCount}
              followingCount={entityData.followingCount}
              shareLink={entityData.linkUrl || entityData.shareLink}
              isSelfFollowing={entityData.isSelfFollowing}
              onFollow={onFollow}
              hideFollowerCount={hideFollowerCount}
              isAuthenticated={isAuthenticated}
              isInfluencer={isInfluencer}
              bio={entityData.bio}
              showEditButton={showEditButton}
              hasRecurringDeposit={entityData.hasRecurringDeposit}
              socialLinks={entityData.userSocials}
              profileData={entityData.profileData}
              foundation={null}
              webUrl={entityData.webUrl}
              isSelf={entityData.isCurrentEntity}
              email={entityData.email}
              activeEntity={entityData.activeEntity}
            />
            {groupMember && (
              <div className="flex-column flex-center">
                <div className="group-balance">
                  Group Balance: <Currency amount={groupMember.balance.total} />
                </div>
                <Button
                  style={{ marginBottom: '2rem' }}
                  className="is-medium"
                  onClick={() =>
                    uiStore.openModal('FUND_GROUP', {
                      groupId: entityData.id,
                    })
                  }
                >
                  Add Funds to Group
                </Button>
              </div>
            )}
            {(eventsLoading || (events && events.length > 0)) && (
              <>
                <EventListWithArrowsAbove
                  bleeding
                  iconSize="xs"
                  title={
                    entityData.isCurrentEntity
                      ? 'Your Cauzes'
                      : `${entityData.name}'s Cauzes`
                  }
                  className="profile-view-cauzes-title"
                  titleClasses="text-[20px] leading-[20px]"
                  events={events}
                  loading={eventsLoading}
                  onPin={onPin}
                  isSelf={entityData.isCurrentEntity}
                  uiStore={uiStore}
                />
              </>
            )}
            {isAuthenticated && isCompanyProfilePage && (
              <div
                className={cx({
                  'mt-3': isCharityProfilePage,
                })}
              >
                <StartCauzeButton activeEntity={activeEntity} />
              </div>
            )}
            {showMembers && (
              <>
                <div className="profile-view-cauzes-title">
                  <div className="profile-view-see-all">
                    <div>
                      {entityData.isCurrentEntity
                        ? 'Your'
                        : `${entityData.name}'s`}{' '}
                      Members ({companyMembers.length})
                    </div>
                    <div className="see-all" onClick={handleSeeAll}>
                      See All
                    </div>
                  </div>
                </div>
                <AvatarList avatars={companyMembers} />
              </>
            )}
            {featuredProjects && featuredProjects.length > 0 && (
              <>
                <div className="text-[20px] leading-none mt-8 mb-5 flex justify-between">
                  <div className="flex items-center gap-x-1">
                    <div className="font-agenda-bold">
                      {entityData.isCurrentEntity
                        ? 'Your'
                        : `${entityData.name}'s`}{' '}
                      Favorite Nonprofits
                    </div>
                    {isSelf && (
                      <div
                        className="profile-view-edit-button !mt-0"
                        onClick={() => {
                          uiStore.openModal('FAVORITE_NONPROFITS', {
                            charities: featuredProjects,
                            onRearrange,
                          });
                        }}
                      >
                        <span style={{ marginRight: 4, marginLeft: 8 }}>
                          <PencilIcon width="12" height="12" />
                        </span>
                        <span>EDIT</span>
                      </div>
                    )}
                  </div>
                  {swiperRef && swiperRef.allowSlideNext && !isMobile && (
                    <div className="flex gap-2 items-center justify-center">
                      {/* Custom Navigation Buttons */}
                      <div
                        ref={prevRef}
                        onClick={() => swiperRef.slidePrev()}
                        className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-prev ${
                          isAtBeginning ? '!cursor-default opacity-40' : ''
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          size={'xs'}
                          style={{
                            color: '#2a2a33',
                            transform: 'translateX(-1px)',
                          }}
                        />
                      </div>
                      <div
                        ref={nextRef}
                        onClick={() => {
                          swiperRef.slideNext();
                        }}
                        className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-next ${
                          isAtEnd ? '!cursor-default opacity-40' : ''
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          size={'xs'}
                          style={{
                            color: '#2a2a33',
                            transform: 'translateX(1px)',
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="discover-charity-list">
                  <Swiper
                    className={'charity-list'}
                    modules={[Navigation]}
                    spaceBetween={8}
                    slidesPerView={isMobile ? 2.1 : 3.5}
                    slidesPerGroup={isMobile ? 1 : 2}
                    onSlideChange={handleSlideChange}
                    navigation={{
                      prevEl: 'swiper-arrows-above-button-prev',
                      nextEl: 'swiper-arrows-above-button-next',
                    }}
                    onInit={(swiper) => {
                      setSwiperRef(swiper);
                      swiper.prevEl = prevRef.current;
                      swiper.nextEl = nextRef.current;
                      swiper.navigation.update();
                    }}
                    onSwiper={setSwiperRef}
                  >
                    {featuredProjects.map((charity) => (
                      <SwiperSlide key={charity.id}>
                        <CharityListItem
                          charity={charity}
                          onLearnMore={(id) => navigate(`/charity/${id}`)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
            {!feedLoading ? (
              feed && (
                <>
                  <div
                    ref={feedSectionRef}
                    className="profile-view-cauzes-title"
                  >
                    {entityData.isCurrentEntity
                      ? 'Your'
                      : `${entityData.name}'s`}{' '}
                    {entityData.isCurrentEntity ? 'Feed' : 'Activity'}
                  </div>
                  <div className="feed">
                    {feed.map((item, index) => (
                      <FeedItem
                        key={`${item.date}-${item.actor.id}-${index}`}
                        item={item}
                        index={index}
                        uiStore={uiStore}
                        shareLink={
                          item.event?.shareLink ||
                          item.activityPurchase?.shareLink
                        }
                        scrollToFeed={scrollToFeed}
                        onCheckoutSuccess={() => {
                          if (!entityData.isCurrentEntity) {
                            return;
                          }
                          onCheckoutSuccess();
                        }}
                        followingFeedStore={store}
                        onLike={handleLike}
                        entity={{
                          ...entityData,
                          groupType: 'USER',
                        }}
                      />
                    ))}
                  </div>
                  <div className="flex-row flex-justify-center pt-4">
                    {canLoadMore && (
                      <CauzeButton
                        white
                        large
                        isSubmitting={feedLoadingMore}
                        onClick={feedLoadMore}
                      >
                        Load More Results
                      </CauzeButton>
                    )}
                  </div>
                </>
              )
            ) : (
              <CauzeSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityProfile;
