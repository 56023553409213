import { useEffect, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import NewPaymentOptions from 'components/checkout/NewPaymentOptions';
import UserInfoInput from 'views/DonationCheckout/UserInfoInput';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import WrappedPaypalButton from 'components/WrappedPaypalButton';
import DonationLegalInfo from 'components/legal/DonationLegalInfo';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import Currency from 'components/Currency/Currency';

import stripeOptions from 'config/stripe';
import { checkoutFormValidate as validate } from 'util/formFieldValidators';
import MultipleItemMessage from 'util/MultipleItemMessage';
import * as events from 'util/events/checkout';
import Section from './CheckoutSection';
import WrappedGooglePayButton from 'components/WrappedGooglePayButton';
import { navigate } from '@reach/router';

const PaymentSection = ({
  isAuthenticated,
  authedOrUnauthedDonationStore,
  currentCheckout,

  minimumDonationAmount,
  activeEntity,
  uiStore,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const pendingInvites = authedOrUnauthedDonationStore.pendingInvites;
  const isBalance =
    `${currentCheckout.paymentType}`.toUpperCase() === 'BALANCE';
  const donateButtonText = (
    <>
      Give <Currency amount={currentCheckout.donationAmount} showCents />
    </>
  );

  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    card: true,
    isValid: true,
  });
  const [showUserInputError, setShowUserInputError] = useState(false);
  const [userInputErrorMessage, setUserInputErrorMessage] = useState('');

  const associatedEmailError = () => {
    if (
      authedOrUnauthedDonationStore?.pendingInvites &&
      authedOrUnauthedDonationStore?.pendingInvites?.userIsUnclaimed
    ) {
      const giftText = pendingInvites?.hasGiftToken ? ' a gift' : '';
      const groupInviteText = pendingInvites?.hasGroupInvite
        ? ' a group invite'
        : '';
      const matchText = pendingInvites?.matchText ? ' matches' : '';
      const errorMessage = (
        <MultipleItemMessage
          messageStart="The email address you provided has"
          messageItems={[giftText, groupInviteText, matchText]}
          messageEnd=" associated. Create an account to get the full benefit."
        />
      );
      return errorMessage;
    }

    if (!authedOrUnauthedDonationStore.pendingInvites.userIsUnclaimed) {
      return (
        <div>
          {`The email address you entered is associated with an exisiting account. If you `}
          <span
            className="login-text"
            onClick={() => {
              navigate('/login');
            }}
          >
            login
          </span>
          , your donation will be matched.;
        </div>
      );
    }
  };

  const unauthedPaypalValidate = async () => {
    const { charityId, charityIds } =
      authedOrUnauthedDonationStore.currentCheckout;

    let validationResult = validate({
      firstName: 'Gift',
      lastName: 'Recipient',
      email: 'cauzerecipient@cauze.com',
      charityId,
      charityIds,
      onNoCharitySelected: authedOrUnauthedDonationStore.onNoCharitySelected,
    });

    setValidation(validationResult);
    if (validationResult.isValid) {
      events.checkoutWithPaypal();
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (currentCheckout.donationAmount === 0) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
    }

    const underMinimumAmount =
      minimumDonationAmount &&
      currentCheckout.donationAmount < minimumDonationAmount;
    if (underMinimumAmount) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
      return;
    }

    if (isBalance) {
      authedOrUnauthedDonationStore.startCheckout();
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { firstName, lastName, email, charityId, charityIds } =
      authedOrUnauthedDonationStore.currentCheckout;

    let validationResult = { isValid: true };

    if (!isAuthenticated) {
      validationResult = validate({
        firstName,
        lastName,
        email,
        charityId,
        charityIds,
        onNoCharitySelected: authedOrUnauthedDonationStore.onNoCharitySelected,
      });
      authedOrUnauthedDonationStore.updateCheckout({
        paymentType: 'stripe_cc',
      });
    }

    // Unauthed card payment form
    if (validationResult.isValid) {
      await authedOrUnauthedDonationStore.startCheckout();
    } else {
      if (
        !validationResult.firstName ||
        !validationResult.lastName ||
        !validationResult.email
      ) {
        processUserInputErrors(validationResult);
        document
          .getElementById('userInput')
          .scrollIntoView({ block: 'center' });
      }
      events.checkoutValidationError();
      setValidation(validationResult);
    }
    if (currentCheckout.donationAmount === 0) {
      document
        .getElementById('customInput')
        .scrollIntoView({ block: 'center' });
    }
  };

  const processUserInputErrors = (validationResult) => {
    const firstName = !validationResult.firstName ? ' first name' : '';
    const lastName = !validationResult.lastName ? ' last name' : '';
    const email = !validationResult.email ? ' email address' : '';

    setUserInputErrorMessage(
      <MultipleItemMessage
        messageStart="Please provide a valid"
        messageItems={[firstName, lastName, email]}
      />,
    );

    setShowUserInputError(true);
  };

  useEffect(() => {
    authedOrUnauthedDonationStore.setStripeObject(stripe);
    authedOrUnauthedDonationStore.setStripeElements(elements);
  }, [authedOrUnauthedDonationStore, stripe, elements]);

  return (
    <Section title="2. Payment">
      <div className={isAuthenticated ? 'pt-5' : undefined}>
        {authedOrUnauthedDonationStore.showPendingInvitesMessage && (
          <ErrorCard
            message={associatedEmailError()}
            className="associated-email"
            support={true}
          />
        )}

        {showUserInputError && (
          <ErrorCard
            message={userInputErrorMessage}
            className="info-input-error"
          />
        )}

        {isAuthenticated && (
          <NewPaymentOptions
            activeEntity={activeEntity}
            excludedPaymentTypes={[
              currentCheckout.balance >= 0 && activeEntity.allowNegative
                ? 'BALANCE'
                : '',
            ]}
            store={authedOrUnauthedDonationStore}
            allowNegativeBalance={activeEntity.allowNegative}
            onDonate={handleSubmit}
            donateText={donateButtonText}
            showCardInputError={authedOrUnauthedDonationStore.cardInputError}
            dismissCardInputError={
              authedOrUnauthedDonationStore.dismissCardInputError
            }
          />
        )}

        {!isAuthenticated && (
          <div className="flex-column flex-align-center unauthed-payment pt-2">
            <h3>Digital Wallet</h3>
            {window.ApplePaySession ? (
              <WrappedPaypalButton store={authedOrUnauthedDonationStore} />
            ) : (
              <WrappedGooglePayButton store={authedOrUnauthedDonationStore} />
            )}
            <WrappedPaypalButton
              {...authedOrUnauthedDonationStore.getPaypalVars()}
              validateFunc={unauthedPaypalValidate}
            />
            <div className="my-4" />
            <h3>Card Payment</h3>
            <UserInfoInput
              donationCheckoutStore={authedOrUnauthedDonationStore}
              setValidation={setValidation}
              validation={validation}
              setErrorMessage={setShowUserInputError}
            />
            <div className="my-4" />
            <div
              className="stripe-element-wrapper"
              id="stripeCard"
              onClick={authedOrUnauthedDonationStore.dismissCardInputError}
              onFocus={authedOrUnauthedDonationStore.dismissCardInputError}
            >
              <PaymentElement
                options={stripeOptions.cardElement}
                onClick={authedOrUnauthedDonationStore.dismissCardInputError}
                onFocus={authedOrUnauthedDonationStore.dismissCardInputError}
              />
            </div>
            {authedOrUnauthedDonationStore.cardInputError && (
              <ErrorCard
                message="Oops. The card information you entered is incorrect. Please check,
        change the highlighted areas and you’ll be all set!"
                className="card-error"
              />
            )}
            <DonationLegalInfo uiStore={uiStore} />
            <CauzeButton onClick={handleSubmit} large className="donate mt-8">
              {donateButtonText}
            </CauzeButton>
          </div>
        )}
      </div>
    </Section>
  );
};

export default PaymentSection;
