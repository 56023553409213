import React, { Component } from 'react';
import { debounce } from 'lodash';
import colors from '../../styles/colors';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { client as apolloClient } from '../../util/apolloClient';
import { searchCharitiesQuery } from '../../graphql/search';
import makeAnimated from 'react-select/animated';
import Avatar from '../Avatar/Avatar';
import classNames from 'classnames';

const fetchResults = async (inputValue, callback) => {
  if (inputValue) {
    try {
      const result = await apolloClient
        .query({
          query: searchCharitiesQuery,
          variables: { query: inputValue },
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then((res) =>
          res.data.searchCharity.map((charity) => ({
            ...charity,
            value: charity.id,
            label: charity.name,
          })),
        );
      callback(result);
    } catch (err) {
      console.error(err);
    }
  }
};

const animatedComponents = makeAnimated();

const styles = {
  multiValueLabel: (provided, _state) => ({
    ...provided,
    whiteSpace: 'normal',
  }),
};

const MultiValueContainer = (props) => (
  <components.MultiValueContainer {...props}>
    <div className="flex-row">
      {props.data.avatar && (
        <div
          style={{
            justifySelf: 'stretch',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar
            xsm
            style={{ marginLeft: '0.25rem' }}
            avatar={props.data.avatar}
          />
        </div>
      )}
      {props.children}
    </div>
  </components.MultiValueContainer>
);

const Option = (props) => (
  <components.Option {...props}>
    <div className="flex-row flex-align-center">
      <Avatar
        sm
        style={{ marginRight: '0.5rem' }}
        avatar={props.data.avatar}
        type="charity"
      />
      <div className="flex-column">
        {props.children}, &nbsp;
        {props.data.location} &nbsp;
        <div style={{ fontSize: '0.8rem', color: 'lightgrey' }}>
          EIN: {props.data.ein}
        </div>
      </div>
    </div>
  </components.Option>
);

const debouncedFetchResults = debounce(fetchResults, 300);

const loadOptions = (inputValue, callback) => {
  debouncedFetchResults(inputValue, callback);
};

export default class CharitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.defaultValue };
  }

  removeValue = (index) => {
    let newValue = this.state.value.slice();
    newValue.splice(index, 1);
    this.setState({ value: newValue });
  };

  handleInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  render() {
    return (
      <div className="search-container !block !h-[inherit]">
        <AsyncSelect
          value={this.state.value}
          placeholder="Search for a Non-Profit"
          loadOptions={loadOptions}
          defaultOptions={false}
          isMulti
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.baseColor,
              primary50: colors.lightGrey,
              primary25: colors.white,
              danger: colors.baseColor,
              dangerLight: colors.baseColorHalfTransparent,
            },
          })}
          styles={styles}
          onInputChange={this.handleInputChange}
          components={{
            ...animatedComponents,
            Option,
            MultiValueContainer,
            ClearIndicator: () => null,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(value) => {
            this.props.onChange(value);
            this.setState({ value: value });
          }}
          autoLoad={false}
        />
        {this.props.showSelectionList && this.state.value && (
          <div
            className={classNames('search-result-container', {
              'border border-[#ccc] pb-4 pl-4 pr-4 border-solid':
                this.state.value.length > 0,
            })}
          >
            {this.state.value.map((charity, index) => (
              <div
                key={`${charity.id}`}
                className="flex-row flex-align-center search-result-row cursor-pointer"
                onClick={() => {
                  this.props.uiStore.openModal('PROFILE_MODAL', {
                    id: charity.id,
                    entityType: 'CHARITY',
                  });
                }}
              >
                <Avatar avatar={charity.avatar} entityType={'CHARITY'} />
                <div className="flex-expand flex-column">
                  <div className="flex-row text-bold">{charity.name}</div>
                  <div className="flex-row result-detail">
                    {charity.location} | EIN: {charity.ein}
                  </div>
                </div>
                <div
                  className="delete"
                  onClick={() => this.removeValue(index)}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
