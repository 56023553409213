import cx from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CauzeTabHeader from 'components/CauzeTabs/CauzeTabHeader';
import LikeEntity from './LikeEntity';
import { getProfileUrl } from 'util/stringUtils';
import FeedCommentItem from './FeedCommentItem';
import BlockModal from '../BlockModal/BlockModal';
import ReportModal from '../ReportModal/ReportModal';
import FeedCommentInput from './FeedCommentInput';
import ImageExpandModal from '../ImageExpandModal/ImageExpandModal';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import EditFeedCommentModal from './EditFeedCommentModal';

const CommentModal = ({
  onToggleClose,
  isOpen,
  userReportsStore,
  commentProps,
  cauzePurchaseStore,
  profileStore,
  uiStore,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });
  const activeEntity = profileStore.getActiveEntity();

  const [headerDropShadow, setHeaderDropShadow] = useState(false);
  // const [activeTab, setActiveTab] = useState('comments');
  const [showReportModal, setShowReportModal] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteDialog] = useState(false);
  const [selectedComment, setSelectedComment] = useState({});
  const [loadingComment, setLoadingComment] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [blockUsername, setBlockUsername] = useState('');
  const [reportProps, setReportProps] = useState({});
  const [tab, setTab] = useState(0);
  const [purchase, setPurchase] = useState(null);
  const [commentFeed, setCommentFeed] = useState(null);
  const [likeActors, setLikeActors] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const scrollRef = useRef(null);
  const modalBodyRef = useRef(null);
  const [showImageExpandModal, setShowImageExpandModal] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const id = commentProps.purchaseId;

  useEffect(() => {
    requestInitial();

    return () => {
      cauzePurchaseStore.clearFeed();
      cauzePurchaseStore.clearComment();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, activeEntity]);

  const requestInitial = async () => {
    cauzePurchaseStore.clearFeed();

    const fetchedPurchase = await cauzePurchaseStore.getPurchaseById({ id });
    setPurchase(fetchedPurchase);

    const fetchedCommentFeed = await cauzePurchaseStore.getFeed({
      purchaseId: id,
      userContext: activeEntity.userContext,
    });
    setCommentFeed(fetchedCommentFeed);

    const actors = await cauzePurchaseStore.getLikeActors({
      purchaseId: id,
      userContext: activeEntity.userContext,
    });

    setLikeActors(actors);
  };

  const canEdit = ({ user, company, charityId }) => {
    if (
      !isEmpty(activeEntity.userContext) &&
      !charityId &&
      (!company || company.name === '')
    ) {
      return +activeEntity.id === user.id;
    } else {
      if (charityId && charityId === activeEntity.userContext?.charityId) {
        return true;
      }
      if (company && company.id === activeEntity.userContext?.companyId) {
        return true;
      }
    }
    return false;
  };

  const handleShowReportModal = (props) => {
    setReportProps(props);
    setShowReportModal(true);
  };
  const handleShowConfirmDeleteModal = (item) => {
    setShowConfirmDeleteDialog(true);
    setSelectedComment(item);
  };

  const handleShowEditCommentModal = (item) => {
    setShowEditCommentModal(true);
    setSelectedComment(item);
  };

  const handleShowBlockModal = (props) => {
    setReportProps(props);
    setShowBlockModal(true);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
  };
  const closeImageExpandModal = () => {
    setShowImageExpandModal(false);
  };
  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteDialog(false);
  };
  const closeEditCommentModal = () => {
    setShowEditCommentModal(false);
  };

  const onImagePress = (imageExpandUrl) => {
    setImagePreviewUrl(imageExpandUrl);
    setShowImageExpandModal(true);
  };
  const renderRow = ({ item, index }) => {
    if (!item || typeof item !== 'object') {
      return null;
    }

    const canEditItem = canEdit(item);

    const actions = [];

    if (canEditItem) {
      actions.push({
        name: 'Edit',
        action: () => handleShowEditCommentModal(item),
      });
      actions.push({
        name: 'Delete',
        action: () => handleShowConfirmDeleteModal(item),
      });
    } else {
      actions.push({
        name: 'Report',
        action: () => handleShowReportModal({ purchaseCommentId: item.id }),
      });
      actions.push({
        name: 'Block',
        action: () => {
          console.log('item.user', item.user);
          setBlockUsername(item.user?.username);
          handleShowBlockModal({ userId: item.user?.id });
        },
      });
    }

    return (
      <FeedCommentItem
        key={index}
        imageUrls={item.imageUrls}
        video={item.video}
        props={{ ...item }}
        imageId={item?.imageId}
        openCommentOptions={onEditComment}
        body={item.body}
        canEdit={canEditItem}
        onImagePress={onImagePress}
        actions={actions}
        onToggleClose={onToggleClose}
      />
    );
  };

  const onAddConfirm = async () => {
    setLoadingComment(true);
    if (
      cauzePurchaseStore.comment.purchaseId ||
      cauzePurchaseStore.comment.commentId
    ) {
      cauzePurchaseStore.editComment({
        ...cauzePurchaseStore.comment,
      });
      return;
    }
    scrollRef.current &&
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    const fetchedCommentFeed = await cauzePurchaseStore.addComment({
      ...cauzePurchaseStore.comment,
      purchaseId: purchase.id,
      userContext: activeEntity.userContext,
    });
    const commentCount = fetchedCommentFeed.length;

    setCommentFeed(fetchedCommentFeed);
    commentProps.setFeedCount(commentCount, true);
    // commentProps.followingFeedStore.updateFeedItemOptimistically({
    //   index: commentProps.index,
    //   updatedProps: {
    //     commentCount: commentCount,
    //     currentEntityCommented: true,
    //   },
    // });
    cauzePurchaseStore.clearComment();
    setLoadingComment(false);
    modalBodyRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onEditComment = async (item) => {
    setUpdateLoading(true);
    try {
      await cauzePurchaseStore.editComment({
        commentId: item.id,
        body: item.body,
        previewUrl: item.previewUrl,
        imageId: item.imageId,
      });
      closeEditCommentModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const onRemoveComment = async (item) => {
    setDeleteLoading(true);
    try {
      await cauzePurchaseStore.deleteComment({ commentId: item.id });
      let fetchedCommentFeed = await cauzePurchaseStore.getFeed({
        purchaseId: id,
        userContext: activeEntity.userContext,
      });
      const commentCount = fetchedCommentFeed.length;
      const currentEntityCommented = fetchedCommentFeed.some(
        (item) => item.user?.isCurrentEntity,
      );

      setCommentFeed(fetchedCommentFeed);
      commentProps.setFeedCount(commentCount, currentEntityCommented);
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <div
        className={cx('modal comment-modal', {
          'is-active': isOpen,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            onToggleClose(false);
          }}
        />
        <div className="modal-card ">
          <header className="confirm-modal-head">
            <div className="modal-card-title">
              <div
                className="close-button flex-row flex-justify-end"
                onClick={() => {
                  onToggleClose(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
            <CauzeTabHeader
              leftButtonText={`${
                commentProps.commentCount || commentFeed?.length || 0
              } Comments`}
              leftButtonOnClick={() => setTab(0)}
              leftButtonActive={tab === 0}
              rightButtonText={`${
                commentProps.feed?.likeCount || likeActors?.length || 0
              } Likes`}
              rightButtonOnClick={() => setTab(1)}
              rightButtonActive={tab === 1}
              onToggleClose={onToggleClose}
            />
          </header>
          <div
            className=" confirm-modal-body "
            style={{ backgroundColor: 'white' }}
            ref={modalBodyRef}
          >
            {!purchase || !commentFeed || !likeActors ? (
              <div className="h-full">
                <CauzeSpinner marginTop="80px" />
              </div>
            ) : (
              <>
                {tab === 1 && (
                  <div className="flex flex-col mt-3 gap-2 border-b border-gray-d6d last:border-b-0">
                    {likeActors &&
                      likeActors.length > 0 &&
                      likeActors.map((actor) => (
                        <LikeEntity
                          key={actor.id}
                          name={actor.name}
                          avatarUrl={actor.avatarUrls}
                          link={getProfileUrl(actor.id, actor.type)}
                          onToggleClose={onToggleClose}
                        />
                      ))}
                  </div>
                )}

                {tab === 0 && (
                  <div
                    className="commentsContainer"
                    onScroll={(ev) => {
                      if (ev.target.scrollTop !== 0) {
                        if (!headerDropShadow) {
                          setHeaderDropShadow(true);
                        }
                      } else {
                        if (headerDropShadow) {
                          setHeaderDropShadow(false);
                        }
                      }
                    }}
                    ref={scrollRef}
                  >
                    {commentFeed.map((item, index) =>
                      renderRow({ item, index }),
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <footer className="modal-card-foot gap-x-3 shadow-2xl">
            <FeedCommentInput
              key={
                cauzePurchaseStore.comment.purchaseId ||
                cauzePurchaseStore.comment.commentId
              }
              activeEntity={activeEntity}
              uploadingComment={loadingComment}
              comment={cauzePurchaseStore.comment}
              cauzePurchaseStore={cauzePurchaseStore}
              postButtonText={
                cauzePurchaseStore.comment.purchaseId ||
                cauzePurchaseStore.comment.commentId
                  ? 'Save'
                  : 'Post'
              }
              onUpdateComment={cauzePurchaseStore.updateComment}
              onConfirm={onAddConfirm}
              uiStore={uiStore}
            />
          </footer>
        </div>
      </div>
      <BlockModal
        reportProps={reportProps}
        isOpen={showBlockModal}
        onToggleClose={() => setShowBlockModal(false)}
        userReportsStore={userReportsStore}
        uiStore={uiStore}
        blockUsername={blockUsername}
      />
      <ReportModal
        reportProps={reportProps}
        isOpen={showReportModal}
        onToggleClose={closeReportModal}
        userReportsStore={userReportsStore}
        uiStore={uiStore}
      />
      <ImageExpandModal
        previewUrl={imagePreviewUrl}
        isOpen={showImageExpandModal}
        onToggleClose={closeImageExpandModal}
      />
      <ConfirmModal
        isOpen={showConfirmDeleteModal}
        onToggleClose={closeConfirmDeleteModal}
        message="Delete Confirmation"
        bodyMessage="Are you sure you want to delete this comment?"
        cancelText="Cancel"
        confirmText="Delete"
        isSubmitting={deleteLoading}
        action={() => {
          onRemoveComment(selectedComment);
        }}
      />
      <EditFeedCommentModal
        isOpen={showEditCommentModal}
        onToggleClose={closeEditCommentModal}
        onToggleCloseCommentModal={onToggleClose}
        activeEntity={activeEntity}
        isSubmitting={updateLoading}
        cauzePurchaseStore={cauzePurchaseStore}
        selectedComment={selectedComment}
        onEditComment={onEditComment}
        onImagePress={onImagePress}
      />
    </>
  );
};

export default observer(CommentModal);
