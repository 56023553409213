import { useMemo } from 'react';
import { navigate } from '@reach/router';

const StartCauzeButton = ({ activeEntity }) => {
  const startCauzeUrl = useMemo(() => {
    if (activeEntity.entityType === 'COMPANY') {
      return `/admin/company/${activeEntity.userContext.companyId}/cauzes/create`;
    }

    if (activeEntity.entityType === 'USER') {
      return `/admin/user/${activeEntity.id}/cauzes/create`;
    }

    if (activeEntity.entityType === 'CHARITY') {
      return `/admin/charity/${activeEntity.userContext.charityId}/cauzes/create`;
    }

    if (activeEntity.entityType === 'INFLUENCER') {
      return `/admin/influencer/${activeEntity.userContext.influencerId}/cauzes/create`;
    }
  }, [activeEntity]);

  if (!startCauzeUrl) return null;

  return (
    <button
      className="cz-btn-black uppercase h-12 w-full"
      onClick={() => {
        navigate(startCauzeUrl);
      }}
    >
      start a cauze
    </button>
  );
};

export default StartCauzeButton;
