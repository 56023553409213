import React, { useState } from 'react';

import EntityListItem from './EntityListItem/EntityListItem';
import { convertEntityTypeToId, normalizeContext } from 'util/contextUtils';
import ProfileModal from 'components/modals/ProfileModal/ProfileModal';
const EntityList = ({
  showFollow,
  entities,
  buttonLabel,
  store,
  userContext,
  pageContext,
  setUnfollow,
  isAuthenticated,
  confirmMessage,
}) => {
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({
    id: null,
    entityType: null,
  });

  const handleProfileModalOpen = (id, entityType) => {
    setSelectedEntity({ id, entityType });
    setProfileModalOpen(true);
  };

  const handleProfileModalClose = () => {
    setProfileModalOpen(false);
    setSelectedEntity({ id: null, entityType: null });
  };
  return (
    <div className="entity-list-container">
      {entities &&
        entities.map((entity, index) => {
          const entityId = entity.id;
          const entityEntityType = entity.entityType;
          const targetContext = normalizeContext(
            convertEntityTypeToId({
              id: entityId,
              entityType: entityEntityType,
            }),
          );

          if (entity?.isActive === undefined && entity?.user) {
            entity.isActive = entity.user.isActive;
            entity.unclaimed = entity.user.unclaimed;
          }

          return (
            <EntityListItem
              entity={entity}
              showFollow={showFollow}
              buttonLabel={buttonLabel}
              store={store}
              userContext={userContext || { entityType: 'USER' }}
              pageContext={pageContext}
              targetContext={targetContext}
              targetId={entity.id}
              key={index}
              setUnfollow={setUnfollow}
              index={index}
              isAuthenticated={isAuthenticated}
              confirmMessage={confirmMessage}
              onProfileModalOpen={handleProfileModalOpen}
            />
          );
        })}
      <ProfileModal
        isOpen={profileModalOpen}
        onToggleClose={handleProfileModalClose}
        id={selectedEntity.id}
        entityType={selectedEntity.entityType}
      />
    </div>
  );
};

export default EntityList;
