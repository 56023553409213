import { useMemo } from 'react';
import cx from 'classnames';
import useWalletStore from 'stores/WalletStoreV2';

import { onLogoutClick } from 'util/userUtils';

import SidebarItem from './SidebarItem';

import { ReactComponent as HomeIcon } from 'assets/images/icons/svgs/home.svg';
import { ReactComponent as GiveIcon } from 'assets/images/icons/svgs/give.svg';
import { ReactComponent as PeopleIcon } from 'assets/images/icons/svgs/people.svg';
import { ReactComponent as DocDollarIcon } from 'assets/images/icons/svgs/document-dollar.svg';
import { ReactComponent as WalletIcon } from 'assets/images/icons/svgs/wallet.svg';
import { ReactComponent as GearIcon } from 'assets/images/icons/svgs/gear.svg';
import { ReactComponent as StarIcon } from 'assets/images/icons/star/star-outline.svg';
import { ReactComponent as GiftIcon } from 'assets/images/icons/gift/gift-box.svg';
import { ReactComponent as ReportingIcon } from 'assets/images/icons/svgs/reporting.svg';
import { getEntityHomeUrl } from 'util/navHelpers';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const Sidebar = ({
  activeEntity,
  expanded,
  setExpanded,
  authStore,
  profileStore,
  eventStore,
}) => {
  const walletStore = useWalletStore();
  const checkoutStore = useCheckoutStore();
  const primaryEntity = useMemo(() => {
    return profileStore.availableUserEntities?.[0];
  }, [profileStore.availableUserEntities]);

  const isPrimaryEntity = () => {
    return Boolean(
      primaryEntity &&
        activeEntity &&
        primaryEntity?.id === activeEntity?.id &&
        primaryEntity?.entityType === activeEntity?.entityType,
    );
  };

  const activeEntityId = activeEntity?.id;
  const entityType = activeEntity?.entityType?.toUpperCase() || '';
  const isCompany = entityType === 'COMPANY';
  const isCharity = entityType === 'CHARITY';
  const isInfluencer =
    entityType === 'INFLUENCER' ||
    (entityType === 'USER' && !isPrimaryEntity());
  const entityTypeForUrl = isInfluencer ? 'user' : entityType?.toLowerCase();

  const groupItems = useMemo(() => {
    return (
      profileStore.groupRoles?.map((entity) => ({
        label: entity.name,
        href: `/company/${entity.id}`,
      })) || []
    );
  }, [profileStore.groupRoles]);

  const adminItems = [
    {
      label: 'Companies',
      href: '/admin/companies',
    },
    {
      label: 'Charities',
      href: '/admin/charities',
    },
    {
      label: 'Influencers',
      href: '/admin/influencers',
    },
    {
      label: 'Users',
      href: '/admin/users',
    },
  ];

  const getEntityActivityUrl = () => {
    let href = `/admin/user/${activeEntity?.id}/activity`;

    if (entityType === 'CHARITY') {
      href = `/admin/charity/${activeEntity?.id}`;
    } else if (activeEntity?.entityType === 'COMPANY') {
      href = `/admin/company/${activeEntity?.id}/activity`;
    } else if (activeEntity?.entityType === 'INFLUENCER') {
      href = `/admin/user/${activeEntity?.id}/activity`;
    }

    return href;
  };

  const handleLogOut = () => {
    onLogoutClick(authStore, profileStore, eventStore);
    walletStore.onUnmount();
    checkoutStore.reset(true);
    window.location.href = '/login';
  };

  return (
    <div className="relative h-full ">
      {/* Background Overlay (Behind Sidebar) */}
      {expanded && (
        <div
          className="fixed h-full  bg-black bg-opacity-50 z-[999]"
          style={{
            left: '0vw',
            width: '100vw',
          }}
          onClick={() => setExpanded(false)}
        ></div>
      )}
      {/* Sidebar Content */}
      <aside
        className={cx(
          'cauze-sidebar pt-2 pb-32 sm:py-12 border-solid border-0 border-r border-lightgray-f1f bg-white fixed lg:absolute transition-[width] lg:w-[320px] overflow-x-hidden overflow-y-auto z-[1000] lg:z-10 translate-x-[-1px]',
          {
            'w-[80vw]': expanded,
            'w-0': !expanded,
          },
        )}
      >
        <SidebarItem
          icon={<HomeIcon />}
          label="Home"
          href={getEntityHomeUrl({
            id: activeEntityId,
            entityType: activeEntity?.entityType,
          })}
          setExpanded={setExpanded}
        />
        <SidebarItem
          icon={<StarIcon />}
          label="Discover"
          href="/discover"
          setExpanded={setExpanded}
        />
        {isCompany && (
          <>
            <SidebarItem
              icon={<PeopleIcon />}
              label="Manage Employees"
              href={`/admin/company/${activeEntityId}`}
              setExpanded={setExpanded}
            />
          </>
        )}

        {authStore.isAuthenticated && (
          <SidebarItem
            icon={<DocDollarIcon />}
            href={getEntityActivityUrl()}
            label="Transaction History"
            setExpanded={setExpanded}
          />
        )}

        {isCompany && (
          <>
            <SidebarItem
              icon={<GiveIcon />}
              label="Matching"
              href={`/admin/company/${activeEntityId}/matching`}
              setExpanded={setExpanded}
            />
          </>
        )}

        {authStore.isAuthenticated && (
          <SidebarItem
            icon={<GiveIcon />}
            label="Your Cauzes"
            href="/profile/cauzes"
            setExpanded={setExpanded}
          />
        )}

        {(isCompany || isInfluencer) && (
          <>
            <SidebarItem
              icon={<GiftIcon />}
              label="Gifting"
              href={`/admin/${entityType.toLowerCase()}/${activeEntityId}/gifts`}
              setExpanded={setExpanded}
            />
          </>
        )}

        {isCompany && (
          <>
            <SidebarItem
              icon={<WalletIcon />}
              label="Payroll Deductions"
              href={`/admin/company/${activeEntityId}/payroll`}
              setExpanded={setExpanded}
            />
          </>
        )}

        {authStore.isAuthenticated && (
          <>
            {!isCharity && (
              <SidebarItem
                icon={<WalletIcon />}
                label="Wallet"
                href={`/admin/${entityTypeForUrl}/${activeEntityId}/activity?active-tab=1`}
                setExpanded={setExpanded}
              />
            )}
            {Boolean(groupItems.length) && (
              <SidebarItem
                items={groupItems}
                icon={<PeopleIcon />}
                label="Groups"
                setExpanded={setExpanded}
              />
            )}
            {isCompany && (
              <SidebarItem
                icon={<ReportingIcon />}
                label="Reporting"
                href={`/admin/company/${activeEntityId}/reporting`}
                setExpanded={setExpanded}
              />
            )}
          </>
        )}

        {profileStore.data?.sysAdmin && (
          <SidebarItem
            items={adminItems}
            icon={<GearIcon />}
            label="Admin"
            setExpanded={setExpanded}
          />
        )}

        <div className="h-5" />

        {authStore.isAuthenticated && (
          <SidebarItem
            nested
            label="Logout"
            onClick={handleLogOut}
            setExpanded={setExpanded}
          />
        )}
        <SidebarItem
          nested
          label="Help/Feedback"
          href="/contact"
          setExpanded={setExpanded}
        />
        <SidebarItem
          nested
          anchorAsComponent
          label="About Cauze"
          href="https://www.cauze.com/about"
          target="_blank"
          setExpanded={setExpanded}
        />
        <SidebarItem
          nested
          anchorAsComponent
          label="FAQ"
          href="https://www.cauze.com/faqs"
          target="_blank"
          setExpanded={setExpanded}
        />
      </aside>
    </div>
  );
};

export default Sidebar;
