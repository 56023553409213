import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { navigate } from '@reach/router';
import { getProfileUrl } from 'util/stringUtils';

import useDashboardStore from 'stores/DashboardStore';
import Avatar from 'components/Avatar/Avatar';
import Toast from './Toast';
import { LOADING_SCREENS } from 'util/constants';
import { useEffect, useState } from 'react';

const ContextSwitchToast = ({
  name,
  id,
  avatar,
  entityType,
  prevContext,
  profileStore,
  ...props
}) => {
  const { showToast, showLoadingScreen } = useDashboardStore();
  const [cachedState, setCachedState] = useState({
    avatar,
    name,
    entityType,
    id,
    prevContext,
  });

  useEffect(() => {
    if (!props.show) {
      setTimeout(() => {
        setCachedState({});
      }, 100);
    } else {
      setCachedState({
        avatar,
        name,
        entityType,
        id,
        prevContext,
      });
    }
  }, [props.show, name, entityType, avatar]);

  if (!cachedState?.name) return <></>;

  return (
    <Toast id={`${entityType}-${id}`} {...props}>
      <div className="context-switch-toast relative p-4 bg-white rounded-[10px] w-[95vw] sm:w-auto max-w-[334px]">
        <button
          className="top-[10px] right-[10px] absolute bg-transparent border-none text-gray-687 cursor-pointer"
          onClick={() => {
            showToast('');
          }}
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </button>
        <div className="font-agenda-bold text-black text-[17px] leading-tight mb-3">
          {cachedState.name}
        </div>
        <div className="flex gap-x-[14px]">
          <Avatar
            noBorder
            avatar={cachedState.avatar}
            entityType={entityType}
          />
          <div>
            <div className="font-agenda text-[15px] text-gray-1b2 mb-3">
              You’re now acting as {cachedState.name} on Cauze.
            </div>
            <div className="flex justify-end gap-x-3">
              {['USER', 'INFLUENCER'].includes(entityType) && (
                <button
                  className="cz-btn-black pt-[12px] pb-[9px]"
                  onClick={() => {
                    navigate(getProfileUrl(id, entityType));
                    showToast('');
                  }}
                >
                  SEE PROFILE
                </button>
              )}
              <button
                className="cz-btn-outline black pt-[12px] pb-[9px]"
                onClick={() => {
                  const foundIndex =
                    profileStore.availableUserEntities.findIndex((entity) => {
                      return (
                        +entity.id === +prevContext?.id &&
                        entity.entityType === prevContext?.entityType
                      );
                    });
                  const entityIndex = foundIndex > 0 ? foundIndex : 0;
                  const context =
                    profileStore.availableUserEntities[entityIndex];

                  if (entityIndex > 0) {
                    showLoadingScreen(LOADING_SCREENS.CONTEXT_SWITCH, {
                      avatar: context.avatar,
                      entityType: context.entityType,
                      name: context.name,
                      id: context.id,
                      prevContext: {
                        name,
                        id,
                        avatar,
                        entityType,
                      },
                    });
                    profileStore.setActiveEntity(entityIndex, true);
                  } else {
                    showLoadingScreen(LOADING_SCREENS.CONTEXT_SWITCH, {
                      avatar: context.avatar,
                      entityType: context.entityType,
                      name: context.name,
                      id: context.id,
                      prevContext: {
                        name,
                        id,
                        avatar,
                        entityType,
                      },
                    });
                    profileStore.clearActiveEntity();
                  }
                }}
              >
                UNDO
              </button>
            </div>
          </div>
        </div>
      </div>
    </Toast>
  );
};

export default ContextSwitchToast;
