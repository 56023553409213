import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import { shorten } from '../../util/stringUtils';
import Avatar from '../Avatar/Avatar';
import Button from 'components/Button/Button';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import useMobile from 'hooks/useMobile';
const CharityEventListItem = ({
  charity,
  isSelected,
  onSelect,
  onLearnMore,
  showLink = false,
  disabled = false,
  rounded = false,
  charityCount,
}) => (
  <a
    href={showLink ? `/charity/${charity.id}` : ''}
    className={`charity-event-item ${
      charityCount <= 1 ? 'single-charity' : ''
    }`}
    key={`${charity.name}-${charity.id}`}
    onClick={(ev) => {
      if (showLink) {
        return;
      }
      ev.preventDefault();
      if (disabled) {
        return;
      }
      if (!!onSelect) {
        onSelect(charity.id);
      } else if (!!onLearnMore) {
        onLearnMore(charity.id);
      }
    }}
  >
    <Avatar
      className="event-charity-avatar"
      type="charity"
      noBorder={true}
      dropShadow={false}
      avatar={charity.avatar || charity.avatarUrls}
      style={{ marginBottom: '5px' }}
    />
    <div className="flex-column flex-expand flex-align-center">
      <div
        className={`charity-info ${
          charityCount <= 1 ? 'single-charity w-max' : ''
        }`}
      >
        <text
          alt="test"
          className={`charity-name ${
            charityCount <= 1 ? 'single-charity' : ''
          }`}
        >
          {charityCount <= 1 ? charity.name : shorten(charity.name, 60)}
        </text>
        <div className="charity-location">
          {charity.city && charityCount <= 1 && <span>{charity.city}, </span>}
          {charity.state && charityCount <= 1 && <span>{charity.state}</span>}
        </div>
      </div>
    </div>
    {onSelect && !disabled && (
      <div className="flex-column">
        <div className="flex-column flex-expand" />
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            onSelect(charity.id);
            ev.currentTarget.blur();
          }}
          className={classnames('select-button button is-primary is-small', {
            'is-outlined': isSelected,
            selected: isSelected,
            'is-rounded': rounded,
          })}
        >
          {isSelected && <span style={{ fontSize: 14 }}>✓ </span>}
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </div>
    )}
  </a>
);

const CharityList = ({
  charities,
  onLearnMore,
  onSelect,
  selected = [],
  title,
  showLink,
  disabled = false,
  rounded,
}) => {
  const charityCount = charities?.length;
  // Handle slide change to hide/show arrows
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const { isMobile } = useMobile();
  return (
    <div className="charity-list-new flex-column !pt-1.5  max-md:!p-0">
      {!onSelect && (
        <div
          className={`
          charity-select-title 
          flex-row
          justify-between 
          ${charityCount > 1 ? 'multiple-charities' : ''}
          `}
        >
          <SectionHeader title={title || 'Nonprofits'} />
          {swiperRef && swiperRef.allowSlideNext && !isMobile && (
            <div className="flex gap-2 items-center justify-center">
              {/* Custom Navigation Buttons */}
              <div
                ref={prevRef}
                onClick={() => swiperRef.slidePrev()}
                className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-prev ${
                  isAtBeginning ? '!cursor-default opacity-40' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size={'sm'}
                  style={{ color: '#2a2a33', transform: 'translateX(-1px)' }}
                />
              </div>
              <div
                ref={nextRef}
                onClick={() => {
                  swiperRef.slideNext();
                }}
                className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-next ${
                  isAtEnd ? '!cursor-default opacity-40' : ''
                }`}
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size={'sm'}
                  style={{ color: '#2a2a33', transform: 'translateX(1px)' }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="w-full overflow-hidden">
        <Swiper
          className="mb-4"
          modules={[Navigation]}
          spaceBetween={8}
          slidesPerView="auto"
          onSlideChange={handleSlideChange}
          navigation={{
            prevEl: 'swiper-arrows-above-button-prev',
            nextEl: 'swiper-arrows-above-button-next',
          }}
          onInit={(swiper) => {
            setSwiperRef(swiper);
            swiper.prevEl = prevRef.current;
            swiper.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          onSwiper={setSwiperRef}
        >
          {charities.map((charity) => (
            <SwiperSlide key={charity.id} style={{ width: 'auto' }}>
              <CharityEventListItem
                charity={charity}
                onSelect={onSelect}
                isSelected={selected.includes(charity.id)}
                onLearnMore={onLearnMore}
                disabled={disabled}
                showLink={showLink}
                rounded={rounded}
                charityCount={charityCount}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CharityList;
