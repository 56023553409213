import gql from 'graphql-tag';

const listCountriesQuery = gql`
  query listCountries {
    listCountries {
      id
      name
      code
    }
  }
`;

const searchCharitiesQuery = gql`
  query searchCharity($query: String!, $filters: SearchFilters, $zip: String) {
    searchCharity(query: $query, filters: $filters, zip: $zip) {
      id
      name
      avatar {
        sm
        md
        lg
      }
      ein
      location
    }
  }
`;

const searchLocalCharitiesQuery = gql`
  query locationProjectsQuery($location: String!, $limit: Int) {
    locationProjects(location: $location, limit: $limit) {
      projects {
        name
        id
        avatarUrls {
          sm
          md
        }
        heroUrls {
          lg
        }
        currentEntityIsFollowing
        isCurrentEntity
        type
        project {
          city
          state
          totalFollowers
        }
      }
    }
  }
`;

const usersSearchQuery = gql`
  query usersSearch($query: String) {
    usersSearch(query: $query) {
      id
      firstName
      lastName
      username
      isVerified
      avatar {
        sm
      }
    }
  }
`;

const listActiveTopicsQuery = gql`
  query listActiveTopics {
    listActiveTopics
  }
`;

const trendingTopicsQuery = gql`
  query trendingTopics {
    trendingTopics {
      id
      name
    }
  }
`;

const projectCategoriesQuery = gql`
  query projectCategories {
    projectCategories
  }
`;

const quickSearchProjectsQuery = gql`
  query quickSearchProjectsQuery(
    $query: String
    $limit: Int
    $offset: Int
    $country: String
    $active: Boolean
    $categories: [String]
    $topics: [String]
    $location: String
  ) {
    quickSearchProjects(
      query: $query
      limit: $limit
      offset: $offset
      country: $country
      active: $active
      categories: $categories
      topics: $topics
      location: $location
    ) {
      id
      name
      description
      avatarUrls {
        sm
        md
        lg
      }
      ein
      city
      state
      currentEntityIsFollowing
      totalFollowers
      balance {
        total
      }
    }
  }
`;

export {
  listActiveTopicsQuery,
  listCountriesQuery,
  searchCharitiesQuery,
  usersSearchQuery,
  projectCategoriesQuery,
  trendingTopicsQuery,
  searchLocalCharitiesQuery,
  quickSearchProjectsQuery,
};
